import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {LiveQuestion} from '../../../models/PollChoice';
import {Common} from '../../../shared/common';

@Component({
  selector: 'app-preview-event-item',
  templateUrl: './preview-event-item.component.html',
  styleUrls: ['./preview-event-item.component.less', './../preview-resolution/preview-resolution.component.less'],
})
export class PreviewEventItemComponent implements OnInit, AfterViewInit {
  @Input() index: number;
  @Input() event: LiveQuestion;
  @Input() useElectionTitle: boolean;

  constructor(public common: Common) { }

  ngAfterViewInit() {
    if (!this.useElectionTitle) {
      const imageWrapper = document.getElementById(`image-${this.index}`) as HTMLImageElement;
      const numberWrapper = document.getElementById(`wrapper-exp-${this.index}`) as HTMLDivElement;
      if (this.event.listImage != null && this.event.listImage.length > 0) {
        imageWrapper.style.display = 'block';
        imageWrapper.src = this.event.listImage[0];
        numberWrapper.style.display = 'none';
      } else {
        imageWrapper.style.display = 'none';
      }
    }
  }

  getElectionTitle(): string {
    if (this.useElectionTitle) { return this.event.electionTitle; } else { return this.event.title; }
  }

  shouldShowLink() {
    return !this.useElectionTitle && this.event.listLink != null && this.event.listLink.length > 0;
  }

  ngOnInit(): void {
  }

}
