import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {PollCreationComponent} from './poll-creation/poll-creation.component';
import {PollPlannedComponent} from './poll-planned/poll-planned.component';
import {PollOngoingComponent} from './poll-ongoing/poll-ongoing.component';
import {PollFinishedComponent} from './poll-finished/poll-finished.component';
import {PollDashboardComponent} from './polls-dashboard/poll-dashboard.component';
import {SubscribeComponent} from './home/new-login/auth/inscription/inscription.component';
import { AuthGuard } from './helpers/auth.guard';
import {LivePollComponent} from './poll-live/poll-live.component';
import {HomeComponent} from './home/home.component';
import {LocationStrategy, PathLocationStrategy} from '@angular/common';
import {ProfileComponent} from './profile/profile.component';
import {PollPlannedAssessorComponent} from './poll-planned-assessor/poll-planned-assessor.component';
import {PollOngoingAssessorComponent} from './poll-ongoing-assessor/poll-ongoing-assessor.component';
import {PollPreviewComponent} from './poll-preview/poll-preview.component';

const routes: Routes = [
  {
    path: 'poll-creation',
    component: PollCreationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'poll-creation/ok',
    component: PollCreationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'poll-creation/:id',
    component: PollCreationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'poll-creation/canceled',
    component: PollCreationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'poll-planned/:id',
    component: PollPlannedComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'poll-ongoing/:id',
    component: PollOngoingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'poll-result',
    component: PollFinishedComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'polls',
    component: PollDashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'polls/ok',
    component: PollDashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'polls/canceled',
    component: PollDashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'polls/edited',
    component: PollDashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'poll-live/:id',
    component: LivePollComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'inscription',
    component: SubscribeComponent
  },
  {
    path: 'vote-en-ligne',
    component: HomeComponent
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'poll-planned-assessor/:id/:code',
    component: PollPlannedAssessorComponent,
  },
  {
    path: 'poll-ongoing-assessor/:id/:code',
    component: PollOngoingAssessorComponent,
  },
  {
    path: 'poll-preview/:id',
    component: PollPreviewComponent,
  },
  {
    path: '**',
    component: HomeComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: false, scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled'})],
  providers: [
    {provide: LocationStrategy, useClass: PathLocationStrategy}
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
