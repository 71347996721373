<div id="page-container">
  <div id="content-wrap">
    <div class="account-header">
      <div *ngIf="maxVoters === 5" style="display: flex; justify-content: space-between; height: 100%">
        <span class="banner-text">{{'POLL-DASHBOARD.HEADBAND.MESSAGE-CREATION' | translate}}</span>
        <div style="height: 100%; margin-right: 24px; display: flex;">
          <span class="banner-text" style="margin: 0;">{{accountName}}</span>
        </div>
      </div>
      <div *ngIf="maxVoters > 5" style="margin-right: 24px; height: 100%; display: flex; justify-content: flex-end;">
        <span *ngIf="licenseTime == 'D'" class="banner-text">{{accountName}} - {{'POLL-DASHBOARD.HEADBAND.EVENTS' |
          translate}}</span>
        <span *ngIf="licenseTime == 'DM'" class="banner-text">{{accountName}} - {{'POLL-DASHBOARD.HEADBAND.PACK' |
          translate}}</span>
        <span *ngIf="licenseTime == 'Y'" class="banner-text">{{accountName}} - {{'POLL-DASHBOARD.HEADBAND.YEARLY' |
          translate}}</span>
        </div>
    </div>

    <div class="fullScreen">
      <div class="left-header">
        <img src="assets/v8te.png" id="logo" (click)="navigateHome()">
        <div class="title-container">
          <img class="arrow" (click)="navigateBack()" src="assets/arrow-left.svg" style="cursor: pointer;">
          <h3 class="title">{{poll.title}}</h3>
        </div>
      </div>
      <div class="right-header"></div>
    </div>

    <div class="container-live">
      <div class="container-left">
        <div *ngIf="poll.votingType == 'live' || poll.votingType == 'resolution'">
          <vc-live-polls-list [votingType]="poll.votingType" [livePolls]="liveResolutions" [isPublic]="poll.isUncrypted"
            [canAddQuestions]="canAddQuestions" [resolutions]="resolutions" [activeIndex]="activeIndex"
            (submitResolution)="sendNewResolution()" (sendResults)="getResults($event)"
            (sendCommand)="getCommand($event)" (deleteResolutionEvent)="triggerDeletePopup($event)" [hasActiveElection]="hasActiveElection">
          </vc-live-polls-list>
        </div>
        <div *ngIf="poll.votingType == 'liveCandidates' || poll.votingType == 'election'">
          <app-live-candidates-list [votingType]="poll.votingType" [liveChoiceEvents]="liveChoiceEvents" [isPublic]="poll.isUncrypted"
            [isLiveOpen]="isLiveOpen" [choices]="choices" [activeIndex]="activeIndex" [canAddQuestions]="canAddQuestions"
            (submitCandidate)="sendNewCandidate($event)" (sendResults)="getResults($event)"
            (sendCommand)="getCommand($event)" (deleteQuestionEvent)="triggerDeletePopup($event)"
            (sendAnswerImage)="saveCandidateBlob($event)" [hasActiveElection]="hasActiveElection">
          </app-live-candidates-list>
        </div>
      </div>
      <app-confirmation-component [votersCount]="votersCount" [smsVoterCount]="votersCount" [shouldDisplayLive]="true"
        [maxVoters]="maxVoters" [maxSMSVoters]="maxSMSVoters" [canBeClosed]="canBeClosed"
        (sendCloseCommand)="closePoll()" [poll]="poll" [isFromLive]="true"
        [eventCounter]="eventCounter" [licenceTime]="licenseTime" [isAssoc]="clientType == 'assoc' || clientType == null"
        [licenceEnd]="licenceEnd"></app-confirmation-component>
    </div>
  </div>
</div>
<div *ngIf="isError">
  <app-error (isClosed)="dismissError()" [errorMessage]="errorMessage">
  </app-error>
</div>
<div *ngIf="isClosing">
  <vc-warning-popup [understoodButton]="true" [cancelButton]="true" title="{{'LIVE.POPUP.MESSAGE' | translate}}"
    (endDetailsPopup)="popupEvent($event)">
  </vc-warning-popup>
</div>

  <vc-warning-popup *ngIf="deleteRoundPopup && poll.votingType == 'live'" [pleaseButton]="true"
    title="{{'LIVE.POPUP.DELETE-RESO' | translate}}" (endDetailsPopup)="confirmDeletion($event)">
  </vc-warning-popup>

  <vc-warning-popup *ngIf="deleteRoundPopup && poll.votingType == 'election'" [pleaseButton]="true"
    title="{{'LIVE.POPUP.DELETE-ELEC' | translate}}" (endDetailsPopup)="confirmDeletion($event)">
  </vc-warning-popup>

<div *ngIf="loader">
  <vc-loader messageToTheUser="{{'LOADER.DELETE-OPTION' | translate}}">
  </vc-loader>
</div>
