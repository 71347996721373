import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { Poll } from './../../models/Poll';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-confirmation-component',
  templateUrl: 'confirmation-component.component.html',
  styleUrls: ['./confirmation-component.component.less', '../../shared/common.less']
})
export class ConfirmationComponent implements OnInit, OnChanges {
  @Output() editPoll = new EventEmitter<boolean>();
  @Output() sendPoll = new EventEmitter<string>();
  @Output() sendLive = new EventEmitter<boolean>();
  @Output() sendCloseCommand = new EventEmitter<boolean>();
  @Output() sendLogout = new EventEmitter<boolean>();
  @Output() sendPreview = new EventEmitter();
  @Input() numberOfAssessors: number;
  @Input() editionMode = false;
  @Input() maxVoters: number;
  @Input() maxSMSVoters: number;
  @Input() licenceTime: string;
  @Input() votersCount: number;
  @Input() poll: Poll;
  @Input() eventCounter: number;
  @Input() isSMS: boolean;
  @Input() licenceEnd: Date;
  @Input() isAssoc: boolean;
  @Input() listElectionCount: number
  // Input variables used in the HTML only
  @Input() canLaunchLive: boolean;
  @Input() shouldDisplayLive: boolean;
  @Input() canBeClosed: boolean;
  @Input() isPollComplete: boolean;
  @Input() isFromLive = false;
  @Input() smsVoterCount: number;

  isNumbersValid = true;
  // Variable used in the recap panel
  typeImage = '';
  typeLabel = '';
  smsLabel = '';
  priceString = 'Gratuit';
  secretLabel = 'Bulletin secret';
  votersCountString: string;
  questionsLabel = '';
  paymentString = '';
  language: string;
  votingTypeIcon = 'assets/question-count.svg';

  constructor(private store: Store) {}

  publish() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.sendPoll.emit('OK');
  }

  goLive() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.sendLive.emit(true);
  }

  seePreview() {
    this.sendPreview.emit();
  }

  sendEdition() {
    this.editPoll.emit(true);
  }

  setIconsForLive() {
    this.votingTypeIcon = 'assets/question-count.svg';
    if (this.poll.votingType !== undefined) {
      if (this.poll.votingType.localeCompare('election') === 0 || this.poll.votingType.localeCompare('liveCandidates') === 0) {
        this.typeImage = 'assets/election-simple.svg';
        if (this.poll.liveQuestions.length === 1) {
          this.questionsLabel = this.getCandidatesLabel(this.poll.liveQuestions[0].options.length);
        } else {
          this.questionsLabel = this.poll.liveQuestions.length + ' question(s)';
        }
      } else if (this.poll.votingType.localeCompare('resolution') === 0 || this.poll.votingType.localeCompare('live') === 0) {
        this.typeImage = 'assets/resolution-image.svg';
        switch (this.language) {
          case 'fr': { this.questionsLabel = this.poll.resolutions.length + ' résolution(s)'; this.typeLabel = 'Vote de résolutions'; break; }
          case 'es': { this.questionsLabel = this.poll.resolutions.length + ' resolución(s)'; this.typeLabel = 'Voto de resoluciones'; break; }
          default: { this.questionsLabel = this.poll.resolutions.length + ' resolution(s)'; this.typeLabel = 'Resolutions vote'; }
        }
      } else {
        let numberLabel = 0;
        if (this.poll.choices.length > 0) {
          this.poll.choices[0].forEach(candidates => {
            if (candidates.name !== undefined && candidates.name.length > 0) {
              numberLabel++;
            }
          });
        }
        let candidateString: string = this.language === 'fr' ? ' liste(s)' : ' list(s)';
        this.questionsLabel = numberLabel + candidateString;
        if (this.poll.choices.length > 1) {
          numberLabel = this.poll.choices.length;
          candidateString = ' election(s)';
          this.questionsLabel = numberLabel + candidateString;
        }
        this.votingTypeIcon = 'assets/guide-guides.svg';
      }

      this.votersCountString = this.votersCount.toLocaleString('fr-FR', {
        maximumFractionDigits: 2,
      });
    }
  }

  setIcons() {
    this.votingTypeIcon = 'assets/question-count.svg';
    if ((this.poll.votingType === 'election')) {
      this.typeImage = 'assets/election-simple.svg';
      let numberLabel = 0;
      if (this.poll.choices.length > 0) {
        this.poll.choices[0].forEach(candidates => {
          if (candidates.name !== undefined && candidates.name.length > 0) {
            numberLabel++;
          }
        });
      }
      this.questionsLabel = this.getCandidatesLabel(numberLabel);
      if (this.poll.choices.length > 1) {
        numberLabel = this.poll.choices.length;
        this.questionsLabel = numberLabel +  ' question(s)';
      }
    } else if (this.poll.votingType === 'resolution' || this.poll.votingType === 'live') {
      this.typeImage = 'assets/resolution-image.svg';
      switch (this.language) {
        case 'fr': { this.questionsLabel = this.poll.resolutions.length + ' résolution(s)'; break; }
        case 'es': { this.questionsLabel = this.poll.resolutions.length + ' resolución(s)'; break; }
        default: this.questionsLabel = this.poll.resolutions.length + ' resolution(s)';
      }
    } else {
      let numberLabel = 0;
      if (this.poll.choices.length > 0) {
        this.poll.choices[0].forEach(candidates => {
          if (candidates.name !== undefined && candidates.name.length > 0) {
            numberLabel++;
          }
        });
      }
      numberLabel = this.poll.liveQuestions.length;
      const candidateString = this.language === 'fr' ? ' élection(s)' : ' election(s)';
      this.questionsLabel = this.listElectionCount + candidateString;
      this.votingTypeIcon = 'assets/guide-guides.svg';
    }

    if (this.poll.temporality === 'live') {
      this.typeImage = 'assets/live-gris.svg';
      switch(this.language) {
        case 'fr': { this.typeLabel = 'Vote Live'; break; }
        case 'es': { this.typeLabel = 'Voto en vivo'; break; }
        default: { this.typeLabel = 'Live Vote'; }
      }
    } else {
      this.typeImage = 'assets/calendar.svg';
      switch (this.language) {
        case 'fr': { this.typeLabel = 'Vote Programmé'; break; }
        case 'es': { this.typeLabel = 'Voto programado'; break; }
        default: this.typeLabel = 'Scheduled Vote';
      }
    }

    this.votersCountString = this.votersCount.toLocaleString('fr-FR', {
      maximumFractionDigits: 2,
    });
    this.isNumbersValid =
      (this.votersCount <= this.maxVoters &&
      (((this.poll.isSMS || this.poll.is2FA) && this.smsVoterCount <= this.maxSMSVoters) ||
        (!this.poll.isSMS && !this.poll.is2FA))) || this.isFromLive;
  }

  getCandidatesLabel(count: number): string {
    switch(this.language) {
      case 'fr': return count + ' candidat(s)';
      case 'es': return count + ' candidato(s)';
      default: return count + ' candidate(s)';
    }
  }

  closeLive() {
    this.sendCloseCommand.emit(true);
  }

  setPriceLabel() {
    if (this.votersCount <= 10 && this.maxVoters <= 11) {
      this.priceString = this.language === 'fr' ? 'Gratuit' : 'Free';
    } else {
      if (this.maxVoters > 10) {
        if (this.licenceTime !== 'Y') {
          this.priceString = this.getRemainingEventsString(this.eventCounter);
          const usageString = this.language === 'fr' ? 'Utilisation illimitée, jusqu\'à ' : 'Unlimited use, up to ';
          const votersString = this.language === 'fr' ? ' invités ' : ' invites';
          this.paymentString = usageString + this.maxVoters.toLocaleString() + votersString;
        } else {
          switch(this.language) {
            case 'fr': { this.priceString = 'Abonnement annuel illimité'; this.paymentString = 'Utilisation illimitée jusqu’au ' + new Date(this.licenceEnd).toLocaleString(); break; }
            case 'es': { this.priceString = 'Suscripción anual ilimitada'; this.paymentString = 'Uso ilimitado hasta ' + new Date(this.licenceEnd).toLocaleString(); break; }
            default: { this.priceString = 'Unlimited annual subscription'; this.paymentString = 'Unlimited use until' + new Date(this.licenceEnd).toLocaleString(); }
          }
        }
      } else {
        const associationString = this.language === 'fr' ? 'A partir de 240 euros ttc' : 'Starting at 240 euros ttc';
        const corporateString = this.language === 'fr' ? 'A partir de 590 euros ht' : 'Starting at 590 euros ht';
        if (this.isAssoc !== undefined && this.isAssoc != null) {
          this.priceString = this.isAssoc ? associationString : corporateString;
        } else {
          this.priceString =  associationString;
        }
      }
    }
  }

  getRemainingEventsString(eventCounter: number): string {
    switch (this.language) {
      case 'fr': return eventCounter > 1 ? this.eventCounter + ' événements restants' : this.eventCounter + ' événement restant';
      case 'es': return eventCounter > 1 ? this.eventCounter + ' eventos restantes' : this.eventCounter + ' evento restante';
      default: return eventCounter > 1 ? this.eventCounter + ' remaining events' : this.eventCounter + ' remaining event';
    }
  }

  setLabels() {
    if (!this.poll.is2FA) {
      if (this.poll.isSMS) { this.smsLabel = 'E-mail + SMS'; }
      else {
        this.smsLabel = this.poll.isSMS ? 'E-mail + SMS' : 'E-mail';
      }
    }
    this.secretLabel = this.getSecretLabelString();
    this.isNumbersValid =
      (this.votersCount <= this.maxVoters &&
      (((this.poll.isSMS || this.poll.is2FA) && this.smsVoterCount <= this.maxSMSVoters) ||
        (!this.poll.isSMS && !this.poll.is2FA))) || this.isFromLive;
  }

  getSecretLabelString(): string {
    switch (this.language) {
      case 'fr': return this.poll.isUncrypted ? 'Main levée' : 'Bulletin secret';
      case 'es': return this.poll.isUncrypted ? 'Mano alzada' : 'Voto secreto';
      default:  return this.poll.isUncrypted ? 'Show of hands vote' : 'Secret vote';
    }
  }

  ngOnChanges(_changes: SimpleChanges) {
    if (this.isFromLive) {
      this.setIconsForLive();
      this.setPriceLabel();
      this.setLabels();
    } else if (this.poll !== undefined) {
      this.setIcons();
      this.setPriceLabel();
      this.setLabels();
    }
  }

  ngOnInit() {
    if (!this.isFromLive) {
      this.store.select((state: any) => state.creation.poll).subscribe(res => {
        this.poll = res;
        this.setIcons();
        this.setPriceLabel();
        this.setLabels();
      })
    }
    const langKey = 'userLanguage';
    const userLang = navigator.language || navigator[langKey];
    this.language = userLang.substring(0, 2);
  }
}
