import { Component, OnInit, Input } from '@angular/core';
import {Common} from '../../shared/common';

@Component({
  selector: 'vc-poll-planned-summary',
  templateUrl: 'poll-planned-summary.component.html',
  styleUrls: ['./poll-planned-summary.component.less']
})

export class PollPlannedSummaryComponent implements OnInit {
  @Input() description: string;
  @Input() startDate: string;
  @Input() endDate: string;
  @Input() votersCount: number;
  @Input() isSms: boolean;
  @Input() is2FA: boolean;
  @Input() isPublic: boolean;
  @Input() sharedLinks: string;

  constructor(
    public common: Common,
  ) {
  }

  ngOnInit() {
  }
}
