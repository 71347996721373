import {AfterContentInit, ChangeDetectorRef, Component, HostListener, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DraftForm, DraftIdResponse, Poll} from '../models/Poll';
import {ImageTypes} from '../models/Enums';
import {LicenseData, LicenseResponse, SubsciptionData} from '../models/LicenseData';
import {LicenseService} from '../shared/licens.service';
import {ListQuestion, PollCreationData, PollQuestion} from '../models/PollCreationData';
import {LiveQuestion, PollChoice, Resolution, WsCommand} from '../models/PollChoice';
import {Voter} from '../models/Voter';
import {ApiService} from '../shared/api.service';
import {LiveService} from '../shared/live.service';
import {WebsocketService} from '../shared/websocket.service';
import {HttpErrorResponse} from '@angular/common/http';
import {AuthenticationService} from '../shared/authentication.service';
import {ContactData} from '../models/ContactData';
import {environment} from '../../environments/environment';
import {BoughtItem, ShoppingCart} from '../models/PaymentData';
import {Common} from '../shared/common';
import {getDownloadURL, ref} from 'firebase/storage';
import {storage} from '../firebase';
import {v4} from 'uuid';
import {ImportMessage, ImportStatuses} from '../models/VoterImportUtils';
import {CloningPoll} from '../models/ReceivedPoll';
import {Assessor} from '../models/Assessor';
import { Store } from '@ngrx/store';
import {
  resetAction,
  set2FAAction,
  setActivateAssessorsAction,
  setAssessorsMailsAction,
  setAssessorsNamesAction,
  setChoicesAction,
  setDescriptionAction,
  setDocumentAction,
  setDraftFlagAction,
  setEndDateAction,
  setEndTimeAction,
  setLiveQuestionAction,
  setPublicVoteAction,
  setResolutionsAction,
  setSMSAction,
  setSenderAction,
  setStartDateAction,
  setStartTimeAction,
  setTemporalityAction,
  setTitleAction,
  setVotersAction,
  setVotingTypeAction,
  setPollImageAction
} from '../states/creationActions';
import { CreationState } from '../states/creationReducers';
import {blacklistedSenders} from '../models/Enums';

@Component({
  selector: 'vc-poll-creation',
  templateUrl: 'poll-creation.component.html',
  styleUrls: ['./poll-creation.component.less'],
  providers: [WebsocketService, LiveService],
})
export class PollCreationComponent implements OnInit, OnChanges, OnDestroy, AfterContentInit {
  numberValidAssessors = 0;
  validAssessors = true;
  assessorError = false;
  assessorIndexes: Array<number> = [];
  currentPoll = new Poll();
  votersCount = 0;
  isError = false;
  shouldDisplayVoters = false;
  isImportError = false;
  displayedVoters: Array<Voter> = [];
  newLicences: Array<SubsciptionData> = [];
  errorMessage: string;
  errorDescription: string;
  isLiveOpen = false;
  isLaunching = false;
  canLaunchLive = false;
  shouldDisplayLive = false;
  isLiveResolution = true;
  isPayment = false;
  isPaymentError = false;
  confirmContact = false;
  isLoading = false;
  maxVoters = 10;
  maxSMSVoters = 10;
  shouldResume = false;
  isPollComplete = false;
  accountName: string;
  licenseTime: string;
  shouldDisplayLaunchWarning = false;
  shouldDisplayNewLicences = false;
  shouldDisplayListWarning = false;
  smsOption: boolean;
  isLaunchEnabled = false;
  plurinominalError = false;
  isSMSError = false;
  isPonderationError = false;
  paymentPopup = false;
  numberOfVote = 1;
  eventCounter = 0;
  licenceEnd: Date;
  clientType = 'assoc';
  changeOfferReason: string;
  subjects = false;
  // Blacklisting variables

  validateSender = true;
  // Popup for 2FA and confirmation that user will be contacted
  warning2FA = false;
  contactedPopup = false;
  stripe: stripe.Stripe;
  stripeConstants = environment.stripeConstants;
  moreOption: boolean;
  selectedOffer: any;
  isAssoc: boolean;
  getContacted: boolean;
  smsAdditionalOption: boolean;
  disableTemporalitySelection = false;
  subject: string;
  startDateError: boolean;
  hideAddElectionButton = false;
  hasHotline: any;
  shouldDisplay2FAWarning = false;
  smsVoterCount = 0;
  electionsWarningPopup: boolean;
  displayElectionListWarning: boolean;
  isOutdatedImportFileError = false;
  subjectLinks: Array<string> = [];
  launchIndex = -1;

  constructor(
    private router: Router,
    private apiService: ApiService,
    private liveService: LiveService,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private common: Common,
    private licenseService: LicenseService,
    private store: Store<CreationState>,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  needsSms() { return this.currentPoll.isSMS || this.currentPoll.is2FA; }

  triggerSMSPopup() {
    this.isPayment = false;
    if (this.needsSms() &&
      (this.currentPoll.voters.filter(x => x.phone !== null && x.phone !== undefined).length > this.maxSMSVoters)) {
        if ((this.eventCounter === 0 && this.licenseTime !== 'Y')) { this.isPayment = true; }
        else { this.smsOption = true; }
      } else {
        this.smsOption = false;
      }
  }

  goToPoll() {
    this.router.navigate(['/polls']);
  }

  checkStartTime() {
    const selectedDate = new Date(this.currentPoll.startDate + 'T' + this.currentPoll.startTime);
    return new Date() <= selectedDate;
  }

  checkStartDate() {
    const inputDate = new Date(this.currentPoll.startDate);
    const currentDay = new Date();
    currentDay.setHours(0, 0, 0, 0);
    if (currentDay > inputDate) {
      return false;
    } else
      if (this.currentPoll.startTime !== undefined) {
        return this.checkStartTime();
      }
      else { return false; }
  }

  checkEndTime() {
    const selectedDate = new Date(this.currentPoll.endDate + 'T' + this.currentPoll.endTime);
    const startDate = new Date(this.currentPoll.startDate + 'T' + this.currentPoll.startTime);
    return startDate < selectedDate;
  }

  checkEndDate() {
    const endDate = new Date(this.currentPoll.endDate);
    const startDate = new Date(this.currentPoll.startDate);
    if (startDate > endDate) {
      return false;
    } else
      if (this.currentPoll.endTime !== undefined) {
        return this.checkEndTime();
      } else { return false; }
  }

  checkDuration() {
    if (this.maxVoters < 6) {
      const endDate = new Date(this.currentPoll.endDate + 'T' + this.currentPoll.endTime);
      const startDate = new Date(this.currentPoll.startDate + 'T' + this.currentPoll.startTime);
      return endDate.getTime() - startDate.getTime() <= 1000 * 60 * 60 * 24;
    }
    return true;
  }

  checkDates() {
    return this.checkStartDate() && this.checkEndDate() && this.checkDuration();
  }

  markPollChanges() {
    this.currentPoll = Object.assign({}, this.currentPoll);
    this.saveDraft();
  }

  getVoters(data: ImportMessage) {
    switch (data.status) {
      case ImportStatuses.responseOk: {
        if (data.voters.length > this.maxVoters && this.licenseTime !== undefined) {
          if (this.maxVoters <= 5) { this.paymentPopup = true; }
          else {
            this.changeOfferReason = 'addVoters';
            this.isPaymentError = true;
          }
        } else { this.paymentPopup = false; }
        this.triggerSMSPopup();
        this.shouldDisplayVoters = false;
        this.isImportError = false;
        this.currentPoll.voters = data.voters;
        this.votersCount = this.currentPoll.voters.length;
        this.smsVoterCount = this.currentPoll.voters.filter(x => x.phone == null || x.phone === undefined).length;
        this.displayedVoters = data.voters;
        this.calculateLiveAvailability();
        this.validateFields();
        if (this.needsSms() && data.voters.length > 0) {
          if (!this.shouldResume) {
            this.apiService.validatePhones(data.voters).subscribe((res) => { this.saveDraft(); },
              (err: HttpErrorResponse) => {
                this.isError = true;
                this.errorMessage = `Nous sommes désolés mais vous ne pouvez accéder au contenu erreur ${err.status}`;
                this.errorDescription = err.error.error;
              });
          }
        }
        if (this.route.snapshot.paramMap.get('id') !== null && this.route.snapshot.paramMap.get('id') === 'votersascandidates') {
          const votersAsCandidates = this.currentPoll.voters.map(v => PollChoice.withName(v.getName()));
          const newChoices = this.currentPoll.choices[0];
          let hasChanges = false;
          votersAsCandidates.forEach((voter) => {
            if (newChoices.map(c => c.name).indexOf(voter.name) === -1) {
              hasChanges = true;
              newChoices.push(voter);
            }
          });
          if (hasChanges) {
            const copyChoices = structuredClone(this.currentPoll.choices);
            copyChoices[0] = newChoices;
            this.store.dispatch(setChoicesAction({ choices : copyChoices }));
          }
        }
        break;
      }
      case ImportStatuses.errorEmail: {
        this.markVotersAsError(data.voters, false, false);
        break;
      }
      case ImportStatuses.errorSms: {
        this.markVotersAsError(data.voters, true, false);
        break;
      }
      case ImportStatuses.errorWeights: {
        this.markVotersAsError(data.voters, false, true);
      }
    }
  }

  markVotersAsError(data: Voter[], smsError: boolean, ponderationError: boolean) {
    this.displayedVoters = data;
    this.currentPoll.voters = [];
    this.votersCount = this.currentPoll.voters.length;
    this.smsVoterCount = this.currentPoll.voters.filter(x => x.phone == null || x.phone === undefined).length;
    this.isImportError = true;
    this.isSMSError = smsError;
    this.isPonderationError = ponderationError;
    this.shouldDisplayVoters = true;
    this.calculateLiveAvailability();
  }

  addEvent() {
    if (this.currentPoll.liveQuestions.length < 25) {
      const newRound = new LiveQuestion(this.currentPoll.liveQuestions.length, [], '', 0);
      this.currentPoll.liveQuestions.push(newRound);
      const copyQuestion = structuredClone(this.currentPoll.liveQuestions);
      this.currentPoll.choices.push([new PollChoice(), new PollChoice()]);
      this.store.dispatch(setLiveQuestionAction({ liveQuestions: copyQuestion} ));
      if (this.currentPoll.liveQuestions.length === 25) {
        this.hideAddElectionButton = true;
      }
    }
  }

  addElection() {
    if (this.currentPoll.electionIndexes.length < 4) {
      const newRound = new LiveQuestion(this.currentPoll.liveQuestions.length, [], '', 0);
      this.currentPoll.liveQuestions.push(newRound);
      this.currentPoll.electionIndexes.push(this.currentPoll.liveQuestions.length - 1);
      if (this.currentPoll.liveQuestions.length === 25) {
        this.hideAddElectionButton = true;
      }
    }
  }

  confirmCreationElection($event) {
    if ($event) {
      this.addElection();
    } else {
      this.electionsWarningPopup = false;
    }
    this.displayElectionListWarning = !this.displayElectionListWarning;

  }

  deleteList() {
    if (!this.currentPoll.electionIndexes.includes(this.currentPoll.liveQuestions.length - 1)) {
      this.currentPoll.liveQuestions.pop();
      const copyQuestions = structuredClone(this.currentPoll.liveQuestions);
      this.store.dispatch(setLiveQuestionAction({ liveQuestions: copyQuestions} ));
      this.currentPoll.choices.pop();
      const copyChoices = structuredClone(this.currentPoll.choices);
      this.store.dispatch(setChoicesAction({ choices : copyChoices }));
    }
  }

  deleteElection() {
    if (this.currentPoll.electionIndexes.length > 1) {
      this.currentPoll.liveQuestions.splice(this.currentPoll.electionIndexes[this.currentPoll.electionIndexes.length - 1],
        this.currentPoll.liveQuestions.length - this.currentPoll.electionIndexes[this.currentPoll.electionIndexes.length - 1]);
    }
    this.currentPoll.electionIndexes.pop();
    const copyQuestion = structuredClone(this.currentPoll.liveQuestions);
    this.store.dispatch(setLiveQuestionAction({ liveQuestions: copyQuestion} ));
  }

  deleteQuestion(index: number) {
    this.currentPoll.liveQuestions.splice(index, 1);
    this.currentPoll.choices = this.currentPoll.choices.filter(c => c !== this.currentPoll.choices[index]);
    const copyChoices = structuredClone(this.currentPoll.choices);
    const copyQuestion = structuredClone(this.currentPoll.liveQuestions);
    this.store.dispatch(setLiveQuestionAction({ liveQuestions: copyQuestion} ));
    this.store.dispatch(setChoicesAction({ choices: copyChoices }));
  }

  displayVoters() {
    this.isImportError = false;
    this.shouldDisplayVoters = true;
  }

  calculateLicences(date: string) {
    const find = '-';
    const re = new RegExp(find, 'g');
    const formatedDate = date.replace(re, '');
    this.apiService
      .getLicensesDate(formatedDate)
      .subscribe((res: LicenseResponse) => {
        this.maxVoters = res.licences.votes;
        this.maxSMSVoters = res.licences.sms;
      });
  }

  getCommand(data: WsCommand) {
    if (this.numberOfVote > 1) { this.isPlurinominalValid(); }
    if (!this.plurinominalError) {
      this.shouldDisplay2FAWarning = this.currentPoll.is2FA && this.currentPoll.voters.filter(x => this.common.isNullOrUndefined(x.phone) && this.common.isNullOrUndefined(x.validationCode)).length > 0;
      if (!this.shouldDisplay2FAWarning) { this.launchIndex = data.index; this.shouldDisplayLaunchWarning = true; }
    }
  }

  getPaymentSessonId(event: string) {
    localStorage.setItem(event, JSON.stringify(this.currentPoll));
  }

  buyOffer($event) {
    this.getPaymentSessonId('editedPoll');
    this.apiService.createCheckoutSession($event).subscribe((res: any) => {
      this.stripe
        .redirectToCheckout({ sessionId: res.sessionId })
        .then(result => {
          console.log(result);
        });
    });
  }

  displaySMSOption($event) {
    this.selectedOffer = $event;
    this.smsAdditionalOption = true;
  }

  setOnlySMSOption($event) {
    switch ($event) {
      case 0: {
        this.currentPoll.is2FA = false;
        this.currentPoll.isSMS = false;
        this.dismissInitialPopup();
        break;
      }
      case 1: {
        const smsCode = this.isAssoc != false ? this.stripeConstants.sms.assoc.pack200 : this.stripeConstants.sms.corp.pack200;
        this.createSMSOnlyCart(smsCode);
        break;
      }
      case 2: {
        const smsCode = this.isAssoc != false ? this.stripeConstants.sms.assoc.pack500 : this.stripeConstants.sms.corp.pack500;
        this.createSMSOnlyCart(smsCode);
        break;
      }
      case 3: {
        const smsCode = this.isAssoc != false ? this.stripeConstants.sms.assoc.pack1000 : this.stripeConstants.sms.corp.pack1000;
        this.createSMSOnlyCart(smsCode);
        break;
      }
      case 4: {
        const smsCode = this.isAssoc != false ? this.stripeConstants.sms.assoc.pack5000 : this.stripeConstants.sms.corp.pack5000;
        this.createSMSOnlyCart(smsCode);
        break;
      }
    }
  }

  setSMSOption($event) {
    switch ($event) {
      case 0: {
        this.currentPoll.is2FA = false;
        this.currentPoll.isSMS = false;
        this.selectOffer(this.selectedOffer, null);
        break;
      }
      case 1: {
        const smsCode = this.stripeConstants.sms.assoc.pack200;
        this.selectOffer(this.selectedOffer, smsCode);
        break;
      }
      case 2: {
        const smsCode = this.stripeConstants.sms.assoc.pack500;
        this.selectOffer(this.selectedOffer, smsCode);
        break;
      }
      case 3: {
        const smsCode = this.stripeConstants.sms.assoc.pack1000;
        this.selectOffer(this.selectedOffer, smsCode);
        break;
      }
      case 4: {
        const smsCode = this.stripeConstants.sms.assoc.pack5000;
        this.selectOffer(this.selectedOffer, smsCode);
        break;
      }
    }
  }

  selectOffer(index: number, smsOption: string = '') {
    let price = '';
    switch (index) {
      case 0: { price = this.stripeConstants.event.association; break; }
      case 1: { price = this.stripeConstants.pack.asso50; break; }
      case 2: { price = this.stripeConstants.pack.asso1000; break; }
      case 3: { price = this.stripeConstants.pack.asso2ag; }
    }
    this.createCart(price, smsOption);
  }

  createCart(offer: string, smsCode: string = '') {
    const items = offer.length > 0 ? [new BoughtItem(offer, 1)] : [];
    if (smsCode !== null && smsCode.length > 0) { items.push(new BoughtItem(smsCode, 1)); }
    const cart = new ShoppingCart(
        items,
        this.stripeConstants.tax20,
        null
      );
    this.buyOffer(cart);
  }

  createSMSOnlyCart(smsCode: string = '') {
    const item = [new BoughtItem(smsCode, 1)];
    const cart = new ShoppingCart(
      item,
      this.stripeConstants.tax20,
      null
    );
    this.buyOffer(cart);
}

  displayMoreInfo($event) {
    this.moreOption = true;
    this.selectedOffer = $event[0];
    this.isAssoc = $event[1] == 1;
  }

  openOffers($event) {
    if ($event) {
      this.getContacted = true;
    }
    else {
      this.isPayment = true;
    }
    this.moreOption = false;
  }

  dismissInitialPopup() {
    this.smsOption = false;
    this.isPaymentError = false;
    this.confirmContact = false;
    this.getContacted = false;
    this.moreOption = false;
    this.smsAdditionalOption = false;
  }

  dismissPayPopup() {
    this.paymentPopup = false;
    this.isPaymentError = false;
    this.isLaunching = false;
  }

  dismissMoreSMSPopup() {
    if (this.isPaymentError) {
      this.confirmContact = true;
      this.isPaymentError = false;
    } else {
      this.confirmContact = false;
    }
  }

  dismissWarning() {
    this.plurinominalError = false;
  }

  allElectionTitleIsSet() {
    this.subjects = true;
    this.currentPoll.liveQuestions.forEach((element) => {
      if (element.title === undefined || element.title.length < 1) {
        return false;
      }
    });
    return true;
  }

  areResolutionTitlesValid(): boolean {
    let isValid = true;
    if (this.currentPoll.resolutions.length === 0) {
      return false;
    }
    this.currentPoll.resolutions.forEach((element) => {
      if (element.name === undefined || element.name.length < 1) {
        isValid = false;
      }
    });
    return isValid;
  }

  electionHasCandidates(index: number) {
    return this.currentPoll.choices[index] != null && this.currentPoll.choices[index].filter(x => x.name != undefined && x.name.length >= 1).length > 0;
  }

  validateElection() {
    if (this.currentPoll.isAssessors || !this.validAssessors) {
      this.validAssessors = this.validateAssessors();
    }
    if (this.validAssessors &&
        this.isFieldValid(this.currentPoll.title) &&
        this.currentPoll.votingType === 'election' &&
        this.currentPoll.choices[0] != null &&
        this.subjects &&
        this.validateSender) {
      if (this.currentPoll.temporality !== 'live' && this.isFieldValid(this.currentPoll.description) &&
        this.electionHasCandidates(0) && this.validateMinMax()) {
        return true;
      }
      if (this.currentPoll.temporality === 'live' && this.currentPoll.choices[0][0] != undefined && this.electionHasCandidates(0)) {
        this.isLaunchEnabled = true;
        return true;
      }
    }
    return false;
  }

  validateMinMax() {
    const validations = this.currentPoll.liveQuestions.map((value, index) => this.getChoiceCounter(index) >= value.numberOfVote && value.minNumberVote <= value.numberOfVote);
    return validations.indexOf(false) === -1;
  }

  getChoiceCounter(index: number) {
    const cl = this.currentPoll.choices.length <= index ? 1 : this.currentPoll.choices[index].length;
    const ol = this.currentPoll.liveQuestions.length <= index ? 1 : this.currentPoll.liveQuestions[index].options.length;
    return Math.max(cl, ol);
  }

  validateResolution() {
    if (this.isFieldValid(this.currentPoll.title) &&
        this.currentPoll.votingType === 'resolution' &&
        this.currentPoll.resolutions != null &&
        this.validateSender) {
      if (this.currentPoll.temporality !== 'live' && this.isFieldValid(this.currentPoll.description) &&
      this.areResolutionTitlesValid()) {
        return true;
      }
      if (this.currentPoll.temporality === 'live' && this.areResolutionTitlesValid()) {
        return true;
      }
    }
    return false;
  }

  validateAssessors() {
    let validAssessors = 0;
    if (!this.currentPoll.isAssessors) { return true; }
    for (let i = 0; i < this.currentPoll.assessorsMails.length; i++) {
      if (this.common.validateEmail(this.currentPoll.assessorsMails[i]) && this.currentPoll.assessorsNames[i].length > 2) {
        validAssessors++;
        this.assessorIndexes.push(i);
      } else if (this.currentPoll.assessorsNames[i].localeCompare('') != 0 || this.currentPoll.assessorsMails[i].localeCompare('') != 0) {
        return false;
 }
    }
    this.numberValidAssessors = validAssessors;
    return validAssessors > 2;
  }

  hasAllEventNamesFilled(): boolean { return this.currentPoll.liveQuestions.filter(y => this.currentPoll.electionIndexes.indexOf(y.index) > -1).filter(x => x.electionTitle === undefined || x.electionTitle.length < 1).length === 0; }

  hasAllCandidatesFilled(): boolean { return this.currentPoll.liveQuestions.map(x => this.electionHasCandidates(x.index)).indexOf(false) === -1; }

  validateCorporate() {
    if (this.currentPoll.isAssessors || !this.validAssessors) {
      this.validAssessors = this.validateAssessors();
    }
    if (this.validAssessors &&
        this.isFieldValid(this.currentPoll.title) &&
        this.currentPoll.votingType === 'list' &&
        this.currentPoll.choices[0] != null &&
        this.subjects &&
        this.validateSender &&
        this.isFieldValid(this.currentPoll.description)) {
      if (this.hasAllCandidatesFilled()) {
        this.assessorError = false;
        return this.hasAllEventNamesFilled();
      }
    }
    return false;
  }

  validateFields() {
    if (this.currentPoll.votingType === undefined) {
      this.currentPoll.votingType = 'resolution';
    }
    this.allElectionTitleIsSet();
    this.isLaunchEnabled = false;
    if (this.currentPoll.choices.length === 0) {
      this.currentPoll.choices.push(Array(new PollChoice()));
    }
    if (this.currentPoll.voters.length >= 1 && this.checkDates()) {
      switch (this.currentPoll.votingType) {
        case 'resolution':
          this.isPollComplete = this.validateResolution();
          break;
        case 'election':
          this.isPollComplete = this.validateElection();
          break;
        case 'list':
          this.isPollComplete = this.validateCorporate();
          break;
      }
    } else {
      this.isPollComplete = false;
    }
  }

  isFieldValid(field: string) {
    return field != null && field != '';
  }

  isPlurinominalValid() {
    if (this.currentPoll.temporality === 'planned') {
      this.currentPoll.liveQuestions.forEach(function(element, index) {
        element.numberOfVote > this.currentPoll.choices[index].length ?
        (this.plurinominalError = true) : (this.plurinominalError = false);
      }, this);
    } else {
      this.currentPoll.choices[0] = this.currentPoll.choices[0].filter(value => value.name != undefined);
      for (const election of this.currentPoll.liveQuestions) {
        if (
          election.numberOfVote >
          this.currentPoll.choices[election.index].length
        ) {
          this.plurinominalError = true;
          break;
        }
        this.plurinominalError = false;
      }
    }
  }

  displayLaunchWarning() {
    this.paymentPopup = false;
    if (this.numberOfVote > 1) { this.isPlurinominalValid(); }
    if (!this.plurinominalError) {
      if (
        this.votersCount <= this.maxVoters &&
        ((this.needsSms() && this.smsVoterCount <= this.maxSMSVoters) || !this.needsSms())
      ) {
        if (this.currentPoll.votingType === 'list' && this.votersCount < 2) {
          this.shouldDisplayListWarning =  true;
        } else {
          this.shouldDisplay2FAWarning = this.currentPoll.is2FA && this.currentPoll.voters.filter(x => this.common.isNullOrUndefined(x.phone) && this.common.isNullOrUndefined(x.validationCode)).length > 0;
          if (!this.shouldDisplay2FAWarning) { this.shouldDisplayLaunchWarning = true; }
        }
      } else {
        if (this.maxVoters > 5) {
          this.changeOfferReason =
            this.votersCount > this.maxVoters ? 'addVoters' : 'addSMS';
          this.isPaymentError = true;
        } else {
          this.isPayment = true;
          this.changeOfferReason = 'newOffer';
        }
      }
    }
  }

  handleLaunchWarning(event: boolean) {
    this.shouldDisplayLaunchWarning = false;
    this.shouldDisplay2FAWarning = false;
    if (event && this.shouldDisplayLive) {
      this.isLiveResolution
        ? this.configureResolution()
        : this.configureElection();
    }
    if (event && !this.shouldDisplayLive) {
      this.publish();
    }
  }

  handlePaymentWarning(event: boolean) {
    this.paymentPopup = false;
    this.isPayment = false;

    if (event && this.shouldDisplayLive) {
      this.isLiveResolution
        ? this.configureResolution()
        : this.configureElection();
    }
    if (event && !this.shouldDisplayLive) {
      this.publish();
    }
  }

  publish() {
    if (
      this.votersCount > this.maxVoters &&
      ((this.currentPoll.isSMS && this.votersCount <= this.maxSMSVoters) ||
        !this.currentPoll.isSMS)
    ) {
      if (this.maxVoters > 5) {
        this.changeOfferReason =
          this.votersCount > this.maxVoters ? 'addVoters' : 'addSMS';
        this.isPaymentError = true;
      } else {
        this.isPayment = true;
        this.changeOfferReason = 'newOffer';
      }
    } else {
      if (this.currentPoll.votingType === 'election') {
        this.configureElection();
      }
      if (this.currentPoll.votingType === 'resolution') {
        this.configureResolution();
      }
      if (this.currentPoll.votingType === 'list') {
        this.configureElection();
      }
    }
  }

  configureListElection(questions: Array<ListQuestion>){
    let idx = 0;
    for (const poll in this.currentPoll.liveQuestions) {
      const choices: Array<string> = [];
      const links: Array<string> = [];
      const candidateImages: Array<string> = [];
      for (const choice of this.currentPoll.choices[idx]) {
        if (this.common.isNotEmptyString(choice.name)) {
          if (choice.gender === 'M') { choices.push(`M. ${choice.name}`); }
          else { choices.push(`Mme ${choice.name}`); }
        }
        const link = this.common.isNotEmptyString(choice.link) ? choice.link : null;
        links.push(link);
        const candidateImage = this.common.isNotEmptyString(choice.image) ? choice.image : null;
        candidateImages.push(candidateImage);
      }

      if (this.currentPoll.liveQuestions[poll].listImage == undefined) {
        this.currentPoll.liveQuestions[poll].listImage = [''];
      }
      if (this.currentPoll.electionIndexes.includes(idx)) {
        questions.push(new ListQuestion(this.currentPoll.liveQuestions[poll].electionTitle, [new PollQuestion(
          this.currentPoll.liveQuestions[poll].title,
          choices,
          this.currentPoll.liveQuestions[poll].numberOfVote,
          links,
          this.currentPoll.liveQuestions[poll].listLink,
          this.currentPoll.liveQuestions[poll].listImage,
          candidateImages
        )]));
      }
      else {
        questions[questions.length - 1].lists.push(new PollQuestion(
          this.currentPoll.liveQuestions[poll].title,
          choices,
          this.currentPoll.liveQuestions[poll].numberOfVote,
          links,
          this.currentPoll.liveQuestions[poll].listLink,
          this.currentPoll.liveQuestions[poll].listImage,
          candidateImages
        ));
      }
      idx++;
    }
    return questions;
  }

  setImageUrlToLocation(url: string, destination: ImageTypes, index = 0, candidateIndex = 0) {
    switch (destination) {
      case ImageTypes.Poll: { this.currentPoll.imageId = url; this.store.dispatch(setPollImageAction({imageId: url})); break; }
      case ImageTypes.List: { this.currentPoll.liveQuestions[index].listImage = [url];
                              const copyQuestion = structuredClone(this.currentPoll.liveQuestions);
                              this.store.dispatch(setLiveQuestionAction({ liveQuestions: copyQuestion} )); break; }
      case ImageTypes.ListCandidate: {
        const choiceClone = structuredClone(this.currentPoll.choices[index][candidateIndex]);
        choiceClone.image = url;
        this.currentPoll.choices[index][candidateIndex] = choiceClone;
        const choiceClones = structuredClone(this.currentPoll.choices);
        this.store.dispatch(setChoicesAction({ choices: choiceClones}));
        const copyQuestion = structuredClone(this.currentPoll.liveQuestions);
        this.store.dispatch(setLiveQuestionAction({ liveQuestions: copyQuestion} )); break;
      }
      case ImageTypes.Resolution: {
        if (this.currentPoll.resolutions[index] == null) { this.currentPoll.resolutions[index] = new Resolution(this.currentPoll.temporality === 'live'); }
        this.currentPoll.resolutions[index].image = url;
        const resolutionCopy = structuredClone(this.currentPoll.resolutions);
        this.store.dispatch(setResolutionsAction({ resolutions: resolutionCopy })); }
    }
  }

  uploadImage(image: Blob, ext: string, destination: ImageTypes, index = 0, candidateIndex = 0) {
    const pollPayload = new FormData();
    const name = v4() + ext;
    pollPayload.append(name, image);
    this.isLoading = true;
    this.apiService.saveElectionImage(pollPayload).subscribe((res) => {
      getDownloadURL(ref(storage, res[0])).then((url) => {
        this.isLoading = false;
        this.setImageUrlToLocation(url, destination, index, candidateIndex);
        this.markPollChanges();
      });
    });
  }

  configureElection() {
    const createdQuestions: Array<PollQuestion> = [];
    let createdQuestion: PollQuestion;
    let questions: Array<ListQuestion> = [];
    for (const i in this.currentPoll.choices.filter(x => x.filter(c => c.name !== null && c.name !== undefined && c.name.length > 0).length > 0)) {
      const choices: Array<string> = [];
      const links: Array<string> = [];
      const candidateImages: Array<string> = [];
      if (this.currentPoll.votingType === 'list') {
        if (this.subjectLinks[i] != null && this.subjectLinks[i] != '') {
          links.push(this.subjectLinks[i]);
        }
        else {
          links.push(null);
        }
      } else {
        if (this.currentPoll.liveQuestions[i] != null && this.currentPoll.liveQuestions[i].listLink != null && this.currentPoll.liveQuestions[i].listLink != '') {
          links.push(this.currentPoll.liveQuestions[i].listLink);
        }
        else {
          links.push(null);
        }
      }
      for (const choice of this.currentPoll.choices[i]) {
        if (this.common.isNotEmptyString(choice.name)) {
          choices.push(choice.name);
        }
        const link = this.common.isNotEmptyString(choice.link) ? choice.link : null;
        links.push(link);
        const candidateImage = this.common.isNotEmptyString(choice.image) ? choice.image : null;
        candidateImages.push(candidateImage);
      }
      createdQuestion = new PollQuestion(
        this.currentPoll.liveQuestions[i].title,
        choices,
        this.currentPoll.liveQuestions[i].numberOfVote,
        links,
        this.currentPoll.liveQuestions[i].listLink,
        [],
        candidateImages
      );
      if (this.currentPoll.liveQuestions[i].minNumberVote != null) {
        createdQuestion.minNumberOfVote = this.currentPoll.liveQuestions[i].minNumberVote;
      }
      createdQuestions.push(createdQuestion);
    }
    if (this.currentPoll.votingType === 'list') {
      questions = this.configureListElection(questions);
    }
    else {
      questions.push(new ListQuestion('', createdQuestions));
    }

    this.setupVoters();

    const pollData = this.createPollData(questions);
    pollData.isLive = this.currentPoll.temporality === 'live';
    this.sendPoll(pollData);
  }

  createPollData(questions: Array<ListQuestion>) {
    return new PollCreationData(
      this.currentPoll.title,
      this.currentPoll.voters,
      this.currentPoll.description,
      questions,
      this.getStartDate(),
      this.getEndDate(),
      this.currentPoll.imageId,
      this.currentPoll.votingType,
      this.currentPoll.isUncrypted,
      this.currentPoll.isSMS,
      this.currentPoll.numberOfVote,
      this.currentPoll.is2FA,
      this.currentPoll.sender,
      this.currentPoll.sharedLinks,
      this.hasHotline,
      this.common.getLangCode(),
      this.currentPoll.id
    );
  }

  configureResolution() {
    let pollData: PollCreationData;
    const resolutions: Array<PollQuestion> = [];
    const questions: Array<ListQuestion> = [];

    for (const resolution of this.currentPoll.resolutions) {
      const choices: Array<string> = [];
      for (const choice of resolution.choices) {
        choices.push(choice.name);
      }
      const images = (resolution.image != null && resolution.image != undefined && resolution.image.length > 1) ? [resolution.image] : [];
      const createdQuestion = new PollQuestion(resolution.name, choices, 1, [resolution.link], null, images);
      if (createdQuestion.question != null) {
        resolutions.push(createdQuestion);
      }
    }

    questions.push(new ListQuestion('', resolutions));
    this.setupVoters();
    pollData = this.createPollData(questions);
    if (this.currentPoll.temporality !== 'planned') {
      pollData.type = this.currentPoll.temporality;
      pollData.isLive = true;
    }
    this.sendPoll(pollData);
  }

  checkFullyFilledAssessors() {
    for (let i = 0; i < this.currentPoll.assessorsMails.length; i++) {
      if (!this.common.validateEmail(this.currentPoll.assessorsMails[i]) || this.currentPoll.assessorsNames[i].length < 3) {
        return true;
      }
    }
    return false;
  }

  getStartDate(): number {
    if (this.common.checkIfSafari() && this.currentPoll.temporality === 'planned') {
      const dateParts = this.currentPoll.startDate.split('/');
      if (dateParts.length === 1) {
        return new Date(
          this.currentPoll.startDate + 'T' + this.currentPoll.startTime
        ).getTime();
      } else {
        this.currentPoll.startDate =
          dateParts[1] + '/' + dateParts[0] + '/' + dateParts[2];
      }
    }
    return new Date(
      this.currentPoll.startDate + ' ' + this.currentPoll.startTime
    ).getTime();
  }

  getEndDate(): number {
    if (this.common.checkIfSafari() && this.currentPoll.temporality === 'planned') {
      const dateParts = this.currentPoll.endDate.split('/');
      if (dateParts.length === 1) {
        return new Date(
          this.currentPoll.endDate + 'T' + this.currentPoll.endTime
        ).getTime();
      } else {
        this.currentPoll.endDate =
          dateParts[1] + '/' + dateParts[0] + '/' + dateParts[2];
      }
    }
    return new Date(
      this.currentPoll.endDate + ' ' + this.currentPoll.endTime
    ).getTime();
  }

  setupVoters() {
    if (!this.needsSms()) {
      this.currentPoll.voters = this.currentPoll.voters.map(voter => new Voter(voter.firstName, voter.lastName, voter.email, voter.weight, undefined, voter.validationCode, voter.language));
    }
    this.currentPoll.voters = this.currentPoll.voters.filter(x => x.email.length > 0);
  }

  getContactForOption($event: string) {
    const mailData = this.licenseService.getContactMailObject(this.selectedOffer, this.accountName, this.isAssoc, $event);
    this.authenticationService.sendContactMail(mailData).subscribe((res) => {
      this.confirmContact = true;
      this.getContacted = false;
    });
  }

  getContactPhone($event: string, type: string) {
    let subject: string;

    if (type.localeCompare('sms') == 0) {
      subject = 'Souhaite avoir l\'option SMS<br>';
    }
    else if (type.localeCompare('sms') == 0) {
      subject = 'Souhaite avoir plus de votants<br>';
 }
    else {
      subject = '[Double Factor Auth] Souhaite avoir de SMS<br>';
 }
    let mailString =
      subject +
      'E-mail: ' +
      this.accountName +
      '<br>Association: ' +
      this.clientType +
      '<br>Numéro de téléphone: ' +
      $event +
      '<br>Offre: ';
    if (this.licenseTime === 'D') { mailString += 'Forfait 1 événement'; }
    else if (this.licenseTime === 'DM') { mailString += 'Pack 5 événements'; }
    else { mailString += 'Abonnement annuel'; }
    const mailData = new ContactData(mailString);

    this.authenticationService.sendContactMail(mailData).subscribe(() => {
      this.warning2FA = false;
      this.isPaymentError = false;
      this.confirmContact = true;
      this.contactedPopup = true;
    });
  }

  setAssessorsObject() {
    this.currentPoll.assessors = [];
    for (let i = 0; i < this.currentPoll.assessorsMails.length; i++) {
      if (this.currentPoll.assessorsNames[i].localeCompare('') != 0 &&
          this.currentPoll.assessorsMails[i].localeCompare('') != 0) {
      this.currentPoll.assessors.push(new Assessor(this.currentPoll.assessorsNames[i], this.currentPoll.assessorsMails[i]));
      }
    }
  }

  goToProfile() {
    this.router.navigate(['/profile']);
  }

  sendPoll(data: PollCreationData) {
    if (
      (data.description == null ||
        data.name == null ||
        data.questions.length === 0 ||
        data.voters.length < 1 ||
        isNaN(data.startDate) ||
        isNaN(data.endDate)) &&
        !data.isLive
    ) {
      this.isError = true;
      this.errorMessage =
        ' Ce scrutin ne peut être lancé car certains éléments sont manquants ou incorrects ';
    } else {
      if (!this.isLaunching) {
        if (data.isLive) {
          data.startDate = new Date().getTime();
          const today = new Date();
          const tomorrow = new Date(today);
          tomorrow.setDate(tomorrow.getDate() + 1);
          data.endDate = tomorrow.getTime();
          data.shortName = 'Live';
          data.description = ' ';
        }
        if (data.sender === undefined) {
          data.sender = '';
        }
        if (data.sharedLinks == undefined || data.sharedLinks.length === 0) {
          data.sharedLinks = undefined;
        }
        if (this.currentPoll.isAssessors) {
          this.setAssessorsObject();
          data.assessors = this.currentPoll.assessors;
        }
        const jsonObj = JSON.stringify(data);
        this.isLaunching = true;
        this.isLoading = true;
        this.apiService.createPoll(jsonObj).subscribe(
          (res: any ) => {
            const id = res.id;
            this.isLoading = false;
            if (this.currentPoll.temporality !== 'live') {
              this.router.navigate(['/poll-planned/' + id]);
            } else {
              this.liveService.connect(id).subscribe((msg) => {
                console.log('Response from websocket: ' + msg);
              });
              if (this.launchIndex >= 0) { this.liveService.send(`open ${this.launchIndex}`, id); }
              this.isLiveOpen = true;
              this.router.navigate(['/poll-live/' + id]);
            }
          },
          (err: HttpErrorResponse) => {
            this.isLoading = false;
            switch (err.status) {
              case 402:
                this.isPaymentError = true;
                if (err.error.error.includes('No free elections at the same time')) {
                  this.changeOfferReason = 'multiFree';
                }
                if (err.error.error.includes('24 hours maximum')) {
                  this.changeOfferReason = 'max24';
                }
                break;
              case 0:
                this.goToPoll();
                break;
              default: {
                this.isError = true;
                this.errorMessage =
                  'Nous sommes désolés mais vous ne pouvez accéder au contenu \
                erreur ' +
                  err.status;
                this.errorDescription = err.error.error;
              }
            }
          }
        );
      }
    }
  }

  resumeAfterPayment() {
    this.votersCount = this.currentPoll.voters.length;
    this.smsVoterCount = this.currentPoll.voters.filter(x => x.phone == null || x.phone === undefined).length;
    this.shouldResume = true;
    this.numberOfVote = this.currentPoll.numberOfVote;
    if (this.common.isNotNullOrUndefined(this.currentPoll.startDate)) {
      this.calculateLicences(this.currentPoll.startDate);
    }
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/connexion']);
  }

  calculateLiveAvailability() {
    this.canLaunchLive = this.currentPoll.temporality === 'live' &&
      this.currentPoll.voters.length >= 1;
  }

  invalidEmail() {
    this.canLaunchLive = false;
  }

  backArrow() {
    if (this.route.snapshot.paramMap.get('id') !== null && this.route.snapshot.paramMap.get('id') !== 'votersascandidates') {
      this.router.navigate(['/polls/']);
    } else {
      if (this.shouldDisplayLive) { this.shouldDisplayLive = false; }
      else {
        this.goToPoll();
      }
    }
  }

  getLiveCommand($event: boolean) {
    if ($event) {
      this.isLiveOpen = true;
      this.shouldDisplayLive = true;
      this.validateFields();
    } else {
      this.shouldDisplayLive = false;
    }
  }

  dismissError() {
    this.isImportError = false;
    this.contactedPopup = false;
    this.warning2FA = false;
    this.isError = false;
    this.isOutdatedImportFileError = false;
    this.shouldDisplayVoters = false;
    this.isLaunching = false;
    if (this.startDateError) {
      this.startDateError = false;
      this.router.navigate(['/poll-ongoing/' + this.currentPoll.id]);
    }
  }

  dismissPay() {
    this.isPayment = false;
    this.isClientAssoc();
  }

  cancelNewLicencesPopup() {
    this.shouldDisplayNewLicences = false;
  }

  isClientAssoc() {
    if (this.clientType !== null) { this.isAssoc = this.clientType !== 'corp'; }
    else { this.isAssoc = true; }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.numberOfVote > 1) { this.isPlurinominalValid(); }
    this.refitHeader();
  }

  ngOnDestroy() {
    if (this.currentPoll.draftSaved) { this.saveDraft(); }
  }

  updateDraft(data: DraftForm) {
    const x = document.getElementById('snackbar');
    x.style.display = 'none';
    this.apiService.updateDraft(data).subscribe();
  }

  saveAsNewDraft(data: DraftForm) {
    this.store.dispatch(setDraftFlagAction({draftSaved: true}));
    this.apiService.saveDraft(data).subscribe((res) =>
    {
      const x = document.getElementById('snackbar');
      x.className = 'show';
      setTimeout(() => {
        x.className = x.className.replace('show', '');
      }, 3000);
    });
  }

  saveDraft() {
    console.log('Draft is saving.....');
    const data: DraftForm = new  DraftForm().fromPoll(this.currentPoll);
    this.currentPoll.draftSaved ? this.updateDraft(data) : this.saveAsNewDraft(data);
  }

  serializePoll(draftPoll: Poll) {
    this.store.dispatch(setDraftFlagAction({draftSaved: draftPoll.draftSaved}));
    this.store.dispatch(setVotingTypeAction({votingType: draftPoll.votingType}));
    this.store.dispatch(setTemporalityAction({temporality: draftPoll.temporality}));
    this.store.dispatch(setStartDateAction({startDate: draftPoll.startDate}));
    this.store.dispatch(setStartTimeAction({startTime: draftPoll.startTime}));
    this.store.dispatch(setEndDateAction({endDate: draftPoll.endDate}));
    this.store.dispatch(setEndTimeAction({endTime: draftPoll.endTime}));
    this.store.dispatch(setLiveQuestionAction({liveQuestions: draftPoll.liveQuestions}));
    this.store.dispatch(set2FAAction({is2FA: draftPoll.is2FA}));
    this.store.dispatch(setSMSAction({isSMS: draftPoll.isSMS}));
    this.store.dispatch(setSenderAction({sender: draftPoll.sender}));
    this.store.dispatch(setDocumentAction({sharedLinks: draftPoll.sharedLinks}));
    this.store.dispatch(setResolutionsAction({resolutions: draftPoll.resolutions}));
    this.store.dispatch(setVotersAction({voters: draftPoll.voters}));
    this.store.dispatch(setActivateAssessorsAction({isAssessors: draftPoll.isAssessors}));
    this.store.dispatch(setChoicesAction({choices: draftPoll.choices}));
    this.store.dispatch(setAssessorsMailsAction({assessorsMails: draftPoll.assessorsMails}));
    this.store.dispatch(setAssessorsNamesAction({assessorsNames: draftPoll.assessorsNames}));
    this.store.dispatch(setTitleAction({title: draftPoll.title}));
    this.store.dispatch(setPublicVoteAction({isUncrypted: draftPoll.isUncrypted}));
    this.store.dispatch(setDescriptionAction({description: draftPoll.description}));
    this.store.dispatch(setPollImageAction({imageId: draftPoll.imageId}));
    this.currentPoll.electionIndexes = draftPoll.electionIndexes;
  }

  setPollData(id: string) {
    this.route.queryParams.subscribe((params) => {
      const editCondition = params.publishedClone;
      if (editCondition === 'false' || editCondition === undefined) {
        this.currentPoll.id = id;
        this.apiService.getDraft(id).subscribe((res: DraftForm) => {
          this.shouldResume = true;
          const draftPoll: Poll = JSON.parse(res.data);
          this.serializePoll(draftPoll);
          this.updateSecondaryDataAfterRestore();
          if (editCondition === 'false') {
            this.initDraftId();
            this.currentPoll.draftSaved = false;
          }
          this.shouldResume = false;
        });
      } else {
        this.convertExistingPollToCreationData(id);
      }
    });
    this.changeDetectorRef.detectChanges();
  }

  displayPreview() {
    const location = `${window.location.protocol}//${window.location.host}/poll-preview/${this.currentPoll.id}`;
    window.open(location, '_blank');
  }

  convertExistingPollToCreationData(id: string) {
    this.apiService.getCloningPoll(id).subscribe((res: CloningPoll) => {
      this.currentPoll.temporality = res.isLive ? 'live' : 'planned';
      this.currentPoll.title = res.name;
      this.currentPoll.description = res.description;
      this.currentPoll.sender = res.sender;
      this.currentPoll.votingType = res.type;
      this.currentPoll.isUncrypted = res.publicVote;
      this.currentPoll.sharedLinks = res.sharedLinks;
      this.currentPoll.imageId = res.image;
      this.currentPoll = Object.assign({}, this.currentPoll);
      this.currentPoll.voters = res.voters.map(x => new Voter(x.firstName, x.lastName, x.email, x.weight, x.phone, x.validationCode, x.language));
      this.currentPoll.assessors = res.assessors;
      if (res.assessors.length > 0) {
        this.currentPoll.assessorsMails = res.assessors.map( a => a.email);
        this.currentPoll.assessorsNames = res.assessors.map( a => a.name);
      }
      this.currentPoll.isAssessors = res.assessors.length > 0;
      this.currentPoll.isSMS = res.smsOption;
      this.currentPoll.is2FA = res.is2FA;
      this.setChoicesFromRounds(res);
      this.serializePoll(JSON.parse(JSON.stringify(this.currentPoll)));
      this.updateSecondaryDataAfterRestore();
      this.validateAssessors();
    });
  }

  setChoicesFromRounds(data: CloningPoll) {
    switch (data.type) {
      case 'resolution':
      case 'live': {
        const displayedQuestions = data.rounds.map((r) => r.displayedQuestions).flat();
        this.currentPoll.resolutions = displayedQuestions.map(q => new Resolution(true).fromDisplayedQuestion(q));
        if (data.type === 'live') { this.currentPoll.votingType = 'resolution'; }
        break;
      }
      case 'election': {
        const displayedQuestions = data.rounds.map((r) => r.displayedQuestions).flat();
        this.currentPoll.liveQuestions = [];
        this.currentPoll.choices = [];
        for (const i in displayedQuestions) {
          const question = displayedQuestions[i];
          const newQuestion = new LiveQuestion(Number(i), question.answers, question.question, 0, question.numberOfVote);
          if (question.minNumberOfVote !== null) { newQuestion.minNumberVote = question.minNumberOfVote; }
          if (question.links.length > 1) {
            newQuestion.listLink = question.links[0];
          }
          this.currentPoll.liveQuestions.push(newQuestion);
          const choices = question.answers.map(q => PollChoice.withName(q));
          for (const choice in choices) {
            if (question.links.length > Number(choice)) {
              choices[choice].link = question.links[Number(choice) + 1];
            }
          }
          this.currentPoll.choices.push(choices);
        }
        break;
      }
      case 'list': {
        this.currentPoll.liveQuestions = [];
        this.currentPoll.choices = [];
        data.rounds.forEach(round => {
          const questions = round.displayedQuestions;
          if (questions.length > 0) {
            const lists = questions[0].answers.splice(0, questions[0].answers.length - 1);
            for (let i = 0; i < lists.length; i++) {
              const answers = questions.filter(x => x.parent === i).map(q => q.question);
              const newList = new LiveQuestion(i, answers, lists[i], 0, 1);
              if (i === 0) { newList.electionTitle = round.title; }
              if (questions[0].listLink !== null && questions[0].listLink.length > i && questions[0].listLink[i] !== null) {
                newList.listLink = questions[0].listLink[i];
              }
              this.currentPoll.liveQuestions.push(newList);
              const previousLinksCount = questions.filter(x => x.parent !== null).filter(y => y.parent < i).length;
              const choices = newList.options.map(q => PollChoice.withNameAndGender(q));
              for (let j = 0; j < choices.length; j++) {
                if (questions[0].links.length > j + previousLinksCount && questions[0].links[j + previousLinksCount] !== null) {
                  choices[j].link = questions[0].links[j + previousLinksCount];
                }
              }
              this.currentPoll.choices.push(choices);
              if (i === 0 && this.currentPoll.electionIndexes.indexOf(this.currentPoll.liveQuestions.length - 1) === -1) {
                this.currentPoll.electionIndexes.push(this.currentPoll.liveQuestions.length - 1);
              }
            }
          }
        });
        break;
      }
    }
  }

  updateSecondaryDataAfterRestore() {
    localStorage.setItem('editedPoll', JSON.stringify(this.currentPoll));
    this.shouldResume = true;
    this.votersCount = this.currentPoll.voters.length;
    this.smsVoterCount = this.currentPoll.voters.filter(x => x.phone == null || x.phone === undefined).length;
    this.displayedVoters = this.currentPoll.voters;
    if (this.currentPoll.temporality === 'live' && this.currentPoll.votingType === 'election') { this.isLiveResolution = false; }
    this.validateFields();
  }

  initDraftId() {
    this.apiService.getDraftId().subscribe((res: DraftIdResponse) => {
      this.currentPoll.id = res.id;
    });
  }

  setStoreObservers() {
    this.store.select((state: any) => state.creation.poll).subscribe(res => {
      this.currentPoll.draftSaved = res.draftSaved;
      this.currentPoll.isAssessors = res.isAssessors;
      this.calculateLiveAvailability();
      this.currentPoll.votingType = res.votingType;
      this.currentPoll.temporality = res.temporality;
      const dateChanged = (res.startDate !== this.currentPoll.startDate) || (res.startTime !== this.currentPoll.startTime);
      this.currentPoll.startDate = res.startDate;
      this.currentPoll.startTime = res.startTime;
      this.currentPoll.endDate = res.endDate;
      this.currentPoll.endTime = res.endTime;
      if (this.currentPoll.temporality === 'live') {
        this.currentPoll.isAssessors = false;
      }
      this.currentPoll.title = res.title;
      this.currentPoll.voters = [];
      if (this.currentPoll.startDate != undefined && this.currentPoll.startTime.length != undefined &&
        this.currentPoll.endDate.length != undefined && this.currentPoll.endTime.length != undefined) {
        if (dateChanged) { this.calculateLicences(this.currentPoll.startDate); }
      }
      if (res.voters.length > 0) {
        this.currentPoll.voters = res.voters;
      }
      res.resolutions.forEach((elem, index) => {
        const reso = new Resolution(res.temporality === 'live');
        reso.name = elem.name;
        //  reso.choices = elem.choices; <- fixes resolution bug
        reso.link = elem.link;
        reso.image = elem.image;
        this.currentPoll.resolutions[index] = reso;
      });

      res.assessorsNames.forEach((elem, index) => {
        this.currentPoll.assessorsNames[index] = elem;
      });
      res.assessorsMails.forEach((elem, index) => {
        this.currentPoll.assessorsMails[index] = elem;
      });
      if (this.currentPoll.isAssessors) {
        this.assessorError = this.checkFullyFilledAssessors();
      }
      res.liveQuestions.forEach((elem, index) => {
        this.currentPoll.liveQuestions[index] = {...elem};
      });
      this.currentPoll.choices = [];
      res.choices.forEach((element, index) => {
        this.currentPoll.choices[index] = [...element];
      }, this);
      this.currentPoll.sharedLinks = res.sharedLinks;
      this.currentPoll.isSMS = res.isSMS;
      this.currentPoll.is2FA = res.is2FA;
      this.validateSender = blacklistedSenders.map(x => x.toLowerCase()).indexOf(res.sender.toLowerCase().trim())  === -1;
      this.triggerSMSPopup();
      this.validateFields();
      this.isLiveResolution = !(this.currentPoll.temporality !== 'live' || this.currentPoll.votingType !== 'resolution');
      if (this.currentPoll.isAssessors) { this.validateAssessors(); }
    });
  }

  trackByFn(index) {
    return index;
  }

  ngOnInit() {
    this.currentPoll = new Poll();
    this.store.dispatch(resetAction());
    this.currentPoll.choices[0] = [new PollChoice(), new PollChoice()];
    this.isAssoc = true;
    this.stripe = Stripe(this.stripeConstants.key);
    this.currentPoll.liveQuestions.push(new LiveQuestion(0, [], '', 0));

    // If the URL contain an ID, it means we are editing the poll
    // Pick data from the API and fill every field
    if (this.route.snapshot.paramMap.get('id') !== null && this.route.snapshot.paramMap.get('id') !== 'votersascandidates') {
      this.setPollData(this.route.snapshot.paramMap.get('id'));
    }
    this.licenseService.license.subscribe((res: LicenseData) => {
      this.hasHotline = res.hasHotline;
      this.maxVoters = res.votes;
      this.maxSMSVoters = res.sms;
      this.eventCounter = res.events;
      this.licenceEnd = res.endDate;
      this.clientType = res.client;
      if (this.maxVoters > 5 && this.maxVoters < this.currentPoll.voters.length) {
        this.paymentPopup = true;
      } else {
        if (this.maxVoters !== 5) {
          this.paymentPopup = false;
        }
      }
      this.licenseTime = this.common.licenseResponseGetTime(res);
      if (res.client !== null) { this.isAssoc = this.clientType !== 'corp'; }
      this.accountName = this.authenticationService.getUserData();
    });
    if (this.currentPoll.id == undefined) { this.initDraftId(); }
    this.licenseService.refreshLicense();
    if (this.router.url.split('/')[2] === 'canceled') {

      this.currentPoll = JSON.parse(localStorage.getItem('editedPoll'));

      this.resumeAfterPayment();
    }
    this.route.queryParams.subscribe((params) => {
      if (params.session_id != null) {
        this.apiService
          .getSubscriptionData(params.session_id)
          .subscribe((res: Array<SubsciptionData>) => {
            this.shouldDisplayNewLicences = true;
            this.isPayment = false;
            this.newLicences = res;
          });
        // previously params.session_id instead of editedPoll
        this.currentPoll = JSON.parse(localStorage.getItem('editedPoll'));
        const live = this.currentPoll.temporality === 'live';
        this.currentPoll.resolutions.push(new Resolution(live));
        this.resumeAfterPayment();
      }
    });
    if (this.licenseTime === undefined) {
      this.paymentPopup = false;
    }
    this.changeDetectorRef.detectChanges();
  }

  closeListMessageError() {
    this.shouldDisplayListWarning = false;
  }

  getVoterListTitle(): string {
    switch (this.common.getLangCode()) {
      case 'fr': return 'Liste des votants';
      case 'es': return 'Lista de votantes';
      default: return 'Voters list';
    }
  }

  initDate() {
    const startDateTime = new Date();
    startDateTime.setHours(startDateTime.getHours() + 1);
    const formatTime = startDateTime.toTimeString().substring(0, 5);
    this.currentPoll.startTime = formatTime;
    this.store.dispatch(setStartTimeAction({startTime: formatTime}));
    this.currentPoll.endTime = formatTime;
    this.store.dispatch(setEndTimeAction({endTime: formatTime}));
    const startDate = startDateTime.toISOString().split('T')[0];
    this.currentPoll.startDate = startDate;
    this.store.dispatch(setStartDateAction({startDate}));
    const endDateRaw = new Date(startDateTime.setDate(startDateTime.getDate() + 1));
    const endDate = endDateRaw.toISOString().split('T')[0];
    this.currentPoll.endDate = endDate;
    this.store.dispatch(setEndDateAction({endDate}));
  }

  ngAfterContentInit() {
    this.setStoreObservers();
    if (this.route.snapshot.paramMap.get('id') === null) {
      this.initDate();
    }
    this.changeDetectorRef.detectChanges();
   }

   fileUploaded(event: Array<number>) {
     this.currentPoll.electionIndexes = event;
   }

  refitHeader() {
    const doc = document.getElementById('recap-panel');
    const header = document.getElementsByClassName('account-header');
    doc.style.top = header[0].clientHeight + 'px';
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    const doc = document.getElementById('recap-panel');
    const header = document.getElementsByClassName('account-header');
    doc.style.top = header[0].clientHeight + 'px';
  }

  setListImage($event) {
    this.uploadImage($event[1], $event[0], ImageTypes.List, $event[2]);
  }

  setImageName($event) {
    this.uploadImage($event[1], $event[0], ImageTypes.Poll);
  }

  saveResolutionImage($event) {
    const ext = $event[0].name.substring($event[0].name.lastIndexOf('.'), $event[0].name.length);
    this.uploadImage($event[1], ext, ImageTypes.Resolution, $event[2]);
  }

  setCandidatesImage($event) {
    this.uploadImage($event[1], $event[0], ImageTypes.ListCandidate, $event[3], $event[2]);
  }

  displayOutdatedFileError($event: boolean) {
    if ($event) {
      this.isOutdatedImportFileError = true;
    }
  }

  setPropositionsImage($event) {
    this.uploadImage($event[1], $event[0], ImageTypes.ListCandidate, $event[3], $event[2]);
  }
}
