import {Component, OnInit} from '@angular/core';
import {ApiService} from '../shared/api.service';
import {ActivatedRoute} from '@angular/router';
import {DraftForm, Poll} from '../models/Poll';
import {Common} from '../shared/common';

@Component({
  selector: 'app-poll-preview',
  templateUrl: './poll-preview.component.html',
  styleUrls: ['./poll-preview.component.less']
})
export class PollPreviewComponent implements OnInit {
  poll: Poll;

  constructor(private apiService: ApiService, private route: ActivatedRoute, public common: Common) { }

  getDateString(): string {
    const endDate = `${this.poll.endDate}T${this.poll.endTime}`;
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    return  new Date(endDate).toLocaleDateString(navigator.language, options);
  }

  ngOnInit(): void {
    const pollId = this.route.snapshot.params.id;
    this.apiService.getDraft(pollId).subscribe((res: DraftForm) => {
      this.poll = JSON.parse(res.data);
    });
  }

}
