import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  Input,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { setAssessorsMailsAction, setAssessorsNamesAction } from 'src/app/states/creationActions';

@Component({
  selector: 'vc-assessors-fields',
  templateUrl: 'assessors-fields.component.html',
  styleUrls: ['./assessors-fields.component.less'],
})

export class AssessorsFields implements OnInit {
  isUncrypted = false;
  @Input() assessorsMails = ['', '', ''];
  @Input() assessorsCpy = ['', '', ''];
  @Input() assessorsNames = ['', '', ''];
  @Input() displayError = false;
  @Output() sendAssessorNames = new EventEmitter<string[]>();
  @Output() sendAssessorMails = new EventEmitter<string[]>();
  @Output() saveAssessorData = new EventEmitter<boolean>();

  constructor(private store: Store) {}

  addAssessor() {
    this.assessorsMails.push('');
    this.assessorsNames.push('');
    this.assessorsCpy = this.assessorsMails;
  }

  deleteAssessor($event) {
    this.assessorsMails.splice($event, 1);
    this.assessorsNames.splice($event, 1);
    this.assessorsCpy = this.assessorsMails;
  }

  addAssessorName($event, assessorIndex) {
    this.assessorsNames[assessorIndex] = $event;
    const copyNames = structuredClone(this.assessorsNames);
    this.store.dispatch(setAssessorsNamesAction({ assessorsNames: copyNames }));
  }

  addAssessorMail($event, assessorIndex) {
    this.assessorsMails[assessorIndex] = $event;
    const copyMails = structuredClone(this.assessorsMails);
    this.store.dispatch(setAssessorsMailsAction({ assessorsMails: copyMails }));
  }

  saveAssessors() {
    this.saveAssessorData.emit(true);
  }

  trackByFn(index) {
    return index;
  }

  ngOnInit() {
    this.store.select((state: any) => state.creation.poll).subscribe(res => {
      res.assessorsNames.forEach((elem, index) => {
        this.assessorsNames[index]  = elem;
      });
      res.assessorsMails.forEach((elem, index) => {
        this.assessorsMails[index]  = elem;
      });
    });
  }
}
