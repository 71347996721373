import {Component, Input, OnInit} from '@angular/core';
import {PollChoice} from '../../../models/PollChoice';

@Component({
  selector: 'app-preview-resolution-item',
  templateUrl: './preview-resolution-item.component.html',
  styleUrls: ['./preview-resolution-item.component.less']
})
export class PreviewResolutionItemComponent implements OnInit {
  @Input() choice: PollChoice;
  @Input() index: number;
  shouldShow = true;

  constructor() { }

  ngOnInit(): void {
    this.shouldShow = this.index !== 3;
  }

}
