import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {Resolution} from '../../../models/PollChoice';

@Component({
  selector: 'app-preview-resolution',
  templateUrl: './preview-resolution.component.html',
  styleUrls: ['./preview-resolution.component.less']
})
export class PreviewResolutionComponent implements OnInit, AfterViewInit {
  @Input() index: number;
  @Input() resolution: Resolution;

  constructor() { }

  ngAfterViewInit() {
    const imageWrapper = document.getElementById(`image-wrapper-${this.index}`) as HTMLImageElement
    imageWrapper.style.display = 'none';
    const ids = document.getElementsByClassName('resolution-index') as HTMLCollectionOf<HTMLElement>
    if (ids.length > 0) {
      const result = [...[ids]];
      if (result[0][result[0].length - 1].id.length === 2) {
        result[0][result[0].length - 1].style.fontSize = '14px';
      }
      if (result[0][result[0].length - 1].id.length === 3) {
        result[0][result[0].length - 1].style.fontSize = '9px';
      }
    }
    this.loadImage();
  }

  loadImage() {
    if (this.resolution.image != null) {
      const imageWrapper = document.getElementById(`image-wrapper-${this.index}`) as HTMLImageElement
      if (imageWrapper != null) {
        imageWrapper.style.display = 'block';
      }
      const indexValue = document.getElementById(`wrapper-${this.index}`) as HTMLImageElement
      if (indexValue != null) {
        indexValue.style.display = 'none';
      }
      const logo = document.getElementById(`resolution-${this.index}`) as HTMLImageElement
      if (logo != null) {
        logo.src = this.resolution.image;
      }
    }
  }

  ngOnInit(): void {
  }

}
