import {
  Component,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnInit,
  ChangeDetectorRef, OnChanges, AfterContentInit,
} from '@angular/core';
import {
  PollChoice,
  EditedPollChoice,
  Resolution,
  EditedResolution,
  WsCommand,
  LiveQuestion,
} from '../../models/PollChoice';
import { Poll } from '../../models/Poll';
import { Common } from 'src/app/shared/common';
import { Store } from '@ngrx/store';
import { setChoicesAction, setLiveQuestionAction, setResolutionsAction } from 'src/app/states/creationActions';

@Component({
  selector: 'vc-voted-list',
  templateUrl: 'voted-list.component.html',
  styleUrls: ['./voted-list.component.less'],
})
export class VotedListComponent implements OnInit, OnChanges, AfterContentInit {
  isLiveResolution: boolean;
  @Input() subject = '';
  @Input() isLaunchEnabled: boolean;
  @Input() shouldResume: boolean;
  @Input() numberOfVote = 1;
  @Input() minNumberOfVote = 1;
  @Input() index: number;
  @Input() isMainList = false;
  @Input() choices = [new PollChoice(), new PollChoice()];
  @Input() currentPoll: Poll;
  @Input() editMode = false;
  @Input() numberOfList = 0;
  @Input() deleteEnabled = false;
  @Output() sendCommand = new EventEmitter<WsCommand>();
  @Output() sendListImage = new EventEmitter<Array<any>>();
  @Output() sendResolutionImageEvent = new EventEmitter<Array<any>>();
  @Output() sendCandidatesImageEvent = new EventEmitter<Array<any>>();
  @Output() sendDeselect = new EventEmitter<boolean>();
  @Output() deleteQuestions = new EventEmitter<number>();
  language = '';
  ordinal = '';
  resolutions: Array<Resolution> = [];
  previousLiveModeResolution: boolean;
  isValid = false;
  eventTitleCharacters = 0;
  eventSubjectCharacters = 0;
  hideHeader = false;
  eventElectionTitleCharacters = 0;
  blueLinkIcon = false;
  displayImageError = false;
  eventTitle: string;

  constructor(
    public common: Common,
    private store: Store,
    private changeDetectorRef: ChangeDetectorRef
    ) {}

  sendVotersNumber($event: number) {
    this.currentPoll.liveQuestions[this.index].numberOfVote = $event;
    const copyQuestions = structuredClone(this.currentPoll.liveQuestions);
    this.store.dispatch(setLiveQuestionAction({ liveQuestions: copyQuestions }));
  }

  sendMinVotersNumber($event: number) {
    this.currentPoll.liveQuestions[this.index].minNumberVote = $event;
    const copyQuestions = structuredClone(this.currentPoll.liveQuestions);
    this.store.dispatch(setLiveQuestionAction({ liveQuestions: copyQuestions }));
  }

  closeInput() {
    const floatingInput = document.getElementById(`input-${this.index}`) as HTMLInputElement;
    floatingInput.style.display = 'none';
    if (floatingInput.value != undefined && floatingInput.value.length > 0) {
      const link = floatingInput.value.trim();

      if (link.length > 0) {
        this.blueLinkIcon = true;
      }
    } else {
      this.blueLinkIcon = false;
    }
    this.currentPoll.liveQuestions[this.index].listLink = floatingInput.value;
    const copyQuestions = structuredClone(this.currentPoll.liveQuestions);

    this.store.dispatch(setLiveQuestionAction({ liveQuestions: copyQuestions }));
  }

  addChoice() {
    if (
      this.currentPoll.votingType === 'election' || this.currentPoll.votingType === 'list' ||
      (this.currentPoll.votingType === 'live' && !this.isLiveResolution)
    ) {
      const choice = new PollChoice();
      if (this.currentPoll.votingType === 'list') { choice.gender = 'M'; }
      this.choices.push(choice);
    }
    if (
      this.currentPoll.votingType === 'resolution' ||
      (this.currentPoll.votingType === 'live' && this.isLiveResolution)
    ) {
      const resolution = new Resolution(this.currentPoll.votingType === 'live' || (this.currentPoll.votingType === 'resolution' && this.isLiveResolution));
      this.resolutions.push(resolution);
    }
    window.scrollBy(0, 63);
  }

  onTitleInput(event: { target: { value: string } }) {
    this.currentPoll.liveQuestions[this.index].title = event.target.value;
    const copyQuestion = structuredClone(this.currentPoll.liveQuestions);
    this.store.dispatch(setLiveQuestionAction({ liveQuestions: copyQuestion }));
    this.eventSubjectCharacters = event.target.value.length;
  }

  getChoice(data: EditedPollChoice) {
    this.choices[data.index] = data.choice;
    this.currentPoll.choices[this.index] = this.choices;
    const copyChoices = structuredClone(this.currentPoll.choices);
    this.store.dispatch(setChoicesAction({ choices : copyChoices }));
    this.getCandidatesIsValid();
  }

  sendLinkCandidate($event) {
    if (this.currentPoll.votingType !== 'resolution') {
      this.choices = JSON.parse(JSON.stringify(this.choices));
      this.choices[$event[0]].link = $event[1];
      this.currentPoll.choices[this.index] = this.choices;
      const copyChoices = structuredClone(this.currentPoll.choices);
      this.store.dispatch(setChoicesAction({ choices : copyChoices }));
    }
    this.markDeselect();
  }

  markDeselect() { this.sendDeselect.emit(true); }

  deleteChoice(data: number) {
    this.choices.splice(data, 1);
    this.currentPoll.choices[this.index] = this.choices;
    const copyChoices = structuredClone(this.currentPoll.choices);
    this.store.dispatch(setChoicesAction({ choices : copyChoices }));
    this.markDeselect();
  }

  getResolution(data: EditedResolution) {
    this.resolutions[data.index] = data.resolution;
    const resolutionCopy = structuredClone(this.resolutions);
    this.store.dispatch(setResolutionsAction({ resolutions: resolutionCopy }));
  }

  deleteResolution(data: number) {
    this.resolutions.splice(data, 1);
    const resolutionCopy = structuredClone(this.resolutions);
    this.store.dispatch(setResolutionsAction({ resolutions: resolutionCopy }));
    this.sendDeselect.emit(true);
  }

  wsCommand(data: WsCommand) {
    this.sendCommand.emit(data);
  }

  launchPoll() {
    const command = new WsCommand(this.index, 'open');
    this.sendCommand.emit(command);
  }

  getInput() {
    const floatingInput = document.getElementById(`input-${this.index}`);
    floatingInput.style.display = 'block';
    floatingInput.focus();
  }

  resumeFromPayment() {
    const storedPoll: Poll = JSON.parse(
      localStorage.getItem('editedPoll')
    );
    if (storedPoll.liveQuestions.length === 0) {
        storedPoll.liveQuestions.push(new LiveQuestion(0, [], '', 0));
        storedPoll.liveQuestions[0].title = '';
      }

    if (storedPoll.temporality === 'planned') {
      if (this.choices.length === 0) {
        this.choices.push(new PollChoice());
      }
      if (storedPoll.votingType === 'resolution') {
        if (storedPoll.resolutions.length > 0) {
          this.resolutions = [];
        }
        for (const resolution of storedPoll.resolutions) {
          const newResolution = new Resolution(resolution.choices.length === 3);
          newResolution.name = resolution.name;
          newResolution.link = resolution.link;
          newResolution.image = resolution.image;
          this.resolutions.push(newResolution);
        }
      }
      else if (storedPoll.liveQuestions[this.index] != null) {
          this.setElectionTitleFromStorage(storedPoll);
        }
    } else if (storedPoll.votingType !== 'resolution') {
      setTimeout( () => {
        this.setElectionTitleFromStorage(storedPoll);
      });
    }
  }

  getCandidatesIsValid() {
    this.isValid = this.choices[0] != null &&
      this.choices[0].name != null &&
      this.choices[0].name != '' &&
      this.currentPoll.title !== undefined &&
      this.currentPoll.title.length !== undefined;
  }

  setElectionTitleFromStorage(storedPoll: Poll) {
    this.numberOfVote = storedPoll.liveQuestions[this.index].numberOfVote;
    const subjectField = document.getElementById(`election-subject-${this.index}`) as HTMLTextAreaElement;
    if (subjectField != undefined) {
      (document.getElementById(`election-subject-${this.index}`) as HTMLTextAreaElement).value =
        storedPoll.liveQuestions[this.index].title;
    }
  }

  fillChoices() {
    if (this.currentPoll.votingType === 'resolution') {
      if (this.currentPoll.resolutions.length > 0) {
        this.resolutions = this.currentPoll.resolutions;
        // this.currentPoll.liveQuestions[this.index].title = this.currentPoll.resolutions[0].name
        let i = 0;
        this.choices = [];
        while (i < this.currentPoll.resolutions[0].choices.length) {
          let tempCandidates = new PollChoice();
          tempCandidates = this.currentPoll.resolutions[0].choices[i];
          this.choices.push(tempCandidates);
          i++;
        }
      }
    } else {
      if (this.currentPoll.choices != undefined) {
        if ((this.currentPoll.choices[this.index] == null || this.currentPoll.choices[this.index].length === 0) || (this.currentPoll.choices.length < 2 && this.currentPoll.choices[this.index][0].name == undefined)) {
          this.choices = [new PollChoice(), new PollChoice()];
        } else {
          this.choices = this.currentPoll.choices[this.index];
        }
      }
      this.getCandidatesIsValid();
      if (this.currentPoll.votingType === 'list' && this.currentPoll.liveQuestions[this.index] != null && this.currentPoll.liveQuestions[this.index].electionTitle != null) {
        this.eventTitle = this.currentPoll.liveQuestions[this.index].electionTitle;
      }
      setTimeout( () => {
        if (this.currentPoll.votingType === 'list' && this.currentPoll.liveQuestions[this.index] != null && this.currentPoll.liveQuestions[this.index].listImage != null && this.currentPoll.liveQuestions[this.index].listImage.length >= 1) {
          const displayImage = document.getElementById(`uploaded-${this.index}`) as HTMLImageElement;
          const wrapper = document.getElementById(`uploaded-wrapper-${this.index}`);
          wrapper.style.display = 'flex';
          displayImage.src = this.currentPoll.liveQuestions[this.index].listImage[0];
        }
        if (this.currentPoll.votingType === 'list' && this.currentPoll.liveQuestions[this.index] != null && this.currentPoll.liveQuestions[this.index].listLink !== null && this.currentPoll.liveQuestions[this.index].listLink !== undefined) {
          this.blueLinkIcon = true;
          const floatingInput = document.getElementById(`input-${this.index}`) as HTMLInputElement;
          floatingInput.value = this.currentPoll.liveQuestions[this.index].listLink;
        }
      }, 500);
    }
    this.editMode = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    // if (this.editMode && this.currentPoll !== undefined)
    this.fillChoices();
    if ((this.currentPoll.votingType === 'list' || this.currentPoll.votingType === 'election') && this.currentPoll.liveQuestions[this.index].title.length > 0) {
      this.eventSubjectCharacters = this.currentPoll.liveQuestions[this.index].title.length;
    }
    if (this.currentPoll.votingType === 'list' && this.currentPoll.liveQuestions[this.index].electionTitle.length > 0) {
      this.eventElectionTitleCharacters = this.currentPoll.liveQuestions[this.index].electionTitle.length;
    }
    if (this.currentPoll.votingType === 'resolution') {
      if (this.resolutions.length < 1) {
        this.resolutions = [new Resolution(this.currentPoll.temporality === 'live')];
      }
    }
    if (!this.previousLiveModeResolution && this.isLiveResolution && this.choices.length < 1) {
      this.choices = [];
    }
    if (this.previousLiveModeResolution && !this.isLiveResolution) {
      this.resolutions = [];
    }
  }

  ngAfterContentInit() {
    if (this.currentPoll.votingType === 'election') {
      if (this.currentPoll.liveQuestions[this.index].listLink !== null && this.currentPoll.liveQuestions[this.index].listLink !== undefined) {
        this.blueLinkIcon = true;
        setTimeout(() => {
          const floatingInput = document.getElementById(`input-${this.index}`) as HTMLInputElement;
          floatingInput.value = this.currentPoll.liveQuestions[this.index].listLink;
        }, 500);
      }
    }

    if (this.index > 0) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }

  onElectionTitleInput(event: { target: { value: string } }) {
    this.currentPoll.liveQuestions[this.index].electionTitle = event.target.value;
    const copyQuestion = structuredClone(this.currentPoll.liveQuestions);
    this.store.dispatch(setLiveQuestionAction({ liveQuestions: copyQuestion }));
    this.eventElectionTitleCharacters = event.target.value.length;
  }

  defineOrdinal() {
    switch (this.numberOfList) {
      case(1) :
        switch (this.language) {
          case 'fr': { this.ordinal = 'ère'; break; }
          case 'es': { this.ordinal = 'ª'; break; }
          default: { this.ordinal = 'st'; break; }
        }
        break;
      default :
        this.ordinal = this.language === 'fr' ? 'ème' : 'nd';
        if (this.language === 'es') {
          this.ordinal = 'ª';
        }
    }
  }

  trackByFn(index) {
    return index;
  }

  ngOnInit() {
    this.store.select((state: any) => state.creation.poll).subscribe(res => {
      this.currentPoll.votingType = res.votingType;
      this.resolutions = [new Resolution(this.currentPoll.votingType === 'live')];
      this.isLiveResolution = res.temporality === 'live' && res.votingType === 'resolution';
      if (res.resolutions.length > 0) { this.resolutions = [...res.resolutions]; }
      if (this.currentPoll.votingType === 'election' && res.liveQuestions[this.index] != null) { this.numberOfVote = res.liveQuestions[this.index].numberOfVote; }
    });
    const langKey = 'userLanguage';
    const userLang = navigator.language || navigator[langKey];
    this.language = userLang.substring(0, 2);

    if (this.currentPoll.votingType === 'list') {
      this.defineOrdinal();
    }
    if (this.currentPoll != undefined && this.currentPoll.liveQuestions.length > 1) {
      this.hideHeader = true;
    }
    if (this.isMainList) {
      this.hideHeader = false;
    }
    if (this.shouldResume === true) {
      this.resumeFromPayment();
    } else {
      this.previousLiveModeResolution = this.isLiveResolution;
    }
    this.changeDetectorRef.detectChanges();
  }

  revealInput() {
      const floatingInput = document.getElementById(`input-${this.index}`);
      floatingInput.style.display = 'block';
      floatingInput.focus();
  }

  onFileChanged(event) {
    const displayImage = document.getElementById(`uploaded-${this.index}`) as HTMLImageElement;
    const wrapper = document.getElementById(`uploaded-wrapper-${this.index}`) as HTMLImageElement;
    const reader = new FileReader();
    const extensionImage = event[0].name.substring(event[0].name.lastIndexOf('.'));
    const imageBlob = new Blob(event);
    reader.readAsDataURL(imageBlob);
    if (event[0].size > 2097152) {
      this.displayImageError = true;
    }
    const image = new Image();
    const objectUrl = URL.createObjectURL(event[0]);
    image.onload = function() {
      if (image.width !== image.height) {
        this.displayImageError = true;
      }
      if (this.displayImageError === false) {
          wrapper.style.display = 'flex';
          displayImage.src = URL.createObjectURL(imageBlob);
          this.sendListImage.emit([extensionImage, imageBlob, this.index]);
        }
      URL.revokeObjectURL(objectUrl);
    }.bind(this, event);
    image.src = objectUrl;
  }

  dismissPayPopup() {
    this.displayImageError = false;
  }

  deleteQuestion() {
    this.deleteQuestions.emit(this.index);
  }

  sendResolutionImage($event) {
    this.sendResolutionImageEvent.emit($event);
  }

  getCandidatesImages($event) {
    $event.push(this.index);
    this.sendCandidatesImageEvent.emit($event);
  }
}
