import {Component, Output, Input, EventEmitter, OnInit} from '@angular/core';
import { Voter } from '../../models/Voter';
import { Common } from 'src/app/shared/common';
import {ShortVoter} from '../../models/ShortVoter';

@Component({
  selector: 'vc-warning-popup',
  templateUrl: 'warning-popup.component.html',
  styleUrls: ['./warning-popup.component.less'],
})
export class WarningPopup implements OnInit {
  // Used as text for the popup
  @Input() title: string;
  @Input() firstText: string;
  @Input() secondText: string;
  @Input() thirdText: string;
  // Different button for different popup
  @Input() validateButton: boolean;
  @Input() confirmButton: boolean;
  @Input() deleteButton: boolean;
  @Input() cancelButton: boolean;
  @Input() callButton: boolean;
  @Input() cancelElectionButton: boolean;
  @Input() understoodButton: boolean;
  @Input() pleaseButton: boolean;
  @Input() understoodEndButton: boolean;
  @Input() noSMSButton: boolean;
  @Input() cancelEditionButton: boolean;
  @Input() continueEditionButton: boolean;
  @Input() downloadButton: boolean;

  // True if we want to display the phone input
  @Input() phoneInput: boolean;
  // False if you want to disable the separator
  @Input() separator: boolean;
  // Define which offer detail we display
  @Input() index: number;
  // Define if the user is an association or a company
  @Input() isAssoc: boolean;
  // List of voters when we display the excel mails
  @Input() voters: Array<Voter>;
  @Input() mails: Array<ShortVoter>;
  // Boolean to define the type of error on excel upload
  @Input() isSMSError: boolean;
  @Input() is2FA: boolean;
  @Input() isPonderationError: boolean;
  @Input() isImportError: boolean;
  //
  @Input() SMSOffers: boolean;
  @Input() modifiedLine: boolean;
  @Input() origin = '';
  @Input() isSMS: boolean;
  @Input() SMSOwned = 0;
  @Input() hotline = false;
  @Input() licenseTime: string;

  @Input() continueButton: boolean;
  @Input() changeVoters: boolean;

  @Output() payment = new EventEmitter<boolean>();
  @Output() SMSOptionEmitter = new EventEmitter<number>();
  @Output() contactPhone = new EventEmitter<string>();
  @Output() endDetailsPopup = new EventEmitter<boolean>();
  @Output() hotlineOptionEmitter = new EventEmitter<number>();
  @Output() sendDownloadOrder = new EventEmitter<boolean>();
  searchEnabled = false;
  messageString: string;
  paymentLine: boolean;
  isPhoneValid = false;
  status: number;
  filteredMails: Array<ShortVoter>;

  launch() {
    this.payment.emit(true);
  }

  clicAction($event) {
    if ($event.target.className === 'div-arriere-plan-action') {
      this.cancelDetails(false);
    }
  }

  onPhoneEdit($event) {
    this.isPhoneValid = this.common.isPhoneValid($event.target.value);
  }

  sendContactPhone() {
    const element = document.getElementById('phone-input') as HTMLInputElement;
    this.contactPhone.emit(element.value);
  }

  cancelDetails(value) {
    const popupBackground = document.getElementsByClassName("dapa");
    const offerBackground = document.getElementsByClassName("dga");
    if (offerBackground != undefined && popupBackground.length > 0) {
      popupBackground[0].className = "div-arriere-plan-action";
      offerBackground[0].className = "div-global-action";
    }
    const disableScroll = document.getElementsByTagName("body");
    disableScroll[0].style.overflow = "initial";
    this.endDetailsPopup.emit(value);
  }

  cancel() {
    this.payment.emit(false);
  }

  SMSOption(option: any) {
    this.SMSOptionEmitter.emit(option)
  }

  hotlineOption(option: any) {
    this.hotlineOptionEmitter.emit(option)
  }

  ngOnInit() {
    const offers = document.getElementsByClassName(
      'container-offer'
    );
    if (this.isImportError) {
    this.messageString = this.voters.map((a) => a.email).join(', ');
    }
    if (this.origin === 'addVoters' || this.origin === 'addSMS') {
    this.status = 1;
    }

    document.addEventListener('keydown', (event) => {
      if (event.keyCode === 27) {
        this.endDetailsPopup.emit(false);
      }
    });
    const second = document.getElementsByClassName(
      'container-offer'
    );
  }

  returnVoterString(voter: ShortVoter): string {
    if (voter.firstName !== null && voter.firstName !== undefined && voter.firstName !== 'New' && voter.lastName !== null && voter.lastName !== undefined && voter.lastName !== 'Voter') { return `${voter.firstName} ${voter.lastName} - ${voter.email}`; }
    else {
      if (voter.firstName !== null && voter.firstName !== undefined && voter.firstName !== 'New') { return `${voter.firstName} - ${voter.email}`; }
      else { return voter.email; }
    }
  }

  downloadVoterFile() {
    this.sendDownloadOrder.emit(true);
  }

  enableSearch() {
    this.searchEnabled = true;
    this.filteredMails = this.mails;
  }

  filterVoters() {
    const votersInput = document.getElementById('voter-filter') as HTMLInputElement;
    votersInput.style.minWidth = Math.max(votersInput.offsetWidth, 400)  + 'px';
    const filterValue = votersInput.value.toLowerCase();
    this.filteredMails = this.mails.filter(voter => this.returnVoterString(voter).toLowerCase().includes(filterValue));
  }

  constructor(private common: Common) {}
}
