<div class="line-holder">
  <h3 class="resolution-index">{{index+1}}</h3>
  <div id="name">
    <p readonly [ngClass]="isValid == true ? 'valid-input' : 'invalid-input'">{{resolution.name}}</p>
  </div>
  <div *ngIf="status.status == 0" class="delete-container">
    <div class="icon-container">
      <img id="delete-button" (click)="delete()" src="assets/deletebutton.svg">
    </div>
  </div>
</div>
<div class="button-line-holder">
  <vc-status-bar [status]="status.status"></vc-status-bar>
  <div *ngIf="status && status.status == 1" class="results-button-active" (click)="results()">
    <span class="results-active-text" style="margin-left: 8px; margin-right: 8px;">{{'LIVE.PARTICIPATION' | translate}}</span>
  </div>
  <button  *ngIf="(!status || status.status<1) && !hasActiveElection" type="button" class="start-button-valid" (click)="startResolution()">{{'LIVE.LAUNCH' | translate}}</button>
  <button *ngIf="status && status.status == 1" type="button" class="start-button-valid" (click)="endResolution()">{{'LIVE.CLOSE' | translate}}</button>
  <div *ngIf="status && status.status == 2" class="results-button-active" (click)="results()">
    <img src="assets/results-blue.svg"class="results-image">
    <span class="results-active-text">{{'LIVE.RESULTS' | translate}}</span>
  </div>
</div>
<input type="text" id="argument" placeholder="Argumentaire">
