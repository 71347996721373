import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import {
  LiveResolution,
  EditedAnswer,
} from '../../../models/PollChoice';
import {
  PollQuestion,
  AddedPollQuestion,
} from '../../../models/PollCreationData';
import { Store } from '@ngrx/store';

@Component({
  selector: 'vc-added-candidate',
  templateUrl: 'added-candidate.component.html',
  styleUrls: ['./added-candidate.component.less'],
})
export class AddedCandidateComponent implements OnInit {
  @Input() index: number;
  @Input() status: LiveResolution;
  @Output() sendEvent = new EventEmitter<AddedPollQuestion>();
  @Output() sendAnswerImage = new EventEmitter<Array<any>>();
  @Output() linkEvent = new EventEmitter<[number, string]>();
  blueLinkIcon: boolean = false
  plurinominalError: boolean = false;
  editedObject: PollQuestion;
  nbVotes = 1;
  answers: Array<string>;
  isValid = false;
  eventTitleCharacters = 0

  constructor(private store: Store) {}

  getInput() {
    const floatingInput = document.getElementById(`input-${this.index}`);
    floatingInput.style.display = 'block';
    floatingInput.focus();
  }

  closeInput() {
    let floatingInput = document.getElementById(`input-${this.index}`) as HTMLInputElement
    floatingInput.style.display = 'none';
    if (floatingInput.value != undefined && floatingInput.value.length > 0) {
      const link = floatingInput.value.trim()

      if (link.length > 0) {
        this.blueLinkIcon = true;
      }
    } else {
      this.blueLinkIcon = false;
    }
    this.editedObject.links[0] = floatingInput.value;
  }

  onNameInput(data: EditedAnswer) {
    this.editedObject.answers[data.index] = data.answer;
    this.calculateValidity();
  }

  onTitleInput(event: { target: { value: string } }) {
    const inputValue = event.target.value;
    this.editedObject.question = inputValue;
    this.eventTitleCharacters = event.target.value.length;
    // this.store.dispatch()
    this.calculateValidity();
  }

  cancel() {
    this.plurinominalError = false;
  }

  saveImageCandidate($event) {
    this.sendAnswerImage.emit($event);
  }

  sendLink($event) {
    this.editedObject.links[$event[0] + 1] = $event[1];
    // this.linkEvent.emit($event)
  }

  confirmEvent() {
    this.editedObject.answers = this.editedObject.answers.filter(value => (value != undefined && value.length > 1))
    if (this.nbVotes > this.editedObject.answers.length) { this.plurinominalError = true; }
    else {
      this.sendEvent.emit(
        new AddedPollQuestion(this.editedObject, this.nbVotes)
      );
    }
  }

  deleteChoice(data: number) {
    this.answers.splice(data, 1);
    this.editedObject.answers.splice(data, 1);
  }

  sendVotersNumber($event: number) {
    this.nbVotes = $event;
  }

  sendMinVotersNumber($event: number) {
    this.editedObject.minNumberOfVote = $event;
  }

  addCandidate() {
    this.editedObject.answers.push('');
    this.answers.push('');
  }

  calculateValidity() {
    this.isValid = this.editedObject.answers[0] != null &&
      this.editedObject.answers[0] != '' &&
      this.editedObject.question != null &&
      this.editedObject.question != '';
  }

  ngOnInit() {
    this.editedObject = new PollQuestion('', ['', ''], 1);
    this.answers = ['', ''];
  }
}
