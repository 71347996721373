import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import {Common} from '../../shared/common';
@Component({
  selector: 'vc-poll-finished-summary',
  templateUrl: 'poll-finished-summary.component.html',
  styleUrls: ['./poll-finished-summary.component.less', '../../shared/common.less']
})

export class PollFinishedSummaryComponent implements OnInit {
  @Input() description: string;
  @Input() startDate: string;
  @Input() endDate: string;
  @Input() votersCount: number;
  @Input() votersVoted: number;
  @Input() participation: string;
  @Input() resultsShared: boolean;
  @Input() isShareEnabled: boolean;
  @Input() endDateFormat: Date;
  @Input() calculating: boolean;
  @Output() downloadVoters = new EventEmitter<boolean>();
  @Output() shareResults = new EventEmitter<boolean>();
  @Output() return = new EventEmitter<boolean>();
  @Input() type = 'live';
  blockchainLink: string;
  isActive = true;

  constructor(
    private router: ActivatedRoute,
    public common: Common,
  ) {
  }

  rejoin() {
    this.return.emit(true);
  }

  isActivePoll() {
    if (this.endDateFormat != undefined) {
      const todayDate = new Date();
      if (todayDate.getTime() > this.endDateFormat.getTime()) {
        this.isActive = false;
      }
    }
  }

  download() {
    this.downloadVoters.emit(true);
  }

  shareResult() {
    this.shareResults.emit(true);
  }

  ngOnChanges() {
    this.isActivePoll();
  }

  ngOnInit() {
    let pollId = '';
    this.router.queryParams.subscribe((params) => {
      pollId = params.poll;
    });
    this.blockchainLink = environment.settings.validation + pollId;
  }
}
