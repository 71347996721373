<div class="poll-item">
  <label class="resolution-label">
    <div class="number-holder">
      <div class="option-image-wrapper" id="image-wrapper-{{index}}">
        <img *ngIf="!useElectionTitle" id="image-{{index}}" class="option-image" src="#" alt="{{getElectionTitle()}}">
      </div>
      <div *ngIf="useElectionTitle" class="circle" id="wrapper-{{index}}">
        <span class="resolution-index" id="{{index + 1}}">{{index+1}}</span>
      </div>
      <div *ngIf="!useElectionTitle" class="circle" id="wrapper-exp-{{index}}">
        <span class="resolution-index" id="{{index + 1}}">{{index+1}}</span>
      </div>
    </div>
    <div class="poll-item-description-holder">
      <span *ngIf="!shouldShowLink()" class="poll-item-question">{{getElectionTitle()}}</span>
      <a *ngIf="shouldShowLink()" class="poll-item-question" href="{{common.replaceURLHref(event.listLink)}}" target="_blank">{{getElectionTitle()}}</a>
    </div>
    <div>
      <img src="assets/fleche_resolution_orange.svg" class="helper-image">
    </div>
  </label>
</div>
