import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../shared/authentication.service';
import { AuthService } from '@auth0/auth0-angular';
import { ApiService } from '../shared/api.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { Common } from '../shared/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./new-login/css/normalize.css', './new-login/css/webflow.css', './home.component.less']
})
export class HomeComponent implements OnInit {
  selector1 = 1;
  isPopupMessage = false;
  userMail: string;
  isMobile: boolean;
  hasBeenMobileDisabled = false;
  popupStatus = 0;
  resendPassword = false;
  mailValidForPassword = false;
  passwordMailSent = false;
  test = false;
  backURL = 'https://www.v8te.com/fr';

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    public auth: AuthService,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private common: Common) { }

  dismissPopup() {
    this.isPopupMessage = false;
  }

  setToken(token: string) {
    this.authenticationService.setUserValue(token);
    this.router.navigate(['/polls']);
  }

  resendUserMail(mail) {
    this.test = false;
    this.apiService.resendMail(mail).subscribe((res) => {
      console.log(res);
    });
  }

  closePopup($event) {
    this.test = false;
    const email = (document.getElementById('email') as HTMLInputElement).value;
    this.apiService.resendMail(email).subscribe((res) => {
      console.log(res);
    });
  }

  redirectToSubscription() {
    this.router.navigateByUrl('/inscription');
  }

  loginWithGoogle() {
    this.isMobile = this.checkIfMobile() && !this.hasBeenMobileDisabled;
    if (!this.isMobile) {
      this.authenticationService.loginWithSocial('google-oauth2');
    }
  }

  emailInput($event) {
    const submitButton = (document.getElementById('resendPassword') as HTMLInputElement);
    this.mailValidForPassword = this.common.validateEmail($event.target.value);
    if (submitButton !== null) {
      // tslint:disable-next-line:triple-equals
      this.mailValidForPassword == false ? submitButton.classList.add('invalid-button') : submitButton.classList.remove('invalid-button');
    }
  }

  forgotPasswordTrigger() {
    this.resendPassword = true;
  }

  backToConnect() {
    this.passwordMailSent = false;
    this.resendPassword = false;
  }

  async forgotPasswordMail() {
    const userField = (document.getElementById('email') as HTMLInputElement);
    if (this.common.validateEmail(userField.value)) {
      this.authenticationService.webAuth.changePassword({
        connection: 'Username-Password-Authentication',
        email: userField.value
      }, (err, res) => {
        if (err) { console.log(err); }
        else { this.passwordMailSent = true; }
      });
    }
      else {
        this.popupStatus = 5;
    }
  }

  loginWithLinkedin() {
    this.isMobile = this.checkIfMobile() && !this.hasBeenMobileDisabled;
    if (!this.isMobile) {
      this.authenticationService.loginWithSocial('linkedin');
    }
  }

  isPasswordValid(password: string) {
    return password.length > 3;
  }

  resetTooltips() {
    this.popupStatus = 0;
  }

  loginWithPassword() {
    this.isMobile = this.checkIfMobile() && !this.hasBeenMobileDisabled;
    if (!this.isMobile) {
      const userField = (document.getElementById('email') as HTMLInputElement);
      const passwordField = (document.getElementById('password') as HTMLInputElement);
      const password = passwordField.value;
      this.userMail = userField.value;
      if (this.userMail.length < 2) {
        this.popupStatus = 1;
        userField.focus();
      } else {
        if(!this.common.validateEmail(this.userMail)) {
          this.popupStatus = 2;
          userField.focus();
        } else {
          if(!this.isPasswordValid(password)) {
            this.popupStatus = 3;
            passwordField.focus();
          }
        }
      }

      if(this.common.validateEmail(this.userMail) && this.isPasswordValid(password)) {
        this.authenticationService.webAuth.client.login(
          {
            realm: 'Username-Password-Authentication', // connection name or HRD domain
            username: this.userMail,
            password: password,
            audience: environment.auth.audience,
            scope: 'openid profile email'
          },
          (err, authResult) => {
            if (err) {
              if (err.statusCode == 401) {
                this.test = true;
              }
              else {
                this.popupStatus = 4;
              }

              userField.focus();
            }
            if (authResult) {
              if (authResult["accessToken"] != null) {
                const result: string = authResult.accessToken;
                this.setToken(result);
              }
            }
          }
        );
      }
    }
  }

  scrollTop() {
    const el = document.getElementById('page-top');
    el.scrollIntoView();
  }

    checkIfMobile() {
      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
      ) {
        return true;
      } else {
        return false;
      }
    }

    dismissMobilePopup() {
      this.hasBeenMobileDisabled = true;
      this.isMobile = false;
    }

  useHash() {
    this.authenticationService.webAuth.parseHash({ hash: window.location.hash }, (err, authResult) => {
      if (err) {
        return console.log(err);
      }
      this.setToken(authResult.accessToken);
    });
  }

  ngOnInit() {
    const currentUser = this.authenticationService.currentUserValue;
      if (currentUser) {
        this.router.navigate(['/polls']);
      }
    this.route.queryParams.subscribe((params) => {
      if (params.key != null) {
          localStorage.setItem('referral', params.key);
          this.apiService.getNameFromEncoded(params.key).subscribe((res) => {
            const emailInput = (document.getElementById('email') as HTMLInputElement);
            emailInput.value = res["email"];
          });
      }
    });
    if (window.location.hash != null && window.location.hash !== '') {
      this.useHash();
    }
    const userLang = navigator.language || navigator["userLanguage"];
    if (userLang.substring(0, 2) != 'fr') {
      this.backURL = 'https://www.v8te.com/';
    }
  }

}
