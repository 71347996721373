<div class="poll-item">
  <label>
    <div *ngIf="choice.image == undefined" class="number-holder">
      <div [ngClass]="answer == false ? 'circle' : 'circle-fill'">
        <span class="resolution-index">{{index+1}}</span>
      </div>
    </div>
    <img *ngIf="choice.image != undefined && choice.image.length > 2" src="{{choice.image}}" class="option-image">
    <div *ngIf="choice.link != undefined && choice.link.length > 2" class="poll-item-description-holder">
      <span [ngClass]="answer ? 'poll-item-question' : 'poll-item-question-unanswered'">
        <a href="{{common.replaceURLHref(choice.link)}}" target="_blank">{{choice.name === '' ? '?' : choice.name}}</a>
      </span>
    </div>
    <div *ngIf="choice.link == undefined" class="poll-item-description-holder">
      <span [ngClass]="answer ? 'poll-item-question' : 'poll-item-question-unanswered'">
        {{choice.name === '' ? '?' : choice.name}}
      </span>
    </div>
    <div [ngClass]="['checkbox' + ' checkbox-' + index]" >
      <input class="box" type="checkbox" id="{{'checkbox-' + index}}" [checked]="true" (click)="selection(index)">
    </div>
  </label>
</div>
