import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Resolution} from '../../../models/PollChoice';

@Component({
  selector: 'app-preview-resolutins-choices',
  templateUrl: './preview-resolutins-choices.component.html',
  styleUrls: ['./preview-resolutins-choices.component.less']
})
export class PreviewResolutinsChoicesComponent implements OnInit {
  @Input() resolution: Resolution;
  @Output() goBack = new EventEmitter();

  constructor() { }

  back() {
    this.goBack.emit();
  }

  ngOnInit(): void {
  }

}
