import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from './../../../environments/environment';
import { Common } from "../../shared/common";

@Component({
  selector: 'vc-poll-planned-list',
  templateUrl: 'poll-planned-list.component.html',
  styleUrls: ['./poll-planned-list.component.less']
})

export class PollPlannedList implements OnInit {
  @Input() votingType: string = "";
  @Input() answers;
  @Input() candidates;
  @Input() index;
  @Input() numberOfVote: any = 1;
  @Input() minNumberOfVote = 1;
  @Input() pollId: any;
  @Input() shouldEdit = false;
  @Input() titles: Array<string>;
  @Input() displayBlockchain = false;
  @Output() editionEventEmitter = new EventEmitter<any>();
  isElection: boolean;
  subjects: Array<string> = [];
  displayedAnswers: Array<Array<string>> = [];
  hideNumberOfVoter = false;
  isEditable = true;
  blockchainLink: string;

  constructor(private common: Common) {
  }


  ngOnChanges() {
    if (this.votingType !== undefined)
      this.isElection = this.votingType.localeCompare("resolution") == 0 ||
      this.votingType.localeCompare("live") == 0 ? false : true

    if (this.isElection) {
      if (this.votingType == "election") {
        this.answers.displayedQuestions.forEach(element => {
          this.subjects.push(element.question)
        });
      } else {
        this.answers.displayedQuestions.forEach((element, index) => {
          if (element.parent == this.displayedAnswers.length && this.answers.displayedQuestions.length - 1 != index) {
            this.displayedAnswers [element.parent] = []
          }
          if (this.answers.displayedQuestions.length - 1 != index)
          index == 0 ? this.subjects = element.answers : this.displayedAnswers[element.parent].push(element.question)
        })
        // this.displayedAnswers.pop()
      }
      // this.displayedAnswers = this.answers.answers
    } else if (this.votingType != undefined) {
      this.answers.forEach(element => {
        this.displayedAnswers.push(element.question)
      });
    }
  }

  ngOnInit() {
    if (this.votingType === 'list') {
        this.blockchainLink = environment.settings.validation + this.pollId + "/"+this.index;
        this.hideNumberOfVoter = true;
    }
  }

  translateAnswer(answer: string): string { return this.common.localizeAnswer(answer);}

}
