import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PollChoice} from '../../../../models/PollChoice';
import {Common} from '../../../../shared/common';

@Component({
  selector: 'app-candidates-item',
  templateUrl: './candidates-item.component.html',
  styleUrls: ['./candidates-item.component.less']
})
export class CandidatesItemComponent implements OnInit {
  @Input() option: PollChoice;
  @Input() index: number;
  @Output() sendSelection = new EventEmitter<number>();

  constructor(public common: Common) { }

  actionSelect(index: number) {
    this.sendSelection.emit(index);
  }

  ngOnInit(): void {
  }

}
