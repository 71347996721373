import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

export class AuthHttpInterceptorExtended  extends AuthHttpInterceptor {

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.endsWith('home/contact') || req.url.endsWith('home/contactform')) {
      return  next.handle(req);
    }
    else {
      return super.intercept(req, next);
    }
  }
}
