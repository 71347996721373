<div *ngIf="!showDetails">
  <div class="resolutions-holder">
    <div class="top-holder">
      <h3  *ngIf="poll.votingType == 'resolution'" class="resolutions-header">{{'POLL-CREATION.QUESTIONS.TITLE-RESOLUTIONS' | translate}}</h3>
      <h3  *ngIf="poll.votingType == 'election' || poll.votingType == 'list'" class="resolutions-header">{{'POLL-CREATION.FORM.LABEL-LIST' | translate}}</h3>
      <p class="resolutions-participation">
        <span class="participation-label">{{0}}/{{getEventLength()}}</span>
        <progress max="100" value=0> 70% </progress>
      </p>
    </div>
    <div *ngIf="poll.votingType == 'resolution'">
      <app-preview-resolution *ngFor="let resolution of poll.resolutions; let i = index" [resolution]="resolution" [index]="i" (click)="setActiveResolution(resolution)"></app-preview-resolution>
    </div>
    <div *ngIf="poll.votingType == 'election'">
      <app-preview-event-item *ngFor="let event of poll.liveQuestions; let i = index" [event]="event" [index]="i" [useElectionTitle]="false" (click)="setActiveElection(event)"></app-preview-event-item>
    </div>
    <div *ngIf="poll.votingType == 'list'">
      <app-preview-event-item *ngFor="let event of getElectionEventTitles(); let i = index" [event]="event" [index]="i" [useElectionTitle]="true" (click)="setActiveEvent(i)"></app-preview-event-item>
    </div>
  </div>
</div>
<div *ngIf="showDetails">
  <div *ngIf="poll.votingType == 'resolution'">
    <app-preview-resolutins-choices [resolution]="activeResolution" (goBack)="dismissDetails()"></app-preview-resolutins-choices>
  </div>
  <app-candidates-controller *ngIf="poll.votingType == 'election'" [liveQuestion]="activeEvent" [choices]="activeChoices" (sendBack)="dismissDetails()"></app-candidates-controller>
  <div *ngIf="poll.votingType == 'list'">
    <div *ngIf="!showListDetails" class="resolutions-holder">
      <div class="top-holder">
        <h3 class="resolutions-header"><img src="assets/arrow-left.svg" class="backCandidates" (click)="back()">Lists</h3>
      </div>
      <app-preview-event-item *ngFor="let event of activeListElections; let i = index" [event]="event" [index]="i" [useElectionTitle]="false" (click)="setListDetails(event)"></app-preview-event-item>
      <app-candidates-item [option]="blankVoteChoice" [index]="activeListElections.length"></app-candidates-item>
    </div>
    <app-list-candidates-controller *ngIf="showListDetails" [liveQuestion]="activeEvent" [pollChoices]="activeChoices" (sendBack)="cancelListDetails()"></app-list-candidates-controller>
  </div>
</div>
