import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LiveQuestion, PollChoice} from '../../../models/PollChoice';

@Component({
  selector: 'app-list-candidates-controller',
  templateUrl: './list-candidates-controller.component.html',
  styleUrls: ['./list-candidates-controller.component.less', './../questions-preview.component.less']
})
export class ListCandidatesControllerComponent implements OnInit {
  @Input() liveQuestion: LiveQuestion;
  @Input() pollChoices: Array<PollChoice>;
  @Output() sendBack = new EventEmitter();

  constructor() { }

  back() {
    this.sendBack.emit();
  }

  ngOnInit(): void {
  }

}
