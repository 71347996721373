import {
  Component,
  OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { setActivateAssessorsAction } from 'src/app/states/creationActions';

@Component({
  selector: 'vc-assessors-toggle',
  templateUrl: 'assessors-toggle.component.html',
  styleUrls: ['./assessors-toggle.component.less'],
})
export class AssessorsToggle implements OnInit {
  assessorsActive = false;

  constructor(private store: Store) {}

  setAssessors($event) {
    this.store.dispatch(setActivateAssessorsAction({ isAssessors : $event.target.checked }));
  }

    ngOnInit() {
      this.store.select((state: any) => state.creation.poll).subscribe(res => {
        this.assessorsActive = res.isAssessors;
      });
    }
}
