<div class="form-holder">
  <div id=header>
    <img id="header-icon" src="assets/resolution-icon.svg">
    <div>
      <h1 class="title">{{'POLL-CREATION.QUESTIONS.TITLE-LIVE-CANDIDATES' | translate}}</h1>
      <p class="description">{{'POLL-CREATION.QUESTIONS.CANDIDATES-DESCRIPTION' | translate}}</p>
    </div>
  </div>
  <div class="safe-area">
    <div class="separator"></div>
    <div class="subjectWrapper">
      <div style="width: 45px;"></div>
      <textarea class="subject" (keyup)="onTitleInput($event)" required pattern="\S+.*" maxlength="255"
      placeholder="{{'POLL-CREATION.FORM.SUBJECT-PLACEHOLDER' | translate}}" matInput cdkTextareaAutosize
      cdkAutosizeMinRows="1" cdkAutosizeMaxRows="8"></textarea>
      <div class="link-container">
        <div class="icon-container">
          <input class="floatingInput" placeholder="Tapez ou copiez votre url ici" (focusout)="closeInput()" id="input-{{index}}"/>
          <img *ngIf="!blueLinkIcon" class="icons"  (click)="getInput()" style="margin-bottom: 3px;" src="assets/grey-link.svg">
          <img *ngIf="blueLinkIcon" class="icons"  (click)="getInput()" style="margin-bottom: 3px;" src="assets/blue-link.svg">
        </div>
      </div>
    </div>
    <div [ngClass]="eventTitleCharacters == 255 ? 'character-counter-red' : 'character-counter'">{{eventTitleCharacters}}/255</div>
    <app-plurionominal-min-input [numberOfCandidates]="answers.length"
      (sendNumberOfVote)="sendMinVotersNumber($event)" [index]="index">
    </app-plurionominal-min-input>
    <vc-plurinominal-input [numberOfCandidates]="answers.length" (sendNumberOfVote)="sendVotersNumber($event)"
      [index]="0"></vc-plurinominal-input>
    <div *ngFor="let candidateEvent of answers; let i = index">
      <vc-added-candidate-input [index]="i" (sendPollChoice)="onNameInput($event)" (deleteEvent)="deleteChoice($event)"
      (sendAnswerImage)="saveImageCandidate($event)" (linkEvent)="sendLink($event)">
      </vc-added-candidate-input>
    </div>
  </div>
  <div class="button-line-holder">
    <button type="button" class="add-label" (click)="addCandidate()">
      +&nbsp;&nbsp;&nbsp;&nbsp;{{'POLL-CREATION.QUESTIONS.ADD' |translate}}
    </button>
    <button *ngIf="!isValid" type="button" class="start-button start-button-invalid">{{'LIVE.CONFIRM-ADD-ELECTION' |
      translate}}</button>
    <button *ngIf="isValid" type="button" class="start-button start-button-valid"
      (click)="confirmEvent()">{{'LIVE.CONFIRM-ADD-ELECTION' | translate}}</button>
  </div>
  <div class="separator"></div>
  <div class="separator"></div>
  <vc-warning-popup *ngIf="plurinominalError == true" title="{{'POLL-WARNING.CATCH' | translate}}"
  firstText="{{'POLL-WARNING.PLURINOMINAL' | translate}}"
  [understoodButton]="true" (endDetailsPopup)="cancel()"></vc-warning-popup>
</div>
