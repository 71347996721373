import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Resolution, EditedResolution, WsCommand, LiveResolution } from '../../../models/PollChoice';
import { Common } from '../../../shared/common';
import { ref } from 'firebase/storage';
import { storage } from 'src/app/firebase';
import { v4 } from 'uuid';
import { Store } from '@ngrx/store';
import { setLiveResolutionsAction } from 'src/app/states/livePollActions';

@Component({
  selector: 'vc-added-resolution',
  templateUrl: 'added-resolution.component.html',
  styleUrls: ['./added-resolution.component.less' , '../../../shared/common.less']
})

export class AddedResolutionComponent implements OnInit {
  @Input() index: number;
  @Input() status: LiveResolution
  @Input() resolution: Resolution
  @Output() sendCommand = new EventEmitter<WsCommand>()
  @Output() deleteEvent = new EventEmitter<number>()
  @Output() sendAnswerImage = new EventEmitter<Array<any>>();
  editedObject: EditedResolution
  isValid = false
  eventTitleCharacters: number = 0;
  displayImageError: boolean = false;
  blueLinkIcon: boolean;
  constructor(
    public common: Common,
    private store: Store
    ) {
  }

  onFileChanged(event) {
    let displayImage = document.getElementById(`uploaded-${this.index}`) as HTMLImageElement
    let wrapper = document.getElementById(`uploaded-wrapper-${this.index}`) as HTMLImageElement
    let resolutionIndex = document.getElementById(`resolution-${this.index}`) as HTMLImageElement

    var reader = new FileReader()
    const extensionImage = event[0].name.substring(event[0].name.lastIndexOf('.'));

    const imageRef = ref(storage, `${v4()}${extensionImage}`)
    const imageBlob = new Blob(event)

    displayImage.src = URL.createObjectURL(imageBlob)
    reader.readAsDataURL(imageBlob)

    if (event[0].size > 2097152)
      this.displayImageError = true

    var image = new Image();
    var objectUrl = URL.createObjectURL(event[0]);
    image.onload = function () {
      if (image.width != image.height)
        this.displayImageError = true

      if (this.displayImageError == false) {
        wrapper.style.display = "flex"
        resolutionIndex.style.display = "none"
        this.sendAnswerImage.emit([imageRef, imageBlob, this.index])
      }
      URL.revokeObjectURL(objectUrl);
    }.bind(this, event);
    image.src = objectUrl;
  }

  dismissPayPopup() {
    this.displayImageError = false
  }

  onNameInput(event) {
    this.editedObject.resolution.name = event.target.value
    this.isValid = this.editedObject.resolution.name != null && this.editedObject.resolution.name != ""
    this.eventTitleCharacters = event.target.value.length;
    const copyResolution = structuredClone(this.editedObject.resolution)
    console.log("disp");

    this.store.dispatch(setLiveResolutionsAction({ resolutions: copyResolution}))
  }

  onArgumentInput(event) {
    this.editedObject.resolution.description = event.target.value
  }

  startResolution() {
    const command = new WsCommand(this.index, "open")
    this.sendCommand.emit(command)
  }

  out() {
    let floatingInput = document.getElementById(`input-${this.index}`) as HTMLInputElement
    floatingInput.style.display = 'none'

    this.blueLinkIcon = floatingInput.value.trim().length > 0 ? true : false

    this.editedObject.resolution.link = floatingInput.value
    const copyResolution = structuredClone(this.editedObject.resolution)
    this.store.dispatch(setLiveResolutionsAction({ resolutions: copyResolution }))
  }

  getInput() {
    let floatingInput = document.getElementById(`input-${this.index}`)
    floatingInput.style.display = 'block'
    floatingInput.focus()
  }

  delete() {
    this.deleteEvent.emit(this.index)
  }

  ngOnInit() {
    this.editedObject = new EditedResolution(this.index, this.resolution);
  }
}
