<div class="line-holder">
  <h3 class="resolution-index" id="resolution-{{index}}" (click)="fileInput.click()">{{index+1}}
    <input style="display: none" type="file" accept=".jpeg, .jpeg, .gif, .png" (change)="onFileChanged($event.target.files); fileInput.value=''" #fileInput>
  </h3>
  <div id="uploaded-wrapper-{{index}}" class="resolution-images-wrapper" (click)="fileInput.click()">
    <img id="uploaded-{{index}}" class="resolution-images" src="#" alt="">
  </div>
  <textarea type="text" id="{{ 'name-' + index }}" data-cy="resolution_txt" class="name-input" (keyup)="onNameInput($event)"
    (input)="common.autoHeight($event, 10)" [ngClass]="isValid == true ? 'valid-input' : 'invalid-input'"
    maxlength="255" placeholder="{{'POLL-CREATION.QUESTIONS.RESOLUTION-INPUT-PLACEHOLDER' | translate}}"
    value="{{resolution.name}}" (focusout)="markDeselect()">
  </textarea>

  <div class="link-icon">
    <input class="floatingInput" placeholder="Tapez ou copiez votre url ici" (focusout)="out()" id="input-{{index}}"/>
      <img *ngIf="!blueLinkIcon" class="icons"  (click)="getInput()" style="margin-bottom: 1px;" src="assets/grey-link.svg">
      <img *ngIf="blueLinkIcon" class="icons"  (click)="getInput()" style="margin-bottom: 1px;" src="assets/blue-link.svg">
  </div>

  <div *ngIf="index != 0" class="delete-container">
    <div class="icon-container">
      <img id="delete-button" (click)="delete()" src="assets/deletebutton.svg">
    </div>
  </div>
  <div *ngIf="index == 0" class="delete-placeholder"></div>
</div>
<div [ngClass]="resolutionCharacters == 255 ? 'character-counter-red' : 'character-counter'">{{resolutionCharacters}}/255</div>
<div *ngIf="temporality == 'live'" class="launch-container">
  <button *ngIf="!isValid" type="button" class="start-button-invalid">{{'POLL-CREATION.QUESTIONS.LIVE-LAUNCH' | translate}}</button>
  <button *ngIf="isValid" type="button" class="start-button-valid"  (click)="startResolution()">{{'POLL-CREATION.QUESTIONS.LIVE-LAUNCH' | translate}}</button>
</div>

<!--
-- POPUP FOR BOTH MODE
-->
<vc-warning-popup class="image-error" *ngIf="displayImageError"
  [understoodButton]="true" (endDetailsPopup)="dismissPayPopup()"
  title="{{'UPLOAD-IMAGES.POPUP-TEXT' | translate}}">
</vc-warning-popup>
