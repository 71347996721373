import {
  PollChoice,
  Resolution,
  LiveResolution,
  LiveQuestion,
} from './PollChoice';
import { Voter } from './Voter';
import {Assessor} from './Assessor';

export class Poll {
  public id: string;
  public votingType: string;
  public temporality: string;
  public title: string;
  public description: string;
  public startDate: string;
  public startTime: string;
  public endDate: string;
  public isUncrypted: boolean;
  public isSMS: boolean;
  public is2FA: boolean;
  public endTime: string;
  public choices: Array<Array<PollChoice>>;
  public voters: Array<Voter>;
  public resolutions: Array<Resolution>;
  public imageId: string;
  public liveResolutions: Array<LiveResolution>;
  public liveQuestions: Array<LiveQuestion>;
  public numberOfVote: number;
  public sender: string;
  public sharedLinks: string;
  public assessorsNames: string[] = ['', '', ''];
  public assessorsMails: string[] = ['', '', ''];
  public assessors: Assessor[];
  public isAssessors: boolean;
  public draftSaved: boolean;
  public electionIndexes: Array<number>;

  constructor() {
    this.choices = [];
    this.voters = [];
    this.resolutions = [];
    this.liveResolutions = [];
    this.liveQuestions = [];
    this.isUncrypted = false;
    this.isSMS = false;
    this.is2FA = false;
    this.numberOfVote = 1;
    this.draftSaved = false;
    this.electionIndexes = [0];
    this.isAssessors = false;
  }
}

export class DraftForm {
  id: string;
  name: string;
  data: string;

 fromPoll(poll: Poll): DraftForm {
   this.id = poll.id;
   this.name = poll.title;
   this.data = JSON.stringify(poll);
   return this;
 }
}

export class DisplayedQuestion {
  question: string;
  answers: string[];
  numberOfVote: number;
  links: string[];
  constructor(question: string, answers: string[], numberOfVote: number) {
    this.question = question;
    this.answers = answers;
    this.numberOfVote = numberOfVote;
  }
}

export class ResolutionResult {
  question: string;
  answers: string[];
  results: Result[];
  constructor(question: string, answers: string[], results: Result[]) {
    this.question = question;
    this.answers = answers;
    this.results = results;
  }
}

export class Result {
  constructor(
    public text: string,
    public percentage: number,
    public votes: number
  ) {}
}

export enum ResolutionType {
  resolution = 'resolution',
  liveElection = 'live'
}

export enum ElectionType {
  resolution = 'election',
  liveElection = 'liveCandidates'
}

export class DraftIdResponse {
  id: string;
}

export class ListResult {
  question: string;
  answers: string[];
  results: Result[];
  index: number;
  parent: number;
  votes: number;
  constructor(question: string, answers: string[], results: Result[], index: number, parent: number, votes: number) {
    this.question = question;
    this.answers = answers;
    this.results = results;
    this.index = index;
    this.parent = parent;
    this.votes = votes;
  }
}

export type VotingType = ResolutionType | ElectionType;

export enum PollType {
  resolution = 'resolution',
  election = 'election',
  // following types are deprecated and not used anymore
  // we kept them to avoid issue with already existing polls
  liveResolution = 'live',
  liveElection = 'liveCandidates',
}
