import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
} from '@angular/core';
import { PollChoice } from '../../../models/PollChoice';
import { Poll } from '../../../models/Poll';
import { Common } from '../../../shared/common';
import { Store } from '@ngrx/store';
import {
  setActivateAssessorsAction, setChoicesAction,
  setDescriptionAction,
  setDocumentAction,
  setPublicVoteAction,
  setSenderAction,
  setTitleAction,
  setVotingTypeAction
} from 'src/app/states/creationActions';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'vc-type-vote',
  templateUrl: 'type-vote.component.html',
  styleUrls: ['./type-vote.component.less'],
})
export class TypeVoteComponent implements OnInit {
  @Input() links = '';
  @Input() isLiveResolution: boolean;
  @Input() shouldResume: boolean;
  @Input() currentPoll: Poll;
  @Input() editMode = true;

  @Output() sendPollImage = new EventEmitter<Array<any>>();
  @Output() sendDraftSave = new EventEmitter<boolean>();
  choices = [new PollChoice(), new PollChoice()];
  titleCharacters = 0;
  descriptionCharacters = 0;
  senderCharacters = 0;
  documentsCharacters = 0;
  imageName: HTMLElement;
  displayImageError = false;

  constructor(
    public common: Common,
    private store: Store,
    private route: ActivatedRoute,
    ) {}

  setCrypto(event: { target: { checked: boolean } }) {
    this.store.dispatch(setPublicVoteAction({ isUncrypted: event.target.checked }));
  }

  setPollMode(votingType: string) {
    if (this.currentPoll.isUncrypted && votingType === 'list') {
      this.store.dispatch(setPublicVoteAction({ isUncrypted: false }));
    }
    if (this.currentPoll.isUncrypted && votingType === 'resolution') {
      this.store.dispatch(setActivateAssessorsAction({ isAssessors: false }));
    }
    const hasBecomeList = this.currentPoll.votingType !== 'list' && votingType === 'list';
    this.store.dispatch(setVotingTypeAction({ votingType }));
    if (hasBecomeList) {
      const copyChoices = structuredClone(this.currentPoll.choices);
      for (const group of copyChoices) {
        for (const choice of group) {
          choice.gender = 'M';
        }
      }
      this.store.dispatch(setChoicesAction({ choices : copyChoices }));
    }
  }

  onTitleInput(event: { target: { value: string } }) {
    this.store.dispatch(setTitleAction({ title: event.target.value }));
  }

  onDescriptionInput(event: { target: { value: string } }) {
    this.store.dispatch(setDescriptionAction({ description: event.target.value }));
  }

  senderInput(event: { target: { value: string } }) {
    this.store.dispatch(setSenderAction({ sender: event.target.value }));
  }

  linkInput(event: { target: { value: string } }) {
    this.store.dispatch(setDocumentAction({ sharedLinks : event.target.value }));
  }

  resumeFromPayment() {
    const storedPoll: Poll = JSON.parse(
      localStorage.getItem('editedPoll')
    );

    this.currentPoll.temporality = storedPoll.temporality;
    this.currentPoll.votingType = storedPoll.votingType;
    this.currentPoll.liveResolutions = storedPoll.liveResolutions;
    this.currentPoll.resolutions = storedPoll.resolutions;
    this.currentPoll.isUncrypted = storedPoll.isUncrypted;
    if (this.currentPoll.temporality === 'planned') {
      (document.getElementById('subject') as HTMLTextAreaElement).value =
        storedPoll.title;

      storedPoll.title === undefined ? (document.getElementById('subject') as HTMLTextAreaElement).value = '' :
        (document.getElementById('subject') as HTMLTextAreaElement).value = storedPoll.title;
      storedPoll.description === undefined ? (document.getElementById('arguments') as HTMLTextAreaElement).value = '' :
        (document.getElementById('arguments') as HTMLTextAreaElement).value = storedPoll.description;
      storedPoll.sender === undefined ? (document.getElementById('sender') as HTMLTextAreaElement).value = '' :
        (document.getElementById('sender') as HTMLTextAreaElement).value = storedPoll.sender;

      this.choices = storedPoll.choices[0];
    }
  }

  markSave() {
    this.sendDraftSave.emit(true);
  }

  fillFields() {
    if (this.currentPoll.description != undefined) {
      (document.getElementById('arguments') as HTMLTextAreaElement).value = this.currentPoll.description;
      document.getElementById('arguments').style.height = document.getElementById('arguments').scrollHeight - 10 + 'px';
      this.descriptionCharacters = this.currentPoll.description.length;
    }
    if (this.currentPoll.title != undefined) {
      (document.getElementById('subject') as HTMLTextAreaElement).value = this.currentPoll.title;
      document.getElementById('subject').style.height = document.getElementById('subject').scrollHeight - 10 + 'px';
      this.titleCharacters = this.currentPoll.title.length;
    }
    if (this.currentPoll.sender != undefined) {
      (document.getElementById('sender') as HTMLTextAreaElement).value = this.currentPoll.sender;
      document.getElementById('sender').style.height = document.getElementById('sender').scrollHeight - 10 + 'px';
      this.senderCharacters = this.currentPoll.sender.length;
    }
    if (this.currentPoll.sharedLinks != undefined && this.currentPoll.sharedLinks != 'null') {
      (document.getElementById('documents') as HTMLTextAreaElement).value = this.currentPoll.sharedLinks;
      document.getElementById('documents').style.height = document.getElementById('documents').scrollHeight - 10 + 'px';
      this.documentsCharacters = this.currentPoll.sharedLinks.length;
    }
    setTimeout(() => {
      if (this.currentPoll.imageId !== undefined && this.currentPoll.imageId.length > 1) { this.fillImage(); }
    }, 200);
  }

  fillImage() {
    const displayImage = document.getElementById('uploaded') as HTMLImageElement;
    const uploadImage = document.getElementById('upload-image-icon') as HTMLImageElement;
    const uploadDescription = document.getElementById('description-upload');
    const uploadedImagePanel = document.getElementById('upload-image-wrapper');
    const uploadImagePanel = document.getElementById('upload-panel');
    this.imageName = document.getElementById('image-name');
    displayImage.style.display = 'block';
    uploadedImagePanel.style.display = 'grid';
    uploadImage.style.display = 'none';
    uploadImagePanel.style.display = 'none';
    uploadDescription.style.display = 'none';
    displayImage.src = this.currentPoll.imageId;
    this.imageName.style.display = 'inline';
  }

  ngOnInit() {
    this.store.select((state: any) => state.creation.poll).subscribe(res => {
      this.currentPoll.votingType = res.votingType;
      this.currentPoll.temporality = res.temporality;
      this.currentPoll.title = res.title;
      this.titleCharacters = this.currentPoll.title == undefined ? 0 : this.currentPoll.title.length;
      this.currentPoll.description = res.description;
      this.descriptionCharacters = this.currentPoll.description == undefined ? 0 : this.currentPoll.description.length;
      this.currentPoll.sender = res.sender;
      this.senderCharacters = this.currentPoll.sender == undefined ? 0 : this.currentPoll.sender.length;
      this.currentPoll.sharedLinks = res.sharedLinks;
      this.documentsCharacters = this.currentPoll.sharedLinks == undefined ? 0 : this.currentPoll.sharedLinks.length;
      this.currentPoll.isUncrypted = res.isUncrypted;
      this.currentPoll.imageId = res.imageId;
    });
    if (this.editMode) { this.fillFields(); }
    this.route.queryParams.subscribe((params) => {
      const editCondition = params['publishedClone'];
      if ((editCondition === 'false' || editCondition === undefined) && this.shouldResume === true) {
        this.resumeFromPayment();
      }
    });
  }
  // TO CHECK

  onFileChanged(event) {
    const displayImage = document.getElementById('uploaded') as HTMLImageElement;
    const uploadImage = document.getElementById('upload-image-icon') as HTMLImageElement;
    const uploadDescription = document.getElementById('description-upload');
    const uploadedImagePanel = document.getElementById('upload-image-wrapper');
    const uploadImagePanel = document.getElementById('upload-panel');
    this.imageName = document.getElementById('image-name');

    const reader = new FileReader();
    const extensionImage = event[0].name.substring(event[0].name.lastIndexOf('.'));

    const imageBlob = new Blob(event);

    reader.readAsDataURL(imageBlob);
    if (event[0].size > 2097152) {
      this.displayImageError = true;
    }


    const image = new Image();
    const objectUrl = URL.createObjectURL(event[0]);
    image.onload = function() {
      if (image.width !== image.height) {
        this.displayImageError = true;
      }
      if (this.displayImageError === false) {

        displayImage.style.display = 'block';
        uploadedImagePanel.style.display = 'grid';
        uploadImage.style.display = 'none';
        uploadImagePanel.style.display = 'none';
        uploadDescription.style.display = 'none';
        this.imageName.style.display = 'block';
        displayImage.src = URL.createObjectURL(imageBlob);
        this.sendPollImage.emit([extensionImage, imageBlob]);
      }
      URL.revokeObjectURL(objectUrl);
    }.bind(this, event);
    image.src = objectUrl;
  }

  deleteImage() {
    const displayImage = document.getElementById('uploaded') as HTMLImageElement;
    const uploadImage = document.getElementById('upload-image-icon') as HTMLImageElement;
    const uploadDescription = document.getElementById('description-upload');
    const uploadedImagePanel = document.getElementById('upload-image-wrapper');
    const uploadImagePanel = document.getElementById('upload-panel');
    this.imageName = document.getElementById('image-name');

    displayImage.style.display = 'none';
    uploadedImagePanel.style.display = 'none';
    uploadImage.style.display = 'block';
    uploadImagePanel.style.display = 'block';
    uploadDescription.style.display = 'block';
    this.imageName.style.display = 'none';
  }

  dismissPayPopup() {
    this.displayImageError = false;
  }
}
