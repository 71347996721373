<div class="div-arriere-plan-action" (click)="clicAction($event)"></div>
<div class="div-global-action">
  <div id="center-square">
    <div id="blue-lines"></div>
    <img class="close-icon" src="assets/close_24px.svg" (click)="cancelDetails(false)">
    <div class="text-holder">
      <h1 *ngIf="title !== undefined && mails === undefined" class="header_title title">
        {{title}}
      </h1>
      <h1 *ngIf="title !== undefined && mails !== undefined && !searchEnabled" class="header_title title" style="xwidth: 100%">
        {{title}} <img src="assets/search.svg" class="search-icon" (click)="enableSearch()">
      </h1>
      <div style="width: 100%; display: flex; justify-content: right">
        <input *ngIf="searchEnabled" type="text" id="voter-filter" placeholder="{{title}}" (keyup)="filterVoters()">
      </div>
      <h1 *ngIf="firstText !== undefined" class="header_title">
        {{firstText}}
      </h1>
      <h1 *ngIf="secondText !== undefined" class="header_title">
        {{secondText}}
      </h1>
      <h1 *ngIf="thirdText !== undefined" class="header_title">
        {{thirdText}}
      </h1>

      <!-- Error text for excel import errors -->
      <vc-excel-error *ngIf="isImportError" [isImportError]="isImportError" [isPonderationError]="isPonderationError"
      [isSMSError]="isSMSError" [is2FA]="is2FA" [numberOfVoters]="voters.length" [messageString]="messageString"></vc-excel-error>

      <div *ngIf="separator !== false" class="separator"></div>

      <input *ngIf="phoneInput" type="tel" id="phone-input" placeholder="{{'DETAILS-CONTACT.PLACEHOLDER' | translate}}"
        (keyup)="onPhoneEdit($event)">
    </div>

    <!-- List of voters for the popup after a successful excel upload -->
    <div *ngIf="!SMSOffers && voters !== undefined && !isImportError" class="voters">
      <h3 *ngFor="let voter of voters" class="recommendations-title" style="padding-left: 8px; padding-right: 8px">{{voter.email}}</h3>
    </div>

    <!-- List of remaining voters from the poll summary -->
    <div *ngIf="mails !== undefined && !isImportError && !searchEnabled" class="voters">
      <h3 *ngFor="let voter of mails" class="recommendations-title" style="padding-left: 8px; padding-right: 8px">{{returnVoterString(voter)}}</h3>
    </div>
    <div *ngIf="mails !== undefined && !isImportError && searchEnabled" class="voters">
      <h3 *ngFor="let voter of filteredMails" class="recommendations-title" style="padding-left: 8px; padding-right: 8px">{{returnVoterString(voter)}}</h3>
    </div>

    <!-- Offer details after clicking on "Plus d'information" -->
    <vc-offer-details-popup [index]="index" [isAssoc]="isAssoc"></vc-offer-details-popup>

    <vc-sms-details-popup *ngIf="SMSOffers" [isAssoc]="isAssoc" [voters]="voters"
    (selectedOption)="SMSOption($event)" [SMSOwned]="SMSOwned"></vc-sms-details-popup>

    <vc-hotline-details *ngIf="hotline" [isAssoc]="isAssoc" (selectedOption)="hotlineOption($event)"
    [licenseTime]="licenseTime"></vc-hotline-details>

    <div *ngIf="phoneInput" class="button-holder">
      <button id="button-cancel" class="phone-cancel" (click)="cancelDetails(false)">{{'PRICE-DETAILS.CANCEL' |
        translate}}</button>
      <button *ngIf="isPhoneValid" id="button-vote" (click)="sendContactPhone()">{{'PRICE-DETAILS.RECALL' |
        translate}}</button>
      <button *ngIf="!isPhoneValid" id="button-vote-invalid">{{'PRICE-DETAILS.RECALL' | translate}}</button>
    </div>

    <div *ngIf="!phoneInput" class="button-holder">
      <button *ngIf="cancelElectionButton" type="button" id="button-cancel" (click)="cancelDetails(false)">
        {{'POLL-WARNING.CANCEL-ELECTION' | translate}}
      </button>
      <button *ngIf="cancelButton" type="button" id="button-cancel" (click)="cancelDetails(false)">
        {{'OFFERS.WARNING.CANCEL' | translate}}
      </button>
      <button *ngIf="validateButton" type="button" id="button-vote" (click)="launch()">
        {{'OFFERS.WARNING.CONFIRM' | translate}}
      </button>
      <div *ngIf="downloadButton" class="download-button" (click)="downloadVoterFile()">
        <img src="assets/certificate-dl.svg">
        <span class="download-text">{{'POLL-FINISHED.DOWNLOAD-VOTERS' | translate}}</span>
      </div>
      <button *ngIf="confirmButton" data-cy="confirm" type="button" id="button-vote" (click)="cancelDetails(true)">
        {{'POLL-WARNING.CONFIRM' | translate}}
      </button>
      <button *ngIf="deleteButton" data-cy="confirm" type="button" id="delete-button" (click)="cancelDetails(true)">
        {{'POLL-WARNING.CONFIRM' | translate}}
      </button>
      <!-- Editions button -->
      <button *ngIf="continueEditionButton" type="button" id="button-continue" (click)="cancelDetails(false)">
        {{'EDIT-POPUP.CONTINUE' | translate}}
      </button>
      <button *ngIf="cancelEditionButton" data-cy="leave" type="button" id="button-vote" (click)="cancelDetails(true)">
        {{'EDIT-POPUP.LEAVE' | translate}}
      </button>
      <!-- true to launch a poll -->
      <button *ngIf="understoodButton" type="button" id="button-vote" (click)="cancelDetails(true)">
        {{'DETAILS-CONTACT.UNDERSTOOD' | translate}}
      </button>
      <button *ngIf="pleaseButton" type="button" id="button-cancel" (click)="cancelDetails(false)">
        {{'POLL-WARNING.REFUSE' | translate}}
      </button>
      <button *ngIf="pleaseButton" type="button" id="button-vote" (click)="cancelDetails(true)">
        {{'POLL-WARNING.PLEASE' | translate}}
      </button>
      <!-- False to close popup after number phone validation -->
      <button *ngIf="understoodEndButton" type="button" id="button-vote" (click)="cancelDetails(false)">
        {{'DETAILS-CONTACT.UNDERSTOOD' | translate}}
      </button>
      <button *ngIf="callButton" type="button" id="button-vote" (click)="cancelDetails(true)">
        {{'PRICE-DETAILS.RECALL' | translate}}
      </button>
      <button *ngIf="changeVoters" type="button" class="button-continue" (click)="cancelDetails(false)">
        {{'POLL-WARNING.2FA-EDIT' | translate}}
      </button>
      <button *ngIf="continueButton" type="button" id="button-vote" (click)="cancelDetails(true)">
        {{'POLL-WARNING.2FA-CONTINUE' | translate}}
      </button>
      <button *ngIf="noSMSButton" type="button" id="button-sms" (click)="SMSOption(0)">
        {{'PRICE-DETAILS.NO-SMS' | translate}}
      </button>
    </div>
  </div>
</div>
