import {Component, Input, OnInit} from '@angular/core';
import {PollChoice} from '../../../../models/PollChoice';
import {Common} from '../../../../shared/common';

@Component({
  selector: 'app-list-candidate-item',
  templateUrl: './list-candidate-item.component.html',
  styleUrls: ['./list-candidate-item.component.less', './../../candidates-controller/candidates-item/candidates-item.component.less']
})
export class ListCandidateItemComponent implements OnInit {
  @Input() choice: PollChoice;
  @Input() index: number;
  answer = true;

  selection(index: number): void {
    const checkbox = document.getElementById(`checkbox-${index}`) as HTMLInputElement;
    this.answer = checkbox.checked;
  }

  constructor(
    public common: Common
  ) { }

  ngOnInit(): void {
  }

}
