import { SubsciptionData } from '../../models/LicenseData';
import { Component, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'vc-confirm-popup',
  templateUrl: 'poll-confirm-popup.component.html',
  styleUrls: ['./poll-confirm-popup.component.less'],
})
export class PollConfirmComponent {
  @Output() shouldCancelDisplay = new EventEmitter<boolean>();
  @Input() newLicences: Array<SubsciptionData>;
  voteLicence: SubsciptionData;
  messageString = '';

  cancel() {
    this.shouldCancelDisplay.emit(true);
  }

  clicAction($event) {
    if (
      $event.target.className === 'div-arriere-plan-action' ||
      $event.target.className === 'div-global-action'
    ) {
      this.cancel();
    }
  }

  constructor() {}

  ngOnInit() {
    // this.voteLicence = this.newLicences.filter(
    //   (licence) => licence.type == "V"
    // )[0];
    // if (this.newLicences.filter((licence) => licence.type == "S").length > 0) {
    //   this.messageString =
    //     " électeurs avec option SMS, de façon illimitée pendant ";
    // } else {
    //   this.messageString = " électeurs, de façon illimitée pendant ";
    // }
    // switch (this.voteLicence.duration) {
    //   case "Y": {
    //     this.messageString += "1 an";
    //     break;
    //   }
    //   case "M": {
    //     this.messageString += "30j";
    //     break;
    //   }
    //   default:
    //     this.messageString += "24h";
    // }
  }
}
