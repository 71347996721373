import {DisplayedQuestion} from './Poll';

export class PollChoice {
  public name: string;
  public description: string;
  public link: string;
  public image: string;
  public gender: string;
  constructor() {
    this.gender = 'M';
  }
  public static withName(name: string) {
    const choice = new PollChoice();
    choice.name = name;
    return choice;
  }

  public static withNameAndGender(name: string) {
    const choice = new PollChoice();
    if (name.startsWith('Mme ')) {
      choice.name = name.substring(4, name.length);
      choice.gender = 'F';
    } else if (name.startsWith('M. ')){
      choice.name = name.substring(3, name.length);
      choice.gender = 'M';
      } else {
        choice.name = name;
      }
    return choice;
  }
}

export class EditedPollChoice {
  public choice: PollChoice;
  public index: number;
  public link: string;
  constructor(index: number, choice: PollChoice) {
    this.choice = choice;
    this.index = index;
  }
}

export class Resolution {
  public name: string;
  public description: string;
  public link: string;
  public choices: Array<PollChoice>;
  public image: string;
  constructor(isLive: boolean) {
    const yesResponse = PollChoice.withName('POUR');
    const noResponse = PollChoice.withName('CONTRE');
    const abstentionResponse = PollChoice.withName('ABSTENTION');
    const notResponded = PollChoice.withName('Pas de réponse');
    if (isLive) {
      this.choices = [yesResponse, noResponse, abstentionResponse];
    } else {
      this.choices = [
        yesResponse,
        noResponse,
        abstentionResponse,
        notResponded,
      ];
    }
  }

  fromDisplayedQuestion(input: DisplayedQuestion) {
    const resolution = new Resolution(input.answers.length === 3);
    resolution.name = input.question;
    if (input.links !== null && input.links.length > 0) {
      resolution.link = input.links[0];
    }
    return resolution;
  }
}



export class EditedResolution {
  public resolution: Resolution;
  public index: number;
  constructor(index: number, resolution: Resolution) {
    this.resolution = resolution;
    this.index = index;
  }
}

export class WsCommand {
  public command: string;
  public index: number;
  constructor(index: number, command: string) {
    this.command = command;
    this.index = index;
  }
}

export class LiveResolution {
  public resolution: Resolution;
  public index: number;
  public status: number;
  constructor(index: number, resolution: Resolution, status: number) {
    this.resolution = resolution;
    this.index = index;
    this.status = status;
  }
}

export class EditedAnswer {
  public index: number;
  public answer: string;
  constructor(index: number, answer: string) {
    this.index = index;
    this.answer = answer;
  }
}

export class LiveQuestion {
  public electionTitle: string;
  public options: Array<string>;
  public title: string;
  public index: number;
  public status: number;
  public numberOfVote: number;
  public minNumberVote: number;
  public listLink: string;
  public listImage: Array<string>;
  constructor(
    index: number,
    options: string[],
    title: string,
    status: number,
    numberOfVote: number = 1,
    minNumberVote: number = 1,
    electionTitle: string = '',
    listLink: string = null,
    listImage: Array<string> = null
  ) {
    this.options = options;
    this.title = title;
    this.index = index;
    this.status = status;
    this.numberOfVote = numberOfVote;
    this.minNumberVote = minNumberVote;
    this.electionTitle = electionTitle;
    this.listLink = listLink;
    this.listImage = listImage;
  }
}
