import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LiveQuestion, PollChoice} from '../../../models/PollChoice';

@Component({
  selector: 'app-candidates-controller',
  templateUrl: './candidates-controller.component.html',
  styleUrls: ['./candidates-controller.component.less', './../questions-preview.component.less']
})
export class CandidatesControllerComponent implements OnInit {
  @Input() liveQuestion: LiveQuestion;
  @Output() sendBack = new EventEmitter();
  @Input() choices: Array<PollChoice>;

  constructor() { }

  back() {
    this.sendBack.emit();
  }

  simpleSelection(index: number) {
    const checkBoxes = document.getElementsByClassName('box') as HTMLCollectionOf<HTMLInputElement>;
    for (const item of checkBoxes) {
      if (item.checked && item.id !== `checkbox-${index}`) {
        item.checked = false;
      }
    }
  }

  multipleSelection(index: number) {
    if (this.getSelectionCounter() > this.liveQuestion.numberOfVote) {
      const selItem = document.getElementById(`checkbox-${index}`) as HTMLInputElement;
      selItem.checked = false;
      const x = document.getElementById('snackbar');
      // Add the "show" class to DIV
      x.className = 'show';
      // After 3 seconds, remove the show class from DIV
      setTimeout(() => {
        x.className = x.className.replace('show', '');
      }, 3000);
    }
  }

  getSelectionCounter(): number {
    const checkBoxes = document.getElementsByClassName('box') as HTMLCollectionOf<HTMLInputElement>;
    let selectionCount = 0;
    for (const checkbox of checkBoxes) {
      if (checkbox.checked) {
        selectionCount++;
      }
    }
    return selectionCount;
  }

  getSelection(index: number) {
    if (this.liveQuestion.numberOfVote > 1) {
      this.multipleSelection(index);
    } else {
      this.simpleSelection(index);
    }
  }

  ngOnInit(): void {
  }

}
