import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { PollItemData } from "../../models/PollItemData";
import { AuthService } from "@auth0/auth0-angular";

@Component({
  selector: "vc-poll-column",
  templateUrl: "poll-column.component.html",
  styleUrls: ["./poll-column.component.less", "../../shared/common.less"],
})

export class PollColumnComponent implements OnInit {
  @Input() polls: Array<PollItemData> = [];
  @Input() pollTitle: string;
  @Input() isExpanded: boolean = false;
  // Permet de définir le type de colonne: 0=draft, 1=planned, 2=ongoing, 3=completed
  @Input() category: number;
  // Active le boutton "supprimer" sur un item
  @Input() enableDeletion: boolean = false;
  @Input() super: boolean = false;

  constructor(
    public auth: AuthService,
    private router: Router,
    ) {}

  navigateToPlannedPoll(id: string) {
    this.router.navigate(["/poll-planned/" + id]);
  }

  navigateToOngoingPoll(id: string) {
    this.router.navigate(["/poll-ongoing/" + id]);
  }

  navigateToClosedPoll(id: string) {
    this.router.navigateByUrl("/poll-result?poll=" + id);
  }

  editPoll(id: string) {
    this.router
      .navigateByUrl("/vc-poll-edition", { skipLocationChange: true })
      .then(() => {
        this.router.navigate(["poll-creation/" + id]);
      });
  }

  refreshPolls() {
    this.router
      .navigateByUrl('/vc-poll-dashboard', { skipLocationChange: true })
      .then(() => {
        this.router.navigate(['polls']);
      });
  }

  clonePoll(id: string, isPublished: boolean) {
    this.router.navigateByUrl(`poll-creation/${id}?publishedClone=${isPublished}`);
  }

  seeMore(status: boolean) {
    this.isExpanded = status;
  }

  ngOnInit() {
  }
}
