import {Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges} from '@angular/core';
import { ref } from 'firebase/storage';
import { storage } from 'src/app/firebase';
import { v4 } from 'uuid';
import {Resolution, EditedResolution, WsCommand} from '../../../models/PollChoice';
import { Common } from '../../../shared/common';

@Component({
  selector: 'vc-live-resolution',
  templateUrl: 'live-resolution.component.html',
  styleUrls: ['./live-resolution.component.less']
})

export class LiveResolutionComponent implements OnInit, OnChanges {
  @Input() index: number;
  @Input() resolution: Resolution;
  @Input() temporality: string;
  @Input() titleSet: string;
  @Input() parentIdentifier: number;
  @Output() sendCommand = new EventEmitter<WsCommand>();
  @Output() deleteEvent = new EventEmitter<number>();
  @Output() sendResolution = new EventEmitter<EditedResolution>();
  @Output() linkEvent = new EventEmitter<[number, string]>();
  @Output() sendAnswerImage = new EventEmitter<Array<any>>();
  @Output() sendDeselect = new EventEmitter<boolean>();

  displayImageError = false;
  editedObject: EditedResolution;
  isValid = false;
  resolutionCharacters = 0;
  blueLinkIcon = false;

  constructor(public common: Common) {}

  onNameInput(event) {
    const newResolution = Object.assign({}, this.resolution);
    newResolution.name = event.target.value;
    this.editedObject.resolution = newResolution;
    this.isValid = this.editedObject.resolution.name != null && this.editedObject.resolution.name != '' &&
    this.titleSet != null && this.titleSet != '';
    this.editedObject.index = this.index;
    this.sendResolution.emit(this.editedObject);
    this.resolutionCharacters = event.target.value.length;
  }

  startResolution() {
    const command = new WsCommand(this.index, 'open');
    this.sendCommand.emit(command);
  }

  dismissPayPopup() {
    this.displayImageError = false;
  }

  markDeselect() { this.sendDeselect.emit(true); }

  onFileChanged(event) {
    const displayImage = document.getElementById(`uploaded-${this.index}`) as HTMLImageElement;
    const wrapper = document.getElementById(`uploaded-wrapper-${this.index}`) as HTMLImageElement;
    const resolutionIndex = document.getElementById(`resolution-${this.index}`) as HTMLImageElement;

    const reader = new FileReader();
    const extensionImage = event[0].name.substring(event[0].name.lastIndexOf('.'));

    const imageRef = ref(storage, `${v4()}${extensionImage}`)
    const imageBlob = new Blob(event);

    reader.readAsDataURL(imageBlob);

    if (event[0].size > 2097152) {
      this.displayImageError = true;
    }

    const image = new Image();
    const objectUrl = URL.createObjectURL(event[0]);
    image.onload = function() {
      if (image.width !== image.height) {
        this.displayImageError = true;
      }
      if (this.displayImageError === false) {
        wrapper.style.display = 'flex';
        resolutionIndex.style.display = 'none';
        this.sendAnswerImage.emit([imageRef, imageBlob, this.index]);
        displayImage.src = URL.createObjectURL(imageBlob);
      }
      URL.revokeObjectURL(objectUrl);
    }.bind(this, event);
    image.src = objectUrl;
  }

  delete() {
    this.deleteEvent.emit(this.index);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.editedObject !== undefined) {
      if (changes.resolution != undefined && this.editedObject.resolution.name !== this.resolution.name) {
        this.editedObject.resolution = this.resolution;
        this.resolutionCharacters = this.resolution.name.length;
      }
      this.isValid = typeof this.titleSet !== undefined &&
        (this.editedObject.resolution.name != null && this.editedObject.resolution.name != "")
        && this.titleSet.length > 0;
      }
    else if (changes.index != null && changes.resolution != null && changes.titleSet != null){
      const resChanges: Resolution = changes.resolution.currentValue;
      if ((resChanges.name != null && resChanges.name != '')
      && this.titleSet.length > 0) { this.isValid = true; }
    }
  }

  ngAfterViewInit() {
    if (this.resolution.name !== undefined) {
      const reso = document.getElementById('name-' + this.index);
      reso.style.height = reso.scrollHeight - 10 + 'px';
    }
  }

  ngOnInit() {
    this.editedObject = new EditedResolution(this.index, this.resolution);
    if (this.resolution !== undefined && this.resolution.name !== undefined) {
      this.resolutionCharacters = this.resolution.name.length;
    }
    if (this.resolution.name != undefined) {
        if ((document.getElementById('name-' + this.index) as HTMLTextAreaElement) != null) {
          (document.getElementById('name-' + this.index) as HTMLTextAreaElement).value = this.resolution.name;
          this.resolutionCharacters = this.resolution.name.length;
        }
    }
    if (this.resolution.link !== null && this.resolution.link !== undefined && this.resolution.link.length > 0) {
      this.blueLinkIcon = true;
      setTimeout(() => {
        const floatingInput = document.getElementById(`input-${this.index}`) as HTMLInputElement;
        floatingInput.value = this.resolution.link;
        const image = floatingInput.parentElement.children[1] as HTMLImageElement;
        image.src = 'assets/blue-link.svg';
      }, 500);
    }
    if (this.resolution.image !== null && this.resolution.image !== undefined) {
      setTimeout(() => {
        const displayImage = document.getElementById(`uploaded-${this.index}`) as HTMLImageElement;
        const wrapper = document.getElementById(`uploaded-wrapper-${this.index}`) as HTMLImageElement;
        const candidateIndex = document.getElementById(`resolution-${this.index}`) as HTMLImageElement;
        candidateIndex.style.display = 'none';
        wrapper.style.display = 'flex';
        displayImage.src = this.resolution.image;
        this.editedObject.resolution.image = this.resolution.image;
      }, 500);
    }
    console.log(this.editedObject);
  }

  out() {
    const floatingInput = document.getElementById(`input-${this.index}`) as HTMLInputElement
    floatingInput.style.display = 'none';
    if (floatingInput.value != undefined && floatingInput.value.length > 0) {
      const link = floatingInput.value.trim();

      if (link.length > 0) {
        this.blueLinkIcon = true;
      }
    } else {
      this.blueLinkIcon = false;
    }
    this.editedObject.resolution.link = floatingInput.value;
    this.sendResolution.emit(this.editedObject);
    // this.linkEvent.emit([this.index, floatingInput.value])
  }

  getInput() {
    const floatingInput = document.getElementById(`input-${this.index}`);
    floatingInput.style.display = 'block';
    floatingInput.focus();
  }
}
