import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { AppComponent } from './app.component';
import { AppConfigService } from './shared/app-config.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ContactForm } from './login/contact-form/contact-form.component';
import { PollCardHeaderComponent } from './poll-creation/poll-card-header/poll-card-header.component';
import { PollCreationComponent } from './poll-creation/poll-creation.component';
import { PlurinominalInput } from './poll-creation/plurinominal-input/plurinominal-input.component';
import { LivePollComponent } from './poll-live/poll-live.component';
import { PollCreationFormComponent } from './poll-creation/poll-creation-form/poll-creation-form.component';
import { VotedListComponent } from './poll-creation/voted-list/voted-list.component';
import { TypeVoteComponent } from './poll-creation/voted-list/type-vote/type-vote.component';
import { VotersListComponent } from './poll-creation/voters-list/voters-list.component';
import { InputCandidateComponent } from './poll-creation/voted-list/input-candidate/input-candidate.component';
import { InputResolutionComponent } from './poll-creation/voted-list/input-resolution/input-resolution.component';
import { LiveResolutionComponent } from './poll-creation/voted-list/live-resolution/live-resolution.component';
import { ConfirmationComponent } from './poll-creation/confirmation-component/confirmation-component.component';
import { PollPlannedComponent } from './poll-planned/poll-planned.component';
import { PollPlannedSummaryComponent } from './poll-planned/poll-planned-summary/poll-planned-summary.component';
import { PollPlannedTimerComponent } from './poll-planned/poll-planned-timer/poll-planned-timer.component';
import { PollPlannedList } from './poll-planned/poll-planned-list/poll-planned-list.component';
import { PollOngoingComponent } from './poll-ongoing/poll-ongoing.component';
import { PollOngoingSummaryComponent } from './poll-ongoing/poll-ongoing-summary/poll-ongoing-summary.component';
import { PollOngoingTimerComponent } from './poll-ongoing/poll-ongoing-timer/poll-ongoing-timer.component';
import { PollFinishedComponent } from './poll-finished/poll-finished.component';
import { PollFinishedSummaryComponent } from './poll-finished/poll-finished-summary/poll-finished-summary.component';
import { PollResultsComponent } from './poll-finished/result-holder/result-holder.component';
import { PollResultsResultComponent } from './poll-finished/result-holder/poll-results-result/poll-results-result.component';
import { PollResultsListComponent } from './poll-finished/result-holder/poll-results-list/poll-results-list.component';
import { PollResultsResolutionListComponent } from './poll-finished/result-holder/poll-results-resolution-list/poll-results-resolution-list.component';
import { PollDashboardComponent } from './polls-dashboard/poll-dashboard.component';
import { PollColumnComponent } from './polls-dashboard/poll-column/poll-column.component';
import { PollDashboardItemComponent } from './polls-dashboard/poll-dashboard-item/poll-dashboard-item.component';
import { LivePollsListComponent } from './poll-live/live-polls-list/live-polls-list.component';
import { LiveFixedResolutionComponent } from './poll-live/live-polls-list/live-resolution/live-resolution.component';
import { AddedResolutionComponent } from './poll-live/live-polls-list/added-resolution/added-resolution.component';
import { LiveCandidatesListComponent } from './poll-live/live-candidates-list/live-candidates-list.component';
import { LiveFixedCandidateComponent } from './poll-live/live-candidates-list/live-candidates/live-candidate.component';
import { AddedCandidateComponent } from './poll-live/live-candidates-list/added-candidate/added-candidate.component';
import { AddedCandidateInput } from './poll-live/live-candidates-list/added-candidate/added-candidate-input/input-candidate.component';
import { LoaderComponent } from './poll-creation/loader/loader.component';
import { HomeComponent } from './home/home.component';
import { Offers } from './login/offers/offers.component';
import { OffersPopup } from './polls-dashboard/offers-popup/offers-popup.component';
import { OfferCard } from './polls-dashboard/offer-card/offer-card.component';
import { OfferCardMain } from './polls-dashboard/offer-card-main/offer-card-main.component';
import { WarningPopup } from './poll-creation/warning-popup/warning-popup.component';
import { OfferDetailsPopup } from './poll-creation/warning-popup/offer-details-popup/offer-details-popup.component';
import { SMSDetailsPopup } from './poll-creation/warning-popup/sms-details-popup/sms-details-popup.component';
import { ExcelError } from './poll-creation/warning-popup/excel-error-details/excel-error-details.component';
import { PollConfirmComponent } from './poll-creation/poll-confirm-popup/poll-confirm-popup.component';
import { VotersRelaunchComponent} from './poll-ongoing/voters-relaunch/voters-relaunch.component';
import { PollPagesHeader } from './poll-live/poll-pages-header/poll-pages-header.component';
import { SubscribeComponent } from './home/new-login/auth/inscription/inscription.component';
import { HomepagePopup } from './home/new-login/auth/homepage-popup/homepage-popup.component';
import { HotlineDetails } from './poll-creation/warning-popup/hotline-details/hotline-details.component';
import { StatusBar } from './poll-live/poll-live-shared/status-bar.component';
import { ListResultsListComponent } from './poll-finished/result-holder/list-results-list/list-results-list.component';
import { PollResultsRatureResultComponent } from './poll-finished/result-holder/poll-results-rature-result/poll-results-rature-result.component';
import { AssessorsToggle } from './poll-creation/voted-list/assessors-toggle/assessors-toggle.component';
import { AssessorsFields } from './poll-creation/voted-list/assessors-fields/assessors-fields.component';
import { InputAssessorComponent } from './poll-creation/voted-list/input-assessor/input-assessor.component';
import { BlueButtonComponent } from './shared/buttons/blue-button/blue-button.component';
import { DisabledButtonComponent } from './shared/buttons/disabled-button/disabled-button.component';
import { WhiteDashedButtonComponent } from './shared/buttons/with-dashed-button/white-dashed-button.component';

import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';

import { ErrorComponent } from './error-view/error.component';
import { VotersDisplayComponent } from './voters-display/voters-display.component';
import { AuthModule } from '@auth0/auth0-angular';
import { AuthHttpInterceptorExtended } from './helpers/AuthHttpInterceptorExtended';
import { environment as env } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DragDropDirective } from './drag-drop-directive.directive';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { I18nService } from './core/i18n.service';
import { StoreModule } from '@ngrx/store';
import { appReducer } from './states/reducersMap';
import { ProfileComponent } from './profile/profile.component';
import { PollImportComponent } from './poll-import/poll-import.component';
import {NgOptimizedImage} from '@angular/common';
import { PollPlannedAssessorComponent } from './poll-planned-assessor/poll-planned-assessor.component';
import { AssessorValidationHeaderComponent } from './poll-planned-assessor/assessor-validation-header/assessor-validation-header.component';
import { PollOngoingAssessorComponent } from './poll-ongoing-assessor/poll-ongoing-assessor.component';
import { ChangeVoterComponent } from './poll-ongoing/change-voter/change-voter.component';
import { ValidChangesComponent } from './poll-ongoing/change-voter/valid-changes/valid-changes.component';
import { AssessorChangeComponentComponent } from './poll-planned/assessor-change-component/assessor-change-component.component';
import { PollPreviewComponent } from './poll-preview/poll-preview.component';
import {NgCircleProgressModule} from 'ng-circle-progress';
import { QuestionsPreviewComponent } from './poll-preview/questions-preview/questions-preview.component';
import { PreviewResolutionComponent } from './poll-preview/questions-preview/preview-resolution/preview-resolution.component';
import { PreviewResolutionItemComponent } from './poll-preview/questions-preview/preview-resolution-item/preview-resolution-item.component';
import { PreviewResolutinsChoicesComponent } from './poll-preview/questions-preview/preview-resolutins-choices/preview-resolutins-choices.component';
import { PreviewEventItemComponent } from './poll-preview/questions-preview/preview-event-item/preview-event-item.component';
import { CandidatesControllerComponent } from './poll-preview/questions-preview/candidates-controller/candidates-controller.component';
import { CandidatesItemComponent } from './poll-preview/questions-preview/candidates-controller/candidates-item/candidates-item.component';
import { ListCandidatesControllerComponent } from './poll-preview/questions-preview/list-candidates-controller/list-candidates-controller.component';
import { ListCandidateItemComponent } from './poll-preview/questions-preview/list-candidates-controller/list-candidate-item/list-candidate-item.component';
import { PlurionominalMinInputComponent } from './poll-creation/plurionominal-min-input/plurionominal-min-input.component';


@NgModule({
  declarations: [
    AppComponent,
    PollCreationComponent,
    LivePollComponent,
    PollCreationFormComponent,
    PollPlannedComponent,
    VotedListComponent,
    TypeVoteComponent,
    VotersListComponent,
    InputCandidateComponent,
    InputResolutionComponent,
    LiveResolutionComponent,
    ConfirmationComponent,
    PollPlannedSummaryComponent,
    PollPlannedTimerComponent,
    PollPlannedList,
    PollOngoingComponent,
    PollOngoingSummaryComponent,
    PollOngoingTimerComponent,
    AddedResolutionComponent,
    PollFinishedComponent,
    PollFinishedSummaryComponent,
    PollResultsComponent,
    PollResultsResultComponent,
    PollResultsListComponent,
    PollResultsResolutionListComponent,
    PollDashboardComponent,
    PollColumnComponent,
    PollDashboardItemComponent,
    ErrorComponent,
    LivePollsListComponent,
    LiveFixedResolutionComponent,
    VotersDisplayComponent,
    LiveCandidatesListComponent,
    LiveFixedCandidateComponent,
    AddedCandidateComponent,
    AddedCandidateInput,
    LoaderComponent,
    DragDropDirective,
    HomeComponent,
    PollCardHeaderComponent,
    ContactForm,
    PlurinominalInput,
    Offers,
    OffersPopup,
    OfferCard,
    OfferCardMain,
    PollConfirmComponent,
    VotersRelaunchComponent,
    WarningPopup,
    OfferDetailsPopup,
    SubscribeComponent,
    ExcelError,
    SMSDetailsPopup,
    HomepagePopup,
    PollPagesHeader,
    HotlineDetails,
    ListResultsListComponent,
    PollResultsRatureResultComponent,
    StatusBar,
    AssessorsToggle,
    AssessorsFields,
    InputAssessorComponent,
    BlueButtonComponent,
    DisabledButtonComponent,
    WhiteDashedButtonComponent,
    ProfileComponent,
    PollImportComponent,
    PollPlannedAssessorComponent,
    AssessorValidationHeaderComponent,
    PollOngoingAssessorComponent,
    ChangeVoterComponent,
    ValidChangesComponent,
    AssessorChangeComponentComponent,
    PollPreviewComponent,
    QuestionsPreviewComponent,
    PreviewResolutionComponent,
    PreviewResolutionItemComponent,
    PreviewResolutinsChoicesComponent,
    PreviewEventItemComponent,
    CandidatesControllerComponent,
    CandidatesItemComponent,
    ListCandidatesControllerComponent,
    ListCandidateItemComponent,
    PlurionominalMinInputComponent
  ],
    imports: [
        HttpClientModule,
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        AuthModule.forRoot({
            ...env.auth,
            httpInterceptor: {
                ...env.httpInterceptor,
            },
        }),
        BrowserAnimationsModule,
        MatButtonToggleModule,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        MatInputModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        StoreModule.forRoot(appReducer.reducers, {metaReducers: appReducer.metaReducers}),
        NgOptimizedImage,
        NgCircleProgressModule,
    ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptorExtended,
      multi: true,
    },
    {
      provide: Window,
      useValue: window,
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AppConfigService],
      useFactory: (appConfigService: AppConfigService) => {
        return () => {
          // Make sure to return a promise!
          return appConfigService.loadAppConfig();
        };
      },
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'fill' },
    },
    I18nService,
    Title
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private i18nService: I18nService) {}
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
