<div *ngFor="let choice of choices; let i = index">
  <app-fixed-live-candidate [index]="i" [choiceEvent]="choice"  [isPublic]="isPublic" [status]="liveChoiceEvents[i]"
  [isActive]="i === activeIndex" (sendCommand)="wsCommand($event)" (sendResults)=getResults($event)
  (deleteQuestionEvent)="deleteQuestion($event)" [round]="liveChoiceEvents[i].index" [hasActiveElection]="hasActiveElection">
  </app-fixed-live-candidate>
</div>
<div class="button-container">
  <button *ngIf="canAddQuestions" id="add-candidate-event" (click)="addEvent()">
    +&nbsp;&nbsp;&nbsp;&nbsp;{{'POLL-CREATION.MAIN.ADD-ELECTION' | translate}}
  </button>
</div>
<vc-added-candidate *ngIf="addRound" [index]="choices.length" [status]=0 (sendEvent)="addChoicedEvent($event)"
(sendAnswerImage)="sendImageEvent($event)" (linkEvent)="sendLink($event)">
</vc-added-candidate>
