import {Component, Input, Output, OnInit, EventEmitter, OnDestroy, OnChanges, SimpleChanges} from '@angular/core';
import { Result, ResolutionResult, ListResult } from '../../models/Poll';
import { ApiService } from '../../shared/api.service';
import { ReceivedPoll } from './../../models/ReceivedPoll';
import { environment } from './../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-results',
  templateUrl: 'result-holder.component.html',
  styleUrls: ['./result-holder.component.less', '../../shared/common.less', '../../poll-planned/poll-planned-timer/poll-planned-timer.component.less']
})

export class PollResultsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() pollId: string;
  @Input() displayIndex: number;
  @Input() assessorsValidated: boolean
  @Input() canResendAssessors: boolean
  @Input() hasAssessors: boolean
  @Input() pollData: ReceivedPoll;
  @Input() hasAssessorsButtons: boolean = true;
  @Input() remainingAssessorTime: string;
  @Output() sendAssessorsList = new EventEmitter<string>()
  @Output() sendAssessorsInvitation = new EventEmitter<string>();
  @Input() assessorCount: number = 0;
  @Input() inactiveAssessorCount: number = 0;
  title: string;
  isResolution = false;
  resolutionResults: Array<ResolutionResult> = [];
  listResults: Array<Array<ListResult>> = [];
  results: Result[] = [];
  tmpResults: Array<Result[]> = [];
  calculating = false;
  type: any;
  timer: any;
  blockchainLink: string;
  warningPopup: boolean
  isActive: boolean = true;
  assessorValidationPercentage = 0;
  @Input() endDateFormat: Date;

  constructor(
    private apiService: ApiService,
    private router: Router
    ) {
  }

  isActivePoll() {
    if (this.endDateFormat != undefined) {
      let todayDate = new Date()
      if (todayDate.getTime() > this.endDateFormat.getTime())
      this.isActive = false
    }
  }

  getElectionCalculated() {
    this.apiService.getResults(this.pollId).subscribe((res: Array<Array<number>>) => {
      if (Object.keys(res).length === 0){
        this.calculating = true;
        const self = this;
        this.timer = setTimeout(() => {
            self.getElectionCalculated();
          }, 15000);
      } else {
        this.calculating = false;
        for (const [round, result] of this.tmpResults.entries()) {
          const votes: number[] = res[round];
          const totalVotes = votes.reduce((a: number, b: number) => {
            return a + b;
          }, 0);
          let resultIndex = 0;
          for (const answer in result) {
            if (this.tmpResults[round].hasOwnProperty(answer)) {
              const participarionNumber = Math.round(res[round][resultIndex] / totalVotes * 100);
              this.tmpResults[round][answer].percentage = isNaN(participarionNumber) ? 0 : parseFloat((res[round][resultIndex] / totalVotes * 100).toFixed(2));
              this.tmpResults[round][answer].votes = res[round][resultIndex];
            }
            resultIndex++;
          }
          this.tmpResults[round].sort((a, b) => {
            if (a.percentage > b.percentage) { return -1; }
            if (a.percentage < b.percentage) { return 1; }
            return 0;
          });
        }
      }
    });
  }

  deletePopup() {
    this.warningPopup = true;
  }

  deletePoll() {
    this.apiService.deletePoll(this.pollId).subscribe(
      (res) => {
        this.router.navigateByUrl("/inscription");
      },
      (err) => {
        console.log(err);
      }
    );
  }

  dismissDeletePopup($event) {
    this.warningPopup = false;
    if ($event) { this.deletePoll(); }
  }

  listAssessors() {
    this.sendAssessorsList.emit()
  }

  relaunchAssessors() {
    this.sendAssessorsInvitation.emit()
  }

  getListCalculated() {
      this.apiService.getResults(this.pollId).subscribe((res: Array<Array<number>>) => {
        if (Object.keys(res).length === 0) {
          const self = this;
          this.timer = setTimeout(() => {
              self.getListCalculated();
            }, 15000);
        }
        else {
          let offset = 0;

          this.listResults.forEach((round) => {
              round.forEach((value, index) => {
                value.results = value.answers.map((x, i) => new Result(x, parseFloat((res[index + offset][i] / value.votes * 100).toFixed(2)), res[index + offset][i]));
              });
              offset += round.length;
          });
          this.calculating = false;
        }
      });
  }

  getResolutionCalculated() {
    this.apiService.getResults(this.pollId).subscribe((res: Array<Array<number>>) => {
      if (Object.keys(res).length === 0) {
        const self = this;
        this.calculating = true;
        this.timer = setTimeout(() => {
            self.getResolutionCalculated();
          }, 15000);
      }
      else {
        this.calculating = false;
        for (const roundIndex in res) {
          if (res.hasOwnProperty(roundIndex)) {
            let totalVotes = 0;
            const shouldHideNoAnswer = (this.type === 'resolution' && res[roundIndex].length === 4) ||
              (this.type === 'live' && res[roundIndex].length === 4);
            const limit = shouldHideNoAnswer ? res[roundIndex].length - 1 :  res[roundIndex].length;
            if (limit < res[roundIndex].length) {
              this.resolutionResults[roundIndex].answers.pop();
              this.resolutionResults[roundIndex].results.pop();
            }
            for (let j = 0; j < limit; j++) {
              totalVotes += res[roundIndex][j];
            }
            for (let j = 0; j < limit; j++) {
              if (totalVotes > 0) {
                this.resolutionResults[roundIndex].results[j].percentage = parseFloat((res[roundIndex][j] / totalVotes * 100).toFixed(2));
                this.resolutionResults[roundIndex].results[j].votes = res[roundIndex][j];
              } else {
                this.resolutionResults[roundIndex].results[j].percentage = 0;
                this.resolutionResults[roundIndex].results[j].votes = 0;
              }
            }
            this.resolutionResults[roundIndex].results.sort((a, b) => {
                if (a.percentage > b.percentage) { return -1; }
                if (a.percentage < b.percentage) { return 1; }
                return 0;
            });
          }
        }
      }
    });
  }

  initData() {
    this.type = this.pollData.type;
    if (this.pollData.type === 'planned' || (this.pollData.rounds.length === 1 && (this.pollData.type === '' || this.pollData.type === 'election'))) {
      const responseQuestions: Array<string[]>  = [];
      if (this.pollData.bc) {
        this.pollData.rounds[0].displayedQuestions.forEach(elem => {
          responseQuestions.push(elem.answers);
        });
      } else {
        this.pollData.rounds[0].questions.forEach(elem => {
          responseQuestions.push(elem.answers);
        });
      }
      if (responseQuestions != null) {
        this.tmpResults = responseQuestions.map(options => {
          return options.map(q => new Result(q, 0, 0))
        });
        this.getElectionCalculated();
      }
    }

    if (this.pollData.type === 'list') {
      this.calculating = true;
      this.listResults = this.pollData.rounds.map(r => r.displayedQuestions.map(x => new ListResult(x.question, x.answers, [], x.id, x.parent, r.votes)));
      this.getListCalculated();
    }
    const isLegacyResolution = this.pollData.rounds[0].questions.length > 1 && (this.pollData.type === '' || this.pollData.type === 'planned');
    const isLiveCandidate = this.pollData.type === 'liveCandidate' || (this.pollData.type === 'election' && (this.pollData.isLive || this.pollData.rounds.length > 1));
    if (this.pollData.type === 'resolution' || this.pollData.type === 'live' || isLiveCandidate || isLegacyResolution) {
      this.isResolution = true;
      this.resolutionResults = [];
      const displayedQuestionsArray = this.pollData.rounds.map((a) => a.displayedQuestions).flat();
      const questionsArray = this.pollData.rounds.map((a) => a.questions).flat();
      for (const questionIt of questionsArray) {
        const questionMask = displayedQuestionsArray.filter(x => x.question === questionIt.question)[0];
        const newQuestion = questionMask != null && questionMask !== undefined ? questionMask : questionIt;
        const responseQuestions: string[] = newQuestion.answers;
        const tempResults = responseQuestions.map(q => new Result(q, 0, 0));
        const tempResult = new ResolutionResult(questionIt.question, responseQuestions, tempResults);
        this.resolutionResults.push(tempResult);
      }
      this.getResolutionCalculated();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['pollData'] !== undefined && this.pollData !== null && this.pollData !== undefined) { this.initData(); }
    if (changes['inactiveAssessorCount'] && this.inactiveAssessorCount != undefined) {
      this.assessorValidationPercentage = Math.round((this.assessorCount - this.inactiveAssessorCount) / this.assessorCount * 100)
    }
    this.isActivePoll()
  }

  ngOnInit() {
    if (this.pollData !== null && this.pollData !== undefined) { this.initData(); }
    this.blockchainLink = environment.settings.validation + this.pollId;
  }

  ngOnDestroy() {
    clearTimeout(this.timer);
  }
}
