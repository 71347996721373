<div class="form-holder">

  <vc-poll-card-header [image]="'assets/resolution-icon.svg'" [title]="'POLL-CREATION.QUESTIONS.TITLE-LIVE-CANDIDATES' | translate" [description]="'POLL-CREATION.QUESTIONS.CANDIDATES-DESCRIPTION' | translate">
  </vc-poll-card-header>

  <div class="safe-area">

    <div class="line-holder">
      <h3 class="resolution-index-invisible"> </h3>
      <p type="text" class="title-input" readonly class="subject"
        [ngClass]="isValid == true ? 'valid-input' : 'invalid-input'">{{choiceEvent.question}}</p>
    </div>
    <div class="line-holder">
      <h3 class="resolution-index-invisible"> </h3>
      <p class="votes">{{'POLL-CREATION.QUESTIONS.VOTES' | translate }}{{choiceEvent.numberOfVote}}</p>
    </div>
    <div *ngFor="let candidateEvent of choiceEvent.answers; let i = index">
      <div class="line-holder">
        <h3 class="resolution-index">{{i+1}}</h3>
        <input type="text" class="name" placeholder="{{candidateEvent}}" readonly
          [ngClass]="isValid == true ? 'valid-input' : 'invalid-input'">
      </div>
    </div>
    <div class="button-line-holder">
      <vc-status-bar [status]="status.status"></vc-status-bar>
      <div *ngIf="status && status.status == 1" class="results-button-active" (click)="results()">
        <span class="results-active-text" style="margin-left: 8px; margin-right: 8px;">{{'LIVE.PARTICIPATION' | translate}}</span>
      </div>
      <button *ngIf="!status || status.status<1 && !hasActiveElection" type="button" class="start-button-valid"
        (click)="startResolution()">{{'LIVE.LAUNCH-ELECTION' | translate}}</button>

      <div *ngIf="status.status == 0" class="icon-container">
        <img id="delete-button" (click)="deleteQuestion()" src="assets/deletebuttonred.svg">
      </div>

      <button *ngIf="status && status.status == 1" type="button" class="start-button-valid" (click)="endResolution()">
        {{'LIVE.CLOSE' | translate}} </button>
      <div *ngIf="status && status.status == 2"
        class="results-button-active" (click)="results()">
        <img src="assets/results-blue.svg" class="results-image">
        <span class="results-active-text">{{'LIVE.RESULTS' | translate}}</span>
      </div>
    </div>
  </div>
  <div class="separator"></div>
</div>
