<div class="poll-item">
  <label class="resolution-label">
    <div class="number-holder">
      <div class="option-image-wrapper" id="image-wrapper-{{index}}">
        <img id="resolution-{{index}}" class="option-image" src="#" alt="{{resolution.name}}">
      </div>
      <div class="circle" id="wrapper-{{index}}">
        <span class="resolution-index" id="{{index + 1}}">{{index+1}}</span>
      </div>
    </div>
    <div class="poll-item-description-holder">
      <span class="poll-item-question">{{resolution.name}}</span>
    </div>
    <div>
      <img src="assets/fleche_resolution_orange.svg" class="helper-image">
    </div>
  </label>
</div>
