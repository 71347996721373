<div class="poll-item">
  <label>
    <div *ngIf="option.image == undefined" class="number-holder">
      <div [ngClass]="['circle' + ' circle-' + index]" id="{{'circle-' + index}}">
        <span class="resolution-index" id="{{index + 1}}">{{index+1}}</span>
      </div>
    </div>
    <img *ngIf="option.image != undefined && option.image.length > 2" src="{{option.image}}" class="option-image">
    <div *ngIf="option.link != undefined && option.link.length > 2" class="poll-item-description-holder">
      <span class="poll-item-question">
        <a href="{{common.replaceURLHref(option.link)}}" target="_blank">{{option.name === '' ? '?' : option.name}}</a>
      </span>
    </div>
    <div *ngIf="option.link == undefined" class="poll-item-description-holder">
      <span class="poll-item-question" id="poll-item-question-{{index}}">
        {{option.name}}
      </span>
    </div>
    <div [ngClass]="['checkbox' + ' checkbox-' + index]" >
      <input class="box" type="checkbox" id="{{'checkbox-' + index}}" (click)="actionSelect(index)">
    </div>
  </label>
</div>
