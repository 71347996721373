import {Component, OnInit, Input, Output, EventEmitter, AfterViewInit, OnChanges, SimpleChanges} from '@angular/core';
import {PollChoice, EditedPollChoice} from '../../../models/PollChoice';
import { Common } from "../../../shared/common";
import { ref } from 'firebase/storage';
import { storage } from 'src/app/firebase';
import { v4 } from "uuid";

@Component({
  selector: 'vc-input-candidate',
  templateUrl: 'input-candidate.component.html',
  styleUrls: ['./input-candidate.component.less']
})

export class InputCandidateComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() index: number
  @Input() parentIdentifier: number
  @Input() choice: PollChoice
  @Input() inputPlaceholder: PollChoice
  @Output() sendPollChoice = new EventEmitter<EditedPollChoice>()
  @Output() deleteEvent = new EventEmitter<number>()
  @Output() linkEvent = new EventEmitter<[number, string]>()
  @Output() sendAnswerImage = new EventEmitter<Array<any>>();
  @Output() sendDeselect = new EventEmitter<boolean>();
  editedObject: EditedPollChoice;
  resolutionCharacters: number = 0;
  blueLinkIcon: boolean = false;
  displayImageError: boolean = false;

  constructor(public common: Common) {
  }

  onNameInput(event) {
    this.editedObject.choice = JSON.parse(JSON.stringify(this.editedObject.choice));
    this.editedObject.choice.name = event.target.value;
    this.sendPollChoice.emit(this.editedObject);
    this.resolutionCharacters = event.target.value.length;
  }

  onArgumentInput(event) {
    this.editedObject.choice.description = event.target.value
    this.sendPollChoice.emit(this.editedObject)
  }

  delete() {
    this.deleteEvent.emit(this.index)
  }

  ngAfterViewInit() {
    if (this.choice.name !== undefined) {
      let candidate = document.getElementById(`${this.parentIdentifier}-candidate-${this.index}`)
      candidate.style.height = candidate.scrollHeight - 10 + "px";
    }
  }

  getInput() {
    let floatingInput = document.getElementById(`input-${this.parentIdentifier}-${this.index}`)
    floatingInput.style.display = 'block'
    floatingInput.focus()
  }

  ngOnInit() {
    if (this.choice != undefined) {
      this.editedObject = new EditedPollChoice(this.index, this.choice);
      if (this.choice.name != undefined) {
        this.resolutionCharacters = this.choice.name.length;
      }
      if(this.choice.link !== null && this.choice.link !== undefined) {
        this.blueLinkIcon = true;
        setTimeout(() => {
          let floatingInput = document.getElementById(`input-${this.parentIdentifier}-${this.index}`) as HTMLInputElement;
          floatingInput.value = this.choice.link;
        }, 500)
      }

      if(this.choice.image !== null && this.choice.image !== undefined) {
        setTimeout(() => {
          let displayImage = document.getElementById(`uploaded-${this.parentIdentifier}-${this.index}`) as HTMLImageElement
          let wrapper = document.getElementById(`uploaded-wrapper-${this.parentIdentifier}-${this.index}`) as HTMLImageElement
          let candidateIndex = document.getElementById(`candidate-index-${this.parentIdentifier}-${this.index}`) as HTMLImageElement
          candidateIndex.style.display = 'none';
          wrapper.style.display = 'flex';
          displayImage.src = this.choice.image;
        }, 500)
      }
    }
  }

  onFileChanged(event) {
    let displayImage = document.getElementById(`uploaded-${this.parentIdentifier}-${this.index}`) as HTMLImageElement
    let wrapper = document.getElementById(`uploaded-wrapper-${this.parentIdentifier}-${this.index}`) as HTMLImageElement
    let candidateIndex = document.getElementById(`candidate-index-${this.parentIdentifier}-${this.index}`) as HTMLImageElement

    var reader = new FileReader()
    const extensionImage = event[0].name.substring(event[0].name.lastIndexOf('.'));

    const imageRef = ref(storage, `${v4()}${extensionImage}`)
    const imageBlob = new Blob(event)

    reader.readAsDataURL(imageBlob)

    if (event[0].size > 2097152)
      this.displayImageError = true


    var image = new Image();
    var objectUrl = URL.createObjectURL(event[0]);
    image.onload = function () {
      if (image.width != image.height)
        this.displayImageError = true
      if (this.displayImageError == false) {
        wrapper.style.display = "flex"
        candidateIndex.style.display = "none"
        displayImage.src = URL.createObjectURL(imageBlob)
        this.sendAnswerImage.emit([extensionImage, imageBlob, this.index])
      }
      URL.revokeObjectURL(objectUrl);
    }.bind(this, event);
    image.src = objectUrl;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.choice != undefined) {
      if (this.choice != undefined && this.choice.image != undefined && this.editedObject != undefined) {
        this.editedObject.choice.image = this.choice.image;
        this.editedObject.choice.link = this.choice.link;
      }
    }
  }

  dismissPayPopup() {
    this.displayImageError = false
  }

  markDeselect() { this.sendDeselect.emit(true); }

  closeInput() {
    let floatingInput = document.getElementById(`input-${this.parentIdentifier}-${this.index}`) as HTMLInputElement
    floatingInput.style.display = 'none'
    if (floatingInput.value != undefined && floatingInput.value.length > 0) {
      const link = floatingInput.value.trim()

      if (link.length > 0) {
        this.blueLinkIcon = true
      }
    } else {
      this.blueLinkIcon = false
    }
    this.linkEvent.emit([this.index, floatingInput.value])
  }
}
