import { Component, Input, Output, EventEmitter } from '@angular/core';
import {Common} from '../../shared/common';
@Component({
  selector: 'vc-poll-ongoing-summary',
  templateUrl: 'poll-ongoing-summary.component.html',
  styleUrls: ['./poll-ongoing-summary.component.less', '../../shared/common.less']
})

export class PollOngoingSummaryComponent {
  @Input() description: string;
  @Input() sharedLinks: string;
  @Input() startDate: string;
  @Input() endDate: string;
  @Input() votersCount: number;
  @Input() votersVoted: number;
  @Input() participation: string;
  @Input() isLive: boolean;
  @Input() isPublic: boolean;
  @Input() isSms: boolean;
  @Input() is2FA: boolean;
  @Input() type: string;
  @Output() return = new EventEmitter<boolean>();
  @Output() refreshDataEvent = new EventEmitter<boolean>();

  constructor(
    public common: Common
  ) {
  }

  rejoin() {
    this.return.emit(true)
  }

  refresh() {
    this.refreshDataEvent.emit(true)
  }
}
