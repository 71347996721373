<img *ngIf="poll && poll.imageId == ''" src="assets/v8te.png" class="logo">
<img *ngIf="poll && poll.imageId.length > 1" src="{{poll.imageId}}" class="logo-image">
<div *ngIf="poll" class="summary">
  <h3 class="question">{{poll.title}}</h3>
  <div class="secret-container">
    <img src="assets/info_icon.svg" alt="alert" class="icon">
    <p *ngIf="poll.isUncrypted" class="is-secret">{{'POLL-PREVIEW.PUBLIC' | translate}}</p>
    <p *ngIf="!poll.isUncrypted" class="is-secret">{{'POLL-PREVIEW.SECRET' | translate}}</p>
  </div>
  <div class="firstLine" *ngIf="poll.description != ' '">
    <p class="description-long" [innerHTML] = "common.replaceURLs(poll.description)"></p>
    <div *ngIf="poll.sharedLinks != null && poll.sharedLinks != ''">
      <p class="description-black">
        <span class="document-span">{{'POLL-PREVIEW.LINK' | translate}}</span><a [innerHTML]="common.replaceURLs(poll.sharedLinks)" style="display: block; overflow: hidden; text-overflow: ellipsis;">{{poll.sharedLinks}}</a>
      </p>
    </div>
  </div>
  <div class="infos-holder">
    <div *ngIf="poll.isUncrypted" class="holder-voters">
      <circle-progress id="attendance" [percent]="0" [radius]="21" [outerStrokeWidth]="4"
                       [innerStrokeWidth]="4" [space]="-4" [outerStrokeColor]="'#1890FF'" [innerStrokeColor]="'#e7e8ea'"
                       [titleFontSize]="'14'" [titleColor]="'#1890FF'" [unitsColor]="'#1890FF'" [unitsFontSize]="'14'"
                       [showSubtitle]="false" [animation]="true" [animationDuration]="300" [startFromZero]="false">
      </circle-progress>
      <div class="descriptions-holder">
        <h3 class="info-black">0</h3>
        <h3 class="info-grey">sur {{poll.voters.length}}</h3>
      </div>
    </div>
    <div class="holder-voters" id="left-holder">
      <img class="calendar-image" src="assets/calendar.svg">
      <div class="descriptions-holder">
        <h3 class="info-grey">{{'POLL-PREVIEW.CLOSING' | translate}}</h3>
        <h3 class="info-black">{{getDateString()}}</h3>
      </div>
    </div>
  </div>
  <div class="separator"></div>
  <app-questions-preview *ngIf="poll != null" [poll]="poll">
  </app-questions-preview>
</div>
