<div class="top-holder">
  <div class="title"><img src="assets/arrow-left.svg" class="backCandidates" (click)="back()">Votes
  </div>
  <span class="resolutions-participation" style="margin-top: 0">
  <span class="participation-label">{{getSelectionCounter()}}/{{liveQuestion.numberOfVote}}</span>
    <progress max="100" value='{{(getSelectionCounter() / liveQuestion.numberOfVote) * 100}}'> 70% </progress>
  </span>
</div>
<div class="resolutions-holder">
  <div class="top-holder">
    <h3 class="resolutions-header">{{liveQuestion.title}}</h3>
  </div>
  <app-candidates-item *ngFor="let option of choices, let i = index" [option]="option" [index]="i" (sendSelection)="getSelection(i)"></app-candidates-item>
</div>
<div *ngIf="liveQuestion.numberOfVote > 1" id="snackbar">
  <div class="snackbar-wrapper">
    <p style="margin:0px">{{'POLL-PREVIEW.PLURINOMINAL-VOTE-LIMIT' | translate}} {{liveQuestion.numberOfVote}} {{'POLL-PREVIEW.PLURINOMINAL-VOTES' | translate}}</p>
  </div>
</div>
