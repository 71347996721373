import { Input, Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { AuthenticationService } from '../../shared/authentication.service';
import { LicenseService } from '../../shared/licens.service';
import { environment } from '../../../environments/environment';
import { LicenseData } from '../../models/LicenseData';
import {Common} from '../../shared/common';

@Component({
  selector: 'vc-poll-pages-header',
  templateUrl: 'poll-pages-header.component.html',
  styleUrls: ['./poll-pages-header.component.less', '../../shared/common.less'],
})

export class PollPagesHeader implements OnInit {
  @Input() title: string;
  @Input() shouldReturnToPoll = false;
  @Input() pollId: string;
  @Input() isLive: boolean;
  @Input() isPublic: string;
  @Input() SMSOption: boolean;
  @Input() is2FA: boolean;
  @Input() isAssessors = false;
  maxVoters = 5;
  shoulDisplayChangePopup: boolean;
  smsAdditionalOption: boolean;
  licencesReady = false;
  isAssoc: boolean;
  licenseTime: string;
  hasHotline: boolean;
  clientType = 'assoc';
  eventCounter = 0;
  accountName = '';
  hotlinePopup: boolean;
  stripeConstants = environment.stripeConstants;
  stripe: stripe.Stripe;
  selectedOffer: number;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    public licenseService: LicenseService,
    private common: Common,
    private route: ActivatedRoute
    ) {}

  navigateHome() {
    this.router.navigate(['/polls']);
  }

  navigateArrow() {
    this.shouldReturnToPoll ? this.router.navigate(['/poll-ongoing/' + this.pollId]) : this.getReturnAddress();
  }

  getReturnAddress() {
    if (this.route.snapshot.url.length > 0 && this.route.snapshot.queryParams.resolution !== undefined) {
      if (this.route.snapshot.url[0].path === 'poll-ongoing' || this.route.snapshot.url[0].path === 'poll-result') {
        if (this.pollId === undefined) { this.pollId = this.route.snapshot.params.id; }
        this.router.navigate(['/poll-live/' + this.pollId]);
      }
    } else {
      this.router.navigate(['/polls']);
    }
  }

  ngOnInit(): void {
    this.licenseService.license.subscribe((res: LicenseData) => {
      this.licencesReady = true;
      this.hasHotline = res.hasHotline;
      this.maxVoters = res.votes;
      this.eventCounter = res.events;
      if (res.client !== null) { this.isAssoc = !(res.client !== 'corp'); }
      this.clientType = res.client;
      this.licenseTime = this.common.licenseResponseGetTime(res);
      this.accountName = this.authenticationService.getUserData();
    });
    this.stripe = Stripe(this.stripeConstants.key);
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/connexion']);
  }

  changeSubscription() {
    this.shoulDisplayChangePopup = true;
    this.smsAdditionalOption = true;
  }

  triggerHotlinePopup() {
    this.hotlinePopup = true;
  }

  dismissInitialPopup() {
    this.hotlinePopup = false;
    this.shoulDisplayChangePopup = false;
    this.smsAdditionalOption = false;
  }

  goToProfile() {
    this.router.navigate(['/profile']);
  }

  dismissChangePopup() {
    window.location.reload();
  }

  setSMSOption($event: number) {
    this.licenseService.setSMSOption($event, this.selectedOffer, this.isAssoc, this.dismissChangePopup);
  }

}
