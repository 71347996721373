import { createReducer, MetaReducer, ActionReducer, on, Action } from "@ngrx/store"
import {
  creationAction,
  setDescriptionAction,
  setDocumentAction,
  setPublicVoteAction,
  setSMSAction,
  setSenderAction,
  setTitleAction,
  setVotingTypeAction,
  set2FAAction,
  setTemporalityAction,
  setStartDateAction,
  setStartTimeAction,
  setEndDateAction,
  setEndTimeAction,
  setVotersAction,
  setResolutionsAction,
  setActivateAssessorsAction,
  setAssessorsNamesAction,
  setAssessorsMailsAction,
  setLiveQuestionAction,
  setNumberOfVoteAction,
  setChoicesAction,
  resetAction,
  setDraftFlagAction, setPollImageAction
} from './creationActions';
import { LiveQuestion, PollChoice, Resolution } from "../models/PollChoice";

export interface CreationState {
    poll: {
        draftSaved: boolean,
        temporality: string,
        votingType: string,
        title: string,
        description: string,
        sharedLinks: string,
        sender: string,
        startDate: string,
        startTime: string,
        endDate: string,
        endTime: string,
        voters: {
        },
        resolutions: Array<Resolution>,
        isUncrypted: boolean,
        isSMS: boolean,
        is2FA: boolean,
        isAssessors: boolean,
        assessorsNames: Array<string>,
        assessorsMails: Array<string>,
        liveQuestions: Array<LiveQuestion>,
        choices: Array<Array<PollChoice>>,
        imageId: string
    };
}

export const initialState: CreationState = {
    poll: {
        draftSaved: false,
        temporality: "live",
        votingType: "resolution",
        title: "",
        description: "",
        sharedLinks: "",
        sender: "",
        startDate: "",
        startTime: "",
        endDate: "",
        endTime: "",
        voters: [],
        resolutions: [],
        isUncrypted: false,
        isSMS: false,
        is2FA: false,
        isAssessors: false,
        assessorsNames: ["", "", ""],
        assessorsMails: ["", "", ""],
        liveQuestions: [],
        choices: [],
        imageId: ''
    }
}

function creationLog(reducer: ActionReducer<CreationState, Action>) : ActionReducer<CreationState, Action> {
    return (state, action) => {
        const currentState = reducer(state, action);
        return currentState
    }

}

export const metaReducer: MetaReducer = creationLog

export const creationReducer = createReducer(initialState,
    on(creationAction, (state) => {
        return  {
            ...state
        }
    }),
    on(resetAction, () => initialState),
    on(setVotingTypeAction, (state, props) => {
        if (props.votingType === 'resolution') {
            return {
                ...state,
                poll: {
                    ...state.poll,
                    votingType: props.votingType,
                    isAssessors: false
                }
            };
        } else {
            return {
                ...state,
                poll: {
                    ...state.poll,
                    votingType: props.votingType
                }
            };
        }
    }),
    on(setTitleAction, (state, props) => {
        return {
            ...state,
            poll: {
                ...state.poll,
                title: props.title
            }
        }
    }),
    on(setDescriptionAction, (state, props) => {
        return {
            ...state,
            poll: {
                ...state.poll,
                description: props.description
            }
        }
    }),
    on(setDocumentAction, (state, props) => {
        return {
            ...state,
            poll: {
                ...state.poll,
                sharedLinks: props.sharedLinks
            }
        }
    }),
    on(setSenderAction, (state, props) => {
        return {
            ...state,
            poll: {
                ...state.poll,
                sender: props.sender
            }
        }
    }),
    on(setPollImageAction, (state, props) => {
      return {
        ...state,
        poll: {
          ...state.poll,
          imageId: props.imageId
        }
      }
    }),
    on(setPublicVoteAction, (state, props) => {
        return {
            ...state,
            poll: {
                ...state.poll,
                isUncrypted: props.isUncrypted
            }
        };
    }),
    on(setSMSAction, (state, props) => {
        return {
            ...state,
            poll: {
                ...state.poll,
                isSMS: props.isSMS
            }
        };
    }),
    on(set2FAAction, (state, props) => {
        return {
            ...state,
            poll: {
                ...state.poll,
                is2FA: props.is2FA,
                isSMS: false
            }
        }
    }),
    on(setTemporalityAction, (state, { temporality }) => ({
        ...state,
        poll: {
            ...state.poll,
            temporality,
        },
    })),
    // on(setTemporalityAction, (state, props) => {
    //     return {
    //         ...state,
    //         poll: {
    //             ...state.poll,
    //             draftSaved: false,
    //             temporality: props.temporality,
    //         }
    //     }
    // }),
    on(setStartDateAction, (state, props) => {
        return {
            ...state,
            poll: {
                ...state.poll,
                startDate: props.startDate,
            }
        }
    }),
    on(setStartTimeAction, (state, props) => {
        return {
            ...state,
            poll: {
                ...state.poll,
                startTime: props.startTime,
            }
        }
    }),
    on(setEndDateAction, (state, props) => {
        return {
            ...state,
            poll: {
                ...state.poll,
                endDate: props.endDate,
            }
        }
    }),
    on(setEndTimeAction, (state, props) => {
        return {
            ...state,
            poll: {
                ...state.poll,
                endTime: props.endTime,
            }
        }
    }),
    on(setVotersAction, (state, props) => {
        return {
            ...state,
            poll: {
                ...state.poll,
                    voters: props.voters,
            }
        }
    }),
    on(setActivateAssessorsAction, (state, props) => {
        return {
            ...state,
            poll: {
                ...state.poll,
                    isAssessors: props.isAssessors,
            }
        };
    }),
    on(setAssessorsNamesAction, (state, props) => {
        return {
            ...state,
            poll: {
                ...state.poll,
                    assessorsNames: props.assessorsNames,
            }
        }
    }),
    on(setAssessorsMailsAction, (state, props) => {
        return {
            ...state,
            poll: {
                ...state.poll,
                    assessorsMails: props.assessorsMails,
            }
        }
    }),
    on(setLiveQuestionAction, (state, props) => {
        return {
            ...state,
            poll: {
                ...state.poll,
                liveQuestions: props.liveQuestions
            }
        }
    }),
    on(setResolutionsAction, (state, props) => {
        return {
            ...state,
            poll: {
                ...state.poll,
                resolutions: props.resolutions,
            }
        }
    }),
    on(setChoicesAction, (state, props) => {
        return {
            ...state,
            poll: {
                ...state.poll,
                choices: props.choices,
            }
        }
    }),
    on(setNumberOfVoteAction, (state, props) => {
        return {
            ...state,
            poll: {
                ...state.poll,
                liveQuestions: {
                    ...state.poll.liveQuestions,
                    numberOfVote: props.numberOfVote,
                }
            }
        }
    }),
    on(setDraftFlagAction, (state, props) => {
        return {
            ...state,
            poll: {
                ...state.poll,
                    draftSaved: props.draftSaved,
            }
        }
    })
    )
