import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from '../shared/authentication.service';
import {Router} from '@angular/router';
import {LicenseData} from '../models/LicenseData';
import {LicenseService} from '../shared/licens.service';
import {ApiService} from '../shared/api.service';
import {Common} from '../shared/common';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.less', '../poll-creation/poll-creation.component.less', '../shared/common.less']
})
export class ProfileComponent implements OnInit {
  accountName: string;
  maxVoters = 10;
  licenseTime: string;
  eventCounter = 0;
  hasHotline: any;
  status = 0;
  invoices: Array<string>;
  offer: string;
  shouldDisplayDeletionWarning = false;
  shouldDisplayPasswordMessage = false;
  shouldDisplayMailMessage = false;
  constructor(
    public authenticationService: AuthenticationService,
    private licenseService: LicenseService,
    private router: Router,
    private apiService: ApiService,
    private common: Common
  ) { }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/connexion']);
  }

  backArrow() {
    this.router.navigate(['/polls/']);
  }

  shouldDisplayChangePassword() { return !this.authenticationService.isSocialUser(); }

  ngOnInit(): void {
    this.licenseService.license.subscribe((res: LicenseData) => {
      this.hasHotline = res.hasHotline;
      this.maxVoters = res.votes;
      this.eventCounter = res.events;
      switch (res.duration) {
        case 'Y': {
          this.licenseTime = 'Y';
          break;
        }
        case 'DM': {
          this.licenseTime = 'DM';
          break;
        }
        default:
          this.licenseTime = 'D';
      }
      this.accountName = this.authenticationService.getUserData();
      if (this.maxVoters <= 5) {
        switch (this.common.getLangCode()) {
          case 'fr': { this.offer = 'Utilisation illimitée jusqu\'à 5 votants, SMS inclus.'; break; }
          case 'es': { this.offer = 'Uso ilimitado hasta 5 votantes, SMS incluidos.'; break; }
          default: this.offer = 'Unlimited use up to 5 voters, SMS included.';
        }
      } else {
        if (res.duration !== 'Y') {
          switch (this.common.getLangCode()) {
            case 'fr': { this.offer = `${res.events} evenements restants jusqu\'à ${res.votes} votants,\n\n valide jusqu'au ${res.endDate.toLocaleString()} \n\n ${res.sms} SMS inclus.`; break; }
            case 'es': { this.offer = `${res.events} eventos restantes hasta ${res.votes} votantes,\n\n válido hasta ${res.endDate.toLocaleString()} \n\n ${res.sms} SMS incluidos.`; break; }
            default: this.offer = `${res.events} events remaining up to ${res.votes} voters,\n\n valid until ${new Date(res.endDate).toLocaleString()} \n\n ${res.sms} SMS inclus.`;
          }
        } else {
          switch (this.common.getLangCode()) {
            case 'fr': { this.offer = `scrutins illimitées jusqu\'à ${res.votes} votants,\n\n valide jusqu'au ${res.endDate.toLocaleString()} \n\n ${res.sms} SMS inclus.`; break; }
            case 'es': { this.offer = `escrutinios ilimitados hasta ${res.votes} votantes,\n\n válido hasta ${res.endDate.toLocaleString()} \n\n ${res.sms} SMS incluidos.`; break; }
            default: this.offer = `Unlimited polls up to ${res.votes} voters,\n\n valid until ${new Date(res.endDate).toLocaleString()} \n\n ${res.sms} SMS inclus.`;
          }
        }
      }
    });
    this.apiService.getInvoices().subscribe((res: Array<string>) => {
      this.invoices = res;
    });
  }

  setStatus(status: number) {
    this.status = status;
  }

  changeEmail() {
    const newEmailField = document.getElementById('email-new') as HTMLTextAreaElement;
    const newEmail = newEmailField.value;
    const newEmailConfirmField = document.getElementById('email-new') as HTMLTextAreaElement;
    const newEmailConfirm = newEmailConfirmField.value;
    if (this.common.validateEmail(newEmail) && newEmail === newEmailConfirm) {
      this.apiService.changeEmail(newEmail).subscribe((res: Array<string>) => {
      });
    }
  }

  displayDeleteWarning() {
    this.shouldDisplayDeletionWarning = true;
  }

  displayPasswordAnnouncement() {
    this.changePassword();
    this.shouldDisplayPasswordMessage = true;
  }

  displayMailChangeAnnouncement() {
    this.changeEmail();
    this.shouldDisplayMailMessage = true;
  }

  deleteAccount(deletion: boolean) {
    this.shouldDisplayDeletionWarning = false;
    if (deletion) {
       this.apiService.deleteUser().subscribe(res => {
        this.logout();
       });
    }
  }

  dismissPassChangePopups() {
    this.shouldDisplayPasswordMessage = false;
  }

  dismissMailChangePopups() {
    this.shouldDisplayMailMessage = false;
  }

  changePassword() {
    this.authenticationService.changePassword(this.accountName);
  }

}
