<div class="poll-holder" (click)="openPoll($event)">
  <div *ngIf="column == 3" class="status-finished"></div>
  <div *ngIf="column == 2" class="status-ongoing"></div>
  <div *ngIf="column == 1" class="status-planned"></div>
  <h5 class="item-title">{{pollData.title}}</h5>
  <div class="calendar-holder">
    <img src="assets/calendar-small.svg" height="14" width="14" id="voters-icon">
    <h5 class="date">
      {{startDate}} - {{endDate}}
    </h5>
  </div>
  <div *ngIf="column !== 2" class="button-holder">
    <img src="assets/clone.svg" data-cy="clone_button" (click)="cloneAction()" title="{{'HOME.GENERAL.CLONE-BUTTON' | translate}}" class="clone-icon">
    <img *ngIf="column === 1 && super" src="assets/warning.svg" (click)="exportAction()" class="export-icon">
    <img *ngIf="!hideEditButton" src="assets/edit_icon.svg" (click)="editPopup()" height="20" width="20" id="edit-icon">
    <img src="assets/delete_icon.svg" data-cy="delete_button" (click)="deletePopup()" height="20" width="20" id="delete-icon"
    title="{{'HOME.GENERAL.DELETE-BUTTON' | translate}}">
  </div>
  <div *ngIf="column ===2" class="button-holder">
    <img src="assets/clone.svg" data-cy="clone_button" (click)="cloneAction()" height="20" width="20" class="clone-icon">
    <img *ngIf="super" src="assets/warning.svg" (click)="exportAction()" class="export-icon">
  </div>
</div>
<vc-warning-popup *ngIf="warningPopup" title="{{'POLL-DELETION.COMPLETE' | translate}}" [deleteButton]="true" [cancelButton]="true" (endDetailsPopup)="dismissDeletePopup($event)" [separator]="false">
</vc-warning-popup>
