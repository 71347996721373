import { Component, Input, OnInit } from '@angular/core';
import { Result } from '../../../models/Poll';

@Component({
  selector: 'vc-poll-results-list',
  templateUrl: './poll-results-list.component.html',
  styleUrls: ['./poll-results-list.component.less'],
  styles: [`
    ngb-progressbar {
      margin-top: 5rem;
    }
  `]
})
export class PollResultsListComponent {
  @Input() results: Result[];
  @Input() question: string;
  @Input() index: number;
  show = true;



  constructor() {
  }


  seeMore() {
    this.show = false;
  }

  seeLess() {
    this.show = true;
  }

  ngAfterViewChecked() {
  }

  ngOnInit() {
  }

}
