import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import * as xlsx from 'xlsx';
import {Voter} from '../../models/Voter';
import {Poll} from '../../models/Poll';
import {Common} from 'src/app/shared/common';
import {ImportMessage, ImportStatuses} from '../../models/VoterImportUtils';
import { Store } from '@ngrx/store';
import { set2FAAction, setSMSAction, setVotersAction } from 'src/app/states/creationActions';

@Component({
  selector: 'vc-voters-list',
  templateUrl: 'voters-list.component.html',
  styleUrls: ['./voters-list.component.less'],
})
export class VotersListComponent implements OnInit {
  file: any;
  arrayBuffer: any;
  worksheet: any;
  invalidVoters: Array<Voter> = [];
  votersCount = 0;
  votersCountString = '0';
  isVotersValid = false;
  isExcelUpload = true;
  excelVoters: Array<Voter> = [];
  manualVoters: Array<Voter> = [];
  voters: Array<Voter> = [];
  @Input() editVoters: Array<Voter> = [];
  @Input() isSMS = false;
  @Input() is2FA = false;
  @Input() isUncrypted = false;
  @Input() shouldResume: boolean;
  @Output() sendEmailError = new EventEmitter<boolean>();
  @Output() sendVotersList = new EventEmitter<ImportMessage>();
  @Output() viewVoters = new EventEmitter<boolean>();
  @Output() isOutdatedFile = new EventEmitter<boolean>();

  constructor(
    private common: Common,
    private store: Store
    ) {}

  onFileChanged(event: { target: { files: any[] } }) {
    this.resetVoters();
    this.invalidVoters = [];
    if (event.target.files && event.target.files[0]) {
      this.file = event.target.files[0];
      this.fileReader(this.file, Voter);
    }
    this.excelVoters = this.voters;
  }

  onFileDropped(event: any[]) {
    if (event && event[0]) {
      this.file = event[0];
      this.fileReader(this.file, Voter);
    }
    this.excelVoters = this.voters;
  }

  openModel() {
    const langKey = 'userLanguage';
    const userLang = navigator.language || navigator[langKey];
    const language = userLang.substring(0, 2);
    if (this.common.checkIfSafari()) {
      const link = document.createElement('a');
      switch (language) {
        case 'fr': {
            link.download = 'Fichier votants V8TE.zip';
            link.href = 'assets/Fichier votants V8TE.zip';
            break;
        }
        case 'es': {
            link.download = 'Archivo de votantes V8TE.zip';
            link.href = 'assets/Archivo de votantes V8TE.zip';
            break;
        }
        default: {
            link.download = 'V8TE voters file.zip';
            link.href = 'assets/V8TE voters file.zip';
            break;
        }
      }
      link.click();
    } else {
      const link = document.createElement('a');
      const guideLink = document.createElement('a');
      switch (language) {
        case 'fr': {
            link.download = 'Fichier votants V8TE.xlsx';
            link.href = 'assets/Nouveau Fichier Votants V8TE.xlsx';
            guideLink.download = 'Mode d\'utilisation - Fichier Modèle liste votants.pdf';
            guideLink.href = 'assets/Mode d\'utilisation - Fichier Modèle liste votants.pdf';
            break;
        }
        case 'es': {
            link.download = 'Archivo de votantes V8TE.xlsx';
            link.href = 'assets/Nuevo Archivo de votantes V8TE.xlsx';
            guideLink.download = 'Como usar el archivo excel.pdf';
            guideLink.href = 'assets/Como usar el archivo excel.pdf';
            break;
        }
        default: {
            link.download = 'V8TE voters file.xlsx';
            link.href = 'assets/New Voters File V8TE.xlsx';
            guideLink.download = 'How to use - Voters List Template File.pdf';
            guideLink.href = 'assets/How to use - Voters List Template File.pdf';
            break;
        }
      }
      link.click();
      guideLink.click();
    }
  }

  setSMS(event: { target: { checked: boolean } }) {
    this.store.dispatch(setSMSAction({ isSMS: event.target.checked }));
    if (this.isSMS && !this.isExcelUpload) {
      this.isExcelUpload = true;
      const importButton = document.getElementById('left-toggle-button');
      importButton.click();
    }
    this.invalidVoters = [];
    this.sendVotersList.emit(ImportMessage.fromData(this.invalidVoters, ImportStatuses.errorSms));
    this.resetVoters();
    this.voters = this.excelVoters;
    this.validateVoters();
    if (this.invalidVoters.length === 0) {
      this.validatePonderations();
      if ((this.isSMS || this.is2FA) && this.invalidVoters.length === 0) {
        this.validateSMS();
      }
    }
  }

  set2FA(event: { target: { checked: boolean } }) {
    const buttonElement = document.getElementById('sms-button');
    const activeElement = document.getElementById('span-mail');
    if (activeElement !== null) {
      if (activeElement.className.localeCompare('checkbox-label-left-gray') == 0) {
        buttonElement.click();
      }
    }
    this.store.dispatch(set2FAAction({ is2FA: event.target.checked }));
    if (this.is2FA && !this.isExcelUpload) {
      this.isExcelUpload = true;
      const importButton = document.getElementById('left-toggle-button');
      importButton.click();
    }
    if (this.voters.length > 0) {
      this.validateSMS();
    }
    this.invalidVoters = [];
    this.voters = [];
  }

  trackVoter(index: number) {
    return index;
  }

  onMailInput($event: { target: { value: string } }, i: string | number) {
    const copyVoter: Voter = structuredClone(this.voters[i]);
    copyVoter.email = $event.target.value;
    const copyVoters = structuredClone(this.voters);
    copyVoters[i] = copyVoter;
    this.store.dispatch(setVotersAction({ voters : copyVoters }));
    this.validateLiveVoters();
    this.manualVoters = structuredClone(copyVoters);
  }

  deleteVoter(i: number) {
    this.voters.splice(i, 1);
    this.votersCount = this.voters.length;
    this.validateLiveVoters();
  }

  addVoter() {
    const newVoter = new Voter('New', 'Voter', '', null, null, null, 'fr');
    this.voters = [...this.voters, newVoter];
    this.votersCount = this.voters.length;
    this.votersCountString = this.votersCount.toLocaleString('fr-FR', {
      maximumFractionDigits: 2,
    });
  }

  changed($event: any): void {
    this.isExcelUpload = $event.value === 'true';
    const click2FA = document.getElementById('2fa-button');
    this.voters = [];
    this.isVotersValid = false;
    if (!this.isExcelUpload) {
      if (this.is2FA) {
        click2FA.click();
      }
      if (this.isSMS) {
        const smsButton = document.getElementById('sms-button');
        smsButton.click();
      }
      if (this.manualVoters.length === 0) {
        const newVoter = new Voter('New', 'Voter', '', null, null, null, 'fr');
        this.voters.push(newVoter);
      } else {
        this.voters = this.manualVoters;
        this.sendVotersList.emit(ImportMessage.fromData(this.voters, ImportStatuses.responseOk));
      }
    } else {
      this.voters = this.excelVoters;
      if (this.manualVoters.length == 0) this.validateVoters();
      this.sendVotersList.emit(ImportMessage.fromData(this.voters, ImportStatuses.responseOk));
    }
    this.votersCount = this.voters.length;
    this.votersCountString = this.votersCount.toLocaleString('fr-FR', {
      maximumFractionDigits: 2,
    });
  }

  private fileReader(file: any, line: any) {
    const fileReader = new FileReader();
    fileReader.onload = (_e) => {
      this.arrayBuffer = fileReader.result;
      const data = new Uint8Array(this.arrayBuffer);
      const arr = [];
      for (let i = 0; i !== data.length; i++) {
        arr[i] = String.fromCharCode(data[i]);
      }

      const bstr = arr.join('');
      const workbook = xlsx.read(bstr, { type: 'binary', cellDates: true });
      const firstSheetName = workbook.SheetNames[0];

      const worksheet = workbook.Sheets[firstSheetName];
      this.worksheet = xlsx.utils.sheet_to_json(worksheet, { raw: true });
      this.matchingCell(this.worksheet, line);
    };
    fileReader.readAsArrayBuffer(file);
  }

  private matchingCell(worksheet: any, _line: any) {
    const dictionaryLine = worksheet[0];
    const voterKey = Object.keys(dictionaryLine)[0];
    const ponderationKey = Object.keys(dictionaryLine)[1];
    const phoneKey = Object.keys(dictionaryLine)[2];
    const firstNameKey = Object.keys(dictionaryLine)[3];
    const lastNameKey = Object.keys(dictionaryLine)[4];
    const languageKey = Object.keys(dictionaryLine)[5];
    const filterKey = Object.keys(dictionaryLine)[7];
    const validationKey = Object.keys(dictionaryLine)[8];
    if (filterKey == undefined) {
      this.isOutdatedFile.emit(true);
    } else {
      let hasErrorLines = false;
      for (let i = 1; i < worksheet.length; i++) {
        const worksheetLine = worksheet[i];
        if (voterKey !== undefined) {
          if (worksheetLine[voterKey] == undefined) {
            this.invalidVoters.push(
              new Voter(null, null, i + 2 + '', null, null, null, null)
            );
            hasErrorLines = true;
          } else {
            const email = worksheetLine[voterKey].trim();
            const ponderation = worksheetLine[ponderationKey];
            const phone = worksheetLine[phoneKey];
            if (phone !== undefined && typeof phone === 'string') {
              phone.trim();
            }
            const firstName = worksheetLine[firstNameKey];
            const lastName = worksheetLine[lastNameKey];
            const language = worksheetLine[languageKey] != null && worksheetLine[languageKey] != undefined && worksheetLine[languageKey].length > 1 ?  worksheetLine[languageKey].toLowerCase() : this.common.getLangCode();
            let validationCode = null;
            if (validationKey !== undefined && dictionaryLine[validationKey].toLowerCase().trim() === 'voter keys') {
              validationCode = worksheetLine[validationKey];
            }
            const voter = new Voter(
              firstName,
              lastName,
              email,
              ponderation,
              phone,
              validationCode,
              language
            );
            if (
              (filterKey != null && worksheetLine[filterKey] != null && worksheetLine[filterKey].length > 1 &&
                (worksheetLine[filterKey].toLowerCase().trim() === 'oui' || worksheetLine[filterKey].toLowerCase().trim() === 'yes' || worksheetLine[filterKey].toLowerCase().trim() === 'si')) ||
              worksheetLine[filterKey] == null
            ) {
              this.voters.push(voter);
            }
          }
        }
      }
      if (hasErrorLines) {
        this.resetVoters();
        this.excelVoters = [];
        this.voters = [];
        this.sendVotersList.emit(ImportMessage.fromData(this.invalidVoters, ImportStatuses.errorEmail));
      } else {
        this.validateVoters();
        if (this.invalidVoters.length === 0) {
          this.store.dispatch(setVotersAction({voters : this.voters}));
          this.validatePonderations();
          if ((this.isSMS || this.is2FA) && this.invalidVoters.length === 0) {
            this.validateSMS();
          }
        }
      }
    }
  }

  resetVoters() {
    this.voters = [];
    this.sendVotersList.emit(ImportMessage.fromData(this.voters, ImportStatuses.responseOk));
  }

  validateVoters() {
    let isValid = true;
    this.invalidVoters = [];
    this.voters.forEach((element, index) => {
      isValid = this.common.validateEmail(element.email) && isValid;
      if (!this.common.validateEmail(element.email)) {
        this.invalidVoters.push((new Voter(element.firstName, element.lastName, index + 3 + '', element.weight, element.phone, null, 'fr')));
      }
    });
    if (isValid) {
      this.setVotersOK();
    } else {
      this.voters = [];
      this.excelVoters = [];
      this.isVotersValid = false;
      this.sendVotersList.emit(ImportMessage.fromData(this.invalidVoters, ImportStatuses.errorEmail));
    }
  }

  validatePonderations() {
    let isValid = true;
    this.invalidVoters = [];
    this.voters.forEach((element, index) => {
      isValid = Number.isInteger(Number(element.weight)) && isValid;
      if (
        !Number.isInteger(element.weight) ||
        Math.sign(parseInt(element.weight, 10)) !== 1
      ) {
        const newElement = structuredClone(element);
        newElement.email = (index + 3).toString();
        this.invalidVoters.push(newElement);
      }
    });
    if (isValid) {
      this.setVotersOK();
    } else {
      this.isVotersValid = false;
      this.resetVoters();
      this.voters = [];
      this.excelVoters = [];
      this.sendVotersList.emit(ImportMessage.fromData(this.invalidVoters, ImportStatuses.errorWeights));
    }
  }

  validateSMS() {
    let isValid = true;
    this.voters.forEach((element, index) => {
      isValid =
        ((element.phone !== undefined &&
          this.common.isPhoneValid(element.phone)) ||
          element.phone === undefined &&
        isValid);
      if (element.phone !== undefined && !this.common.isPhoneValid(element.phone)) {
          this.invalidVoters.push((new Voter(element.firstName, element.lastName, index + 3 + '', element.weight, element.phone, null, element.language)));
      }
    });
    if (isValid) {
      this.setVotersOK();
    } else {
      this.isVotersValid = false;
      this.voters = [];
      this.excelVoters = [];
      this.sendVotersList.emit(ImportMessage.fromData(this.invalidVoters, ImportStatuses.errorSms));
    }
  }

  validateLiveVoters() {
    let isValid = true;
    this.invalidVoters = [];
    let i = 0;
    const copyVoters = [...this.voters];
    while (i < copyVoters.length) {
      if (copyVoters[i].email.length === 0) {
        copyVoters.splice(i, 1);
        i--;
      }
      i++;
    }
    for (const voter of copyVoters) {
      isValid = this.common.validateEmail(voter.email) && isValid;
      if (!this.common.validateEmail(voter.email)) {
        this.invalidVoters.push(voter);
      }
    }
    if (isValid) {
      this.isVotersValid = copyVoters.length >= 1;
      this.sendVotersList.emit(ImportMessage.fromData(copyVoters, ImportStatuses.responseOk));
      this.votersCount = copyVoters.length;
      this.votersCountString = this.votersCount.toLocaleString('fr-FR', {
        maximumFractionDigits: 2,
      });
    } else {
      this.sendEmailError.emit(false);
    }
  }

  setVotersOK() {
    this.isVotersValid = this.voters.length >= 1;
    this.sendVotersList.emit(ImportMessage.fromData(this.voters, ImportStatuses.responseOk));
    this.votersCount = this.voters.length;
    this.votersCountString = this.votersCount.toLocaleString('fr-FR', {
      maximumFractionDigits: 2,
    });
    this.invalidVoters = [];
  }

  displayVoters() {
    this.viewVoters.emit(this.invalidVoters.length === 0);
  }

  resumeFromPayment() {
    const storedPoll: Poll = JSON.parse(localStorage.getItem('editedPoll'));
    this.voters = storedPoll.voters;
    this.excelVoters = storedPoll.voters;
    this.isSMS = storedPoll.isSMS;
    this.isUncrypted = storedPoll.isUncrypted;
    this.is2FA = storedPoll.is2FA;

    this.validateVoters();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["shouldResume"] !== null && changes["shouldResume"] !== undefined && changes["shouldResume"].previousValue === false && changes["shouldResume"].currentValue === true) { this.excelVoters = this.editVoters; }
    this.voters = this.editVoters;
    if (this.voters.length === 0 && !this.isExcelUpload) { this.addVoter(); }
    if (this.voters.length >= 1 && this.isExcelUpload) {
      this.validateVoters();
    }
  }

  votersChangedFromState(stateVoters: Array<Voter>): boolean {
    const organizedVoters = stateVoters.map(elem => new Voter(elem.firstName, elem.lastName, elem.email, elem.weight, elem.phone, elem.validationCode, elem.language));
    return JSON.stringify(organizedVoters) !== JSON.stringify(this.voters);
  }

  ngOnInit() {
    this.store.select((state: any) => state.creation.poll).subscribe(res => {
      this.is2FA = res.is2FA;
      this.isSMS = res.isSMS;
      if (this.votersChangedFromState(res.voters)) {
        this.isExcelUpload ? this.validateVoters() : this.validateLiveVoters();
        if (res.voters.length > 0) {
          this.voters = res.voters.map(elem => new Voter(elem.firstName, elem.lastName, elem.email, elem.weight, elem.phone, elem.validationCode, elem.language));
        }
      }
    });
    this.voters = this.editVoters;
    if (this.shouldResume === true) {
      this.resumeFromPayment();
    }

    if (this.voters.length > 0) {
      this.validateVoters();
    }
  }
}
